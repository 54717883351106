import Api from "@/services/Api2";

export default {
  async notifications(index, read) {
    try {
      const response = await Api().get(
        "notifications/from/" + index + "/read/" + read
      );
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async notification(id) {
    try {
      const response = await Api().get("notifications/" + id);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async count() {
    try {
      const response = await Api().get("notifications/count");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async updateCount() {
    try {
      const response = await Api().patch("notifications/count");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async read(nId) {
    try {
      const response = await Api().patch("notifications/read/" + nId);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async push(uId, data) {
    try {
      const response = await Api().post("notifications/" + uId, data);
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async clearMobile() {
    try {
      const response = await Api().post("notifications/mobile/clear", {
        notification: { badge: "0" },
      });
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
  async clearAllNotifications() {
    try {
      const response = await Api().patch("notifications/clear/all");
      return response.data;
    } catch (err) {
      console.log(err);
    }
    return null;
  },
};
