<template>
  <v-app id="inspire" v-if="isSpecialRoute()">
    <router-view />
  </v-app>
  <v-app id="inspire" v-else-if="$store.state.version == 4" @bjjlink-connect-app-login="appLogin">
    <v-navigation-drawer v-model="drawer" temporary app>
      <BeltContainer v-if="userPanel" :height="50" :belt="userPanel.belt"></BeltContainer>
      <v-card-actions class="text-center clickable" style="margin-top:-40px">
        <CountryFlag v-if="userPanel" style="margin-top:40px" size="25" :avatar="true"
          :country-code="userPanel.nationality">
        </CountryFlag>
        <v-spacer></v-spacer>
        <UserAvatar @click="goToUser($store.state.user._id)" :user="userPanel" :store-image="true" size="80"
          :small-badge="true" :offset-x="20">
        </UserAvatar>
        <v-spacer></v-spacer>
        <v-btn icon style="margin-top:40px" @click="loadIdCard()">
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-btn>
      </v-card-actions>
      <v-list dense>
        <v-list-item-group v-model="selectedMenuItem" color="primary">
          <v-list-item @click="navigateTo({ name: 'home-member', query: { v: 'v4' } })">
            <v-list-item-icon>
              <v-icon>mdi-home-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.home") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="navigateTo({ name: 'inbox' })">
            <v-list-item-icon>
              <v-badge color="secondary"
                v-if="$store.state.isUserLoggedIn && !$store.state.hidden && $store.state.messagesCount > 0" right
                :content="$store.state.messagesCount">
                <v-icon>mdi-mailbox-open-up-outline</v-icon>
              </v-badge>
              <v-icon v-else class="mr-1">mdi-mailbox-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.messages") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navigateTo({ name: 'notifications' })">
            <v-list-item-icon>
              <v-badge color="secondary" v-if="$store.state.isUserLoggedIn && $store.state.notificationsCount > 0" right
                :content="$store.state.notificationsCount">
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
              <v-icon v-else>mdi-bell-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.notifications") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="navigateTo({ name: 'myevents' })">
            <v-list-item-icon>
              <v-icon>mdi-calendar-multiple-check</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.my_events") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navigateTo({ name: 'waivers' })">
            <v-list-item-icon>
              <v-icon>mdi-draw-pen</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.documents") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="navigateTo({ name: 'lineagetree' });">
            <v-list-item-icon>
              <v-icon>mdi-family-tree</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.menu_lineage_tree") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="navigateTo({ name: 'academies' });">
            <v-list-item-icon>
              <v-icon>mdi-bank</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.academies") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="navigateTo({ name: 'affiliates' });">
            <v-list-item-icon>
              <v-icon>mdi-account-tie-voice-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t("message.referrals_programs") }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
        </v-list-item-group>
        <v-list-group @click.stop="doNothing()" :value="false" prepend-icon="mdi-account-circle">
          <template v-slot:activator>
            <v-list-item-title @click.stop="doNothing()">{{ $t("message.profile") }}</v-list-item-title>
          </template>
          <v-list-item @click="navigateTo({ name: 'viewprofile', params: { userId: $store.state.user._id } })">
            <v-list-item-icon>
              <v-icon>mdi-account-box-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.view") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navigateTo({ name: 'editprofile' })">
            <v-list-item-icon>
              <v-icon>mdi-square-edit-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.edit") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click.stop="navigateTo({ name: 'edithistory' })">
            <v-list-item-icon>
              <v-icon>mdi-history</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.belts_and_history") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="navigateTo({ name: 'settings' })">
            <v-list-item-icon>
              <v-icon>mdi-tune-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.settings") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="inviteFriendsDialog = true">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple-plus-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ $t("message.invite_friends") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="toggleThemeColor()">
            <v-list-item-icon>
              <v-icon color="#1A237E" v-if="!isDarkTheme()">mdi-weather-night</v-icon>
              <v-icon color="#FFCA28" v-else>mdi-weather-sunny</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title v-if="!isDarkTheme()">
                {{ $t("message.dark_mode") }}
              </v-list-item-title>
              <v-list-item-title v-else>
                {{ $t("message.light_mode") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="!isAppDisplay()" @click="logout()">
            <v-list-item-title>
              <v-icon>mdi-logout</v-icon>
              {{ $t("message.logout") }}
            </v-list-item-title>
          </v-list-item>
        </v-list-group>
        <v-divider></v-divider>
        <v-list-item @click="navigateTo({ name: 'viewourterms' })">
          <v-list-item-icon>
            <v-icon>mdi-file-document-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t("message.terms_of_use") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="reload()">
          <v-list-item-icon>
            <v-icon>mdi-cube-scan</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              v{{ appVersion }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-footer>
        <v-card-text class="text-center">
          <div>
            <p style="font-size: 12px;">{{ $t("message.powered_by") }}</p>
          </div>
          <div class="bottom-nav-logomt-1">
            <b>MARTIAL ARTS</b> TECHNOLOGY
          </div>
        </v-card-text>
      </v-footer>
    </v-navigation-drawer>
    <v-app-bar app flat v-if="!hideNavigations" dense>
      <div @click="toggleDrawer()">
        <IconBjjlink style="margin-top:-5px" :color="isDarkTheme() ? 'white' : 'black'" class="clickable">
        </IconBjjlink>
      </div>
      <v-spacer></v-spacer>
      <v-badge v-if="$store.state.messagesCount || $store.state.notificationsCount" color="secondary" dot overlap>
        <v-avatar @click="toggleDrawer()" size="25px" class="grey lighten-4 clickable ml-1 mb-1" icon>
          <img v-if="!$store.state.userImage" :src="assets('blank-profile-picture.png')" alt="avatar" />
          <img v-else :src="userFile($store.state.userImage)" alt="avatar" />
        </v-avatar>
      </v-badge>
      <v-avatar v-else @click="toggleDrawer()" size="25px" class="grey lighten-4 clickable ml-1 mb-1" icon>
        <img v-if="!$store.state.userImage" :src="assets('blank-profile-picture.png')" alt="avatar" />
        <img v-else :src="userFile($store.state.userImage)" alt="avatar" />
      </v-avatar>
    </v-app-bar>
    <v-main>
      <v-container class="pa-0">
        <router-view />
      </v-container>
    </v-main>
    <v-dialog v-model="pointsDialog">
      <v-card outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="pointsDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
        <v-card-title>{{ $t("message.points") }}</v-card-title>
        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.total_points") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.points.total }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.last_30_days") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.points.last30Days }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.this_month") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.points.thisMonth }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.academy_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.positions.academyPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.city_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.positions.cityPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.country_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.positions.countryPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.world_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ header.positions.worldPosition }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn elevation="0" @click="navigateTo({ name: 'points' })" block>{{ $t("message.view_more") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="idCardDialog" transition="dialog-top-transition" width="100%">
      <v-card outlined light>
        <div class="pa-2" style="overflow: hidden">
          <div v-if="loadingIDCard" class="text-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <div v-else-if="idCard">
            <div>
              <div v-if="familyMembers && familyMembers.length > 0">
                <v-select @change="loadIdCard()" v-model="selectedFamilyMember" :items="familyMembers" item-text="name"
                  item-value="userId" :label="$t('message.select_one')" clearable outlined dense>
                </v-select>
              </div>
            </div>
            <div v-if="idCard.nationality">
              <country-flag :countryCode="idCard.nationality"></country-flag>
            </div>
            <table width="100%">
              <tr>
                <td style="vertical-align: top" class="text-center">
                  <v-avatar size="60px" class="grey lighten-4 clickable">
                    <img v-if="!idCard.image" :src="assets('blank-profile-picture.png')" alt="avatar" />
                    <img v-else :src="userFile(idCard.image)" alt="avatar" />
                  </v-avatar>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                  <div class="text-h6 truncate-text">
                    {{ idCard.name }}
                  </div>
                  <div v-if="idCard.academy" class="text-subtitle-2 truncate-text">
                    {{ idCard.academy }}
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div v-if="idCard.membershipStatus" class="text-center text-h4 pa-2">
                    <div v-if="idCard.membershipStatus == 'active'" class="success--text">
                      {{ $t("message.active").toUpperCase() }}
                    </div>
                    <div v-else class="error--text">
                      {{ $t("message.inactive").toUpperCase() }}
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div v-if="idCard && idCard.verificationUrl" class="text-center pa-2">
              <div class="text-caption">{{ $t('message.scan_to_verify_membership') }}</div>
              <div>
                <vue-qr :text="idCard.verificationUrl" :size="200"></vue-qr>
              </div>
            </div>
            <v-card-actions>
              <v-avatar v-if="idCard.academyImage" size="25px" class="grey lighten-4 clickable">
                <img :src="userFile(idCard.academyImage)" alt="avatar" />
              </v-avatar>
              <v-spacer></v-spacer>
              <div class="text-right text-caption">
                <span class="mr-1">{{ $t("message.member_since") }}</span>
                <span>{{ $d(new Date(idCard.memberSince), 'month_day_year', this.$i18n.locale) }}</span>
              </div>
            </v-card-actions>
          </div>
          <div v-else class="text-center error--text text-h6">
            {{ $t('message.invalid') }}
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-app>
  <v-app v-else id="inspire" @bjjlink-connect-app-login="appLogin">
    <v-app-bar app flat v-if="!hideNavigations">
      <v-container class="pl-0">
        <v-card-actions class="pl-0 pr-0">
          <img @click="navigateTo({ name: 'home' })" style="height:40px" class="ml-2 h-9 w-auto clickable"
            :src="assets('logos/logo_connect.png')" alt="BJJLINK" />
          <v-toolbar-title class="clickable text-subtitle-2" @click="navigateTo({ name: 'home' })">
            Made by BJJLINK
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-badge color="primary" class="mr-1"
            v-if="$store.state.isUserLoggedIn && !$store.state.hidden && $store.state.messagesCount > 0" overlap right
            :content="$store.state.messagesCount">
            <v-btn @click="navigateTo({ name: 'inbox' })" icon small>
              <MailIcon dark class="hero-button"></MailIcon>
            </v-btn>
          </v-badge>
          <v-btn v-else @click="navigateTo({ name: 'inbox' })" icon small>
            <MailIcon dark class="hero-button"></MailIcon>
          </v-btn>
          <v-badge class="mr-2" v-if="$store.state.isUserLoggedIn && $store.state.notificationsCount > 0" right
            color="primary" :content="!$store.state.notificationsCount
              ? 0
              : $store.state.notificationsCount" overlap>
            <v-btn @click="navigateTo({ name: 'notifications' })" icon small>
              <BellIcon dark class="hero-button"></BellIcon>
            </v-btn>
          </v-badge>
          <v-btn class="mr-1" v-else-if="$store.state.isUserLoggedIn" @click="navigateTo({ name: 'notifications' })"
            icon small>
            <BellIcon dark class="hero-button"></BellIcon>
          </v-btn>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-avatar size="30px" class="grey lighten-4 clickable ml-1 mb-1" icon v-bind="attrs" v-on="on">
                <img v-if="!$store.state.userImage" :src="assets('blank-profile-picture.png')" alt="avatar" />
                <img v-else :src="userFile($store.state.userImage)" alt="avatar" />
              </v-avatar>
            </template>
            <v-list>
              <v-list-item @click="navigateTo({ name: 'viewprofile', params: { userId: $store.state.user._id } })">
                <v-list-item-title>
                  <v-icon>mdi-tune-vertical-variant</v-icon>
                  {{ $t("message.profile") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="navigateTo({ name: 'myevents' })">
                <v-list-item-title>
                  <v-icon>mdi-calendar-multiple-check</v-icon>
                  {{ $t("message.my_events") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="navigateTo({ name: 'waivers' })">
                <v-list-item-title>
                  <v-icon>mdi-draw-pen</v-icon>
                  Waivers
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="navigateTo({ name: 'lineagetree' });">
                <v-list-item-title>
                  <v-icon>mdi-family-tree</v-icon>
                  {{ $t("message.menu_lineage_tree") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="navigateTo({ name: 'map' });">
                <v-list-item-title>
                  <v-icon>mdi-map</v-icon>
                  {{ $t("message.explore_map") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="navigateTo({ name: 'academies' });">
                <v-list-item-title>
                  <v-icon>mdi-bank</v-icon>
                  {{ $t("message.academies") }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click.stop="navigateTo({ name: 'affiliates' });">
                <v-list-item-title>
                  <v-icon>mdi-account-tie-voice-outline</v-icon>
                  {{ $t("message.referrals_programs") }}
                </v-list-item-title>
              </v-list-item>
              <v-divider v-if="$store.state.managedAcademyAdmin && this.$store.state.version != 4"></v-divider>
              <v-list-item v-if="$store.state.managedAcademyAdmin && this.$store.state.version != 4"
                @click="openAdmin()">
                <v-list-item-title>
                  <img class="mr-1" style="margin-bottom:-4px" width="20" :src="assets() + '/logos/logo_admin.png'" />
                  Admin+
                </v-list-item-title>
              </v-list-item>
              <v-divider v-if="$store.state.managedAcademyAdmin"></v-divider>
              <v-list-item @click="reload()">
                <v-list-item-title>
                  <v-icon class="mr-1">mdi-cube-scan</v-icon>
                  v{{ appVersion }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card-actions>
      </v-container>
    </v-app-bar>
    <v-main :class="($vuetify.theme.dark ? 'grey darken-3' : 'gray lighten-2')">
      <v-container>
        <v-row>
          <v-col v-if="!isSmallerDisplay()" cols="12" sm="3">
            <v-sheet class="pa-0" rounded="lg" min-height="250">
              <v-skeleton-loader class="mt-2" v-if="!userPanel" type="image, card-heading"></v-skeleton-loader>
              <div v-else>
                <BeltContainer :height="50" :belt="userPanel.belt"></BeltContainer>
                <div class="text-center clickable" style="margin-top:-40px" @click="goToUser($store.state.user._id)">
                  <UserAvatar :user="userPanel" :store-image="true" size="80" :small-badge="true" :offset-x="20">
                  </UserAvatar>
                </div>
                <div class="text-center mt-2">
                  <div class="text-subtitle-1 text-truncate">{{ userPanel.displayName }}</div>
                  <div v-if="userPanel.identifier" class="pa-0 text-subtitle-2 gray--text text-truncate pt-0">
                    {{ userPanel.identifier }}</div>
                  <v-row class="mt-1">
                    <v-col>
                      <div class="text-subtitle-1">{{ userPanel.links }}</div>
                      <div class="text-caption">{{ $t("message.links") }}</div>
                    </v-col>
                    <v-col>
                      <div class="text-subtitle-1">{{ userPanel.jjPoints }}</div>
                      <div class="text-caption">JJPs</div>
                    </v-col>
                    <v-col>
                      <div class="text-subtitle-1">{{ userPanel.posts }}</div>
                      <div class="text-caption">{{ $t("message.posts") }}</div>
                    </v-col>
                  </v-row>
                  <v-divider></v-divider>
                  <v-row v-if="hasMedals(userPanel)" class="mt-1">
                    <v-col>
                    </v-col>
                    <v-col>
                      <v-badge :content="userPanel.overview.bronceMedals ? userPanel.overview.bronceMedals : '0'"
                        overlap>
                        <IconCompMedal :color="themeColor('bronze')"></IconCompMedal>
                      </v-badge>
                    </v-col>
                    <v-col>
                      <v-badge :content="userPanel.overview.silverMedals ? userPanel.overview.silverMedals : '0'"
                        overlap>
                        <IconCompMedal :color="themeColor('silver')"></IconCompMedal>
                      </v-badge>
                    </v-col>
                    <v-col>
                      <v-badge :content="userPanel.overview.goldMedals ? userPanel.overview.goldMedals : '0'" overlap>
                        <IconCompMedal :color="themeColor('gold')"></IconCompMedal>
                      </v-badge>
                    </v-col>
                    <v-col>
                    </v-col>
                  </v-row>
                  <div v-if="userPanel.overview.competitions > 0" class="text-overline">
                    {{ $t("message.competitions") }}: {{ userPanel.overview.competitions }}
                  </div>
                  <v-divider></v-divider>
                  <div class="text-overline">
                    <v-btn block class="text-caption" v-if="$store.state.userManagedAcademy" text color="primary"
                      @click="navigateTo({ name: 'myacademytab', params: { tabId: 0 } });
                      closeSearchPanel();">
                      <StarIcon color="primary" size="15" class="hero-button"></StarIcon>
                      {{ $t("message.my_academy") }}
                    </v-btn>
                    <v-btn class="text-caption" block @click="goToAcademy(userPanel.academy)"
                      :disabled="!userPanel.academy" v-else text color="primary">
                      <LibraryIcon color="primary" size="15" class="hero-button"></LibraryIcon>
                      {{ $t("message.my_academy") }}
                    </v-btn>
                  </div>
                  <v-divider></v-divider>
                  <div class="text-overline">
                    <v-btn block class="text-caption" text color="primary" @click="navigateTo({ name: 'viewprofile', query: { invite: 'true' }, params: { userId: $store.state.user._id } });
                    closeSearchPanel();">
                      <UserGroupIcon color="primary" size="15" class="hero-button"></UserGroupIcon>
                      {{ $t("message.invite_friends") }}
                    </v-btn>
                  </div>
                </div>
              </div>
            </v-sheet>
            <v-sheet class="mt-3 pa-2" rounded="lg" min-height="368">
              <div v-if="$store.state.hidden" class="text-center pt-2 mt-2">
                <div class="text-caption">
                  {{ $t("message.your_user_hidden") }}
                </div>
                <div class="mt-2 pt-2">
                  <v-icon color="error" size="80">mdi-eye-off-outline</v-icon>
                </div>
              </div>
              <v-list v-if="$store.state.isUserLoggedIn && !$store.state.hidden" class="text-left app-menu">
                <v-list-item link @click="navigateTo({ name: 'home' })">
                  <v-list-item-action>
                    <HomeIcon :color="menuColor('home')"></HomeIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('home')">{{ $t("message.home") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item link @click="showElevateDialog()">
                  <v-list-item-action>
                    <v-icon>mdi-new-box</v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('elevate')">BJJLINK Elevate</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'users' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <UserGroupIcon :color="menuColor('users')"></UserGroupIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('users')">{{ $t("message.jitsers") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'events' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <CalendarIcon :color="menuColor('events')"></CalendarIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('events')">{{ $t("message.menu_events") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'ibjjftournaments' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <StatusOnlineIcon :color="menuColor('ibjjftournaments')"></StatusOnlineIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('ibjjftournaments')">{{ $t("message.match_tracker")
                      }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'academies' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <LibraryIcon :color="menuColor('academies')"></LibraryIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('academies')">{{ $t("message.menu_academies") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item v-if="$store.state.userManagedAcademy" link @click="
                  navigateTo({ name: 'videos' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <PlayIcon :color="menuColor('videos')"></PlayIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('videos')">{{ $t("message.menu_videos") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'news' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <RssIcon :color="menuColor('news')"></RssIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('news')">{{ $t("message.news") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'lineagetree' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <IconLineage :color="menuColor('lineagetree')"></IconLineage>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('lineagetree')">{{ $t("message.menu_lineage_tree")
                      }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'map' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <MapIcon :color="menuColor('map')"></MapIcon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('map')">{{ $t("message.menu_explore") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item link @click="
                  navigateTo({ name: 'partners' });
                closeSearchPanel();
                ">
                  <v-list-item-action>
                    <IconPartner :color="menuColor('partners')"></IconPartner>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title :style="'color:' + menuColor('partners')">{{ $t("message.partners") }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-col>

          <v-col :style="!isSmallerDisplay() ? (panelHeight ? 'height: ' + panelHeight + 'px' : '') : ''"
            :class="'mt-0 mb-0 ' + (isSmallerDisplay() ? 'pa-0 router-col center-panel' : 'router-col')" cols="12"
            sm="6">
            <div :style="isSmallerDisplay() ? 'margin-top:-5px' : 'margin-top:-4px'" class="fill-height"
              :color="darkTheme ? '#424242' : '#ededed'">
              <router-view class="mt-1" @update-panels="updatePanels" />
            </div>
          </v-col>

          <v-col class="hidden-sm-and-down" cols="12" sm="3">
            <v-sheet class="pb-4" rounded="lg" min-height="200">
              <v-card-title class="text-overline pl-4 pt-1 pb-1">{{ $t("message.news") }}</v-card-title>
              <v-skeleton-loader class="mt-2" v-if="!newsPanel" type="article"></v-skeleton-loader>
              <div v-else-if="!$store.state.hidden" class="pa-0">
                <FetchedUrl :item="newsPanel" :url="newsPanel.link" :panel="true">
                </FetchedUrl>
              </div>
              <div v-else class="text-center pa-2 mt-2">
                <div class="text-caption">
                  {{ $t("message.your_user_hidden") }}
                </div>
                <div class="mt-2 pt-2">
                  <v-icon color="error" size="80">mdi-eye-off-outline</v-icon>
                </div>
              </div>
            </v-sheet>
            <v-sheet rounded="lg" class="mt-5 pb-4" min-height="250"
              v-if="!$store.state.hidden && (!suggestionsPanel || (suggestionsPanel && suggestionsPanel.length > 0))">
              <v-card-title class="text-overline pl-4 pt-1 pb-1">{{ $t("message.suggestions") }}</v-card-title>
              <v-skeleton-loader class="mt-2" v-if="!suggestionsPanel"
                type="list-item-avatar-three-line, list-item-avatar-three-line"></v-skeleton-loader>
              <div v-else>
                <Suggestions :suggestions="suggestionsPanel" identifier="app_suggestions"></Suggestions>
              </div>
            </v-sheet>
            <div class="text-caption gray--text pa-2 text-center">
              <span>
                {{ $t("message.help_us1") }}
                <a href="mailto:info@bjjlink.com?Subject=QUESTION">help@bjjlink.com</a>
                {{ $t("message.help_us2") }}
              </span>
              <span>BJJLINK&copy; {{ new Date().getFullYear() }}</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-dialog v-model="elevateDialog" max-width="300">
        <v-card>
          <v-card-actions class="mb-0 pb-0">
            <v-spacer></v-spacer>
            <v-btn icon small @click="showElevateDialog()"><v-icon>mdi-close-circle-outline</v-icon></v-btn>
          </v-card-actions>
          <v-card-text class="mt-6">
            <h2 class="text-center mb-2">BJJLINK Elevate</h2>
            <p class="text-center">{{ $t('message.elevate_message') }}</p>
            <div class="text-center">
              <img src="https://res.cloudinary.com/freddyci/image/upload/v1677893367/elevate-logo_bhxwz0.svg"
                style="width:65px" alt="Logo 2">
              <p class="text-center eco-text truncate" style="padding:0;">{{ $t('message.elevate_d') }}
              </p>
              <v-btn color="primary" elevation="0" class="mb-6" @click="goToElevate()">{{ $t('message.lets_go')
                }}</v-btn>
              <div class="text-center text-caption" style="margin-top:-15px">
                bjjlink.com/elevate
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script>
import ConnectService from "@/services/ConnectService";
import UserService from "@/services/UserService";
import AuthenticationService from "@/services/AuthenticationService";
import AcademyService from "@/services/AcademyService";

import IconPartner from "@/components/displays/icons/IconPartner";
import IconLineage from "@/components/displays/icons/IconLineage";
import IconBjjlink from "@/components/displays/icons/IconBjjlink";
import IconCompMedal from "@/components/displays/icons/IconCompMedal";
import BeltContainer from "@/components/displays/BeltContainer";
import FetchedUrl from "@/components/displays/FetchedUrlDisplay.vue";
import Suggestions from "@/components/displays/SuggestionsDisplay.vue";
import UserAvatar from "@/components/displays/UserAvatarDisplay";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import VueQr from 'vue-qr'

//Icons
import {
  HomeIcon,
  CalendarIcon,
  LibraryIcon,
  PlayIcon,
  RssIcon,
  MapIcon,
  UserGroupIcon,
  StarIcon,
  BellIcon,
  MailIcon,
  StatusOnlineIcon,
} from '@vue-hero-icons/outline'

export default {
  name: 'BJJLINKSocial',
  components: {
    CountryFlag,
    HomeIcon,
    CalendarIcon,
    LibraryIcon,
    PlayIcon,
    RssIcon,
    MapIcon,
    UserGroupIcon,
    StarIcon,
    IconPartner,
    BellIcon,
    IconLineage,
    IconCompMedal,
    MailIcon,
    FetchedUrl,
    BeltContainer,
    Suggestions,
    UserAvatar,
    IconBjjlink,
    VueQr,
    StatusOnlineIcon
  },
  data() {
    return {
      elevateDialog: false,
      embed: this.$route.query.embed,
      lat: this.$route.query.lat,
      lon: this.$route.query.lon,
      selectedMenuItem: 0,
      hideNavigations: false,
      drawer: false,
      darkTheme: true,
      newMessageDialog: false,
      testAppMessage: "Santi",
      topMessage: "Test",
      searchPanel: false,
      iconSize: "23px",
      panelHeight: null,
      idCard: null,
      loadingIDCard: false,
      idCardDialog: null,
      userPanel: null,
      familyMembers: [],
      header: { points: {}, positions: {} },
      suggestionsPanel: null,
      newsPanel: null,
      pointsDialog: null,
      termsLink: "https://app.termly.io/document/terms-of-use-for-website/8aac4f6c-e9f6-4165-89e3-241364a2e7de",
      appVersion: process.env.VUE_APP_VERSION
    };
  },
  async created() {
    if (this.$route.query.v && this.$route.query.v == 'v4') {
      this.$store.dispatch("setVersion", 4);
    }
    if (this.lat && this.lon) {
      //store this for future use
      this.$store.dispatch("setLat", this.lat);
      this.$store.dispatch("setLon", this.lon);
    } else {
      this.lat = this.$store.state.lat;
      this.lon = this.$store.state.lon;
    }
    if (this.embed && this.embed == 'true') {
      this.hideNavigations = true;
    }
    //First thiings first: Hide loader
    let initialLoader = document.getElementById('app_loader_container');
    if (initialLoader)
      initialLoader.parentNode.removeChild(initialLoader);
    if (this.$store.state.language) {
      this.$store.dispatch("setLanguage", this.$store.state.language);
      this.$i18n.locale = this.$store.state.language;
    } else this.userLang = "en";

    const path = this.$route ? this.$route.path : null;
    if (path && path.indexOf("applogin") < 0) {
      this.userPanel = await this.getMe();
      if (this.userPanel) {
        this.darkTheme = this.userPanel.darkMode;
        this.$vuetify.theme.dark = this.darkTheme;
        if (!this.$route.name || this.$route.name.indexOf('home') < 0) {
          //We are loading the app outside of home
          this.suggestionsPanel = await this.getSuggestions();
          if (!this.$store.state.hidden)
            this.newsPanel = await this.getNews();
          else
            this.newsPanel = {};
        }
      }
    }
  },
  mounted() {
    const windowHeight = window.innerHeight - 50;//64 is the header fixed height
    if (!this.isSmallerDisplay()) {
      const leftPanelheight = 877;
      if (windowHeight > leftPanelheight)
        this.panelHeight = windowHeight;
      else
        this.panelHeight = leftPanelheight;
    } else {
      this.panelHeight = windowHeight;
    }
  },
  methods: {
    appLogin(jsonString) {
      try {
        const data = JSON.parse(jsonString)
        const token = data ? data.token : null;
        console.log('Trying to do applogin: ', jsonString);
        if (token && (!localStorage.token || localStorage.token != token)) {
          this.$store.dispatch("setToken", data.token);
          this.$store.dispatch("setUser", data.user);
          this.$store.dispatch("setName", data.user.fName);
          this.$store.dispatch("setHidden", data.user.hidden);
          this.$store.dispatch("setInApp", true);
          this.$store.dispatch("setUserImage",
            (data.user.userImageSmall ?
              data.user.userImageSmall :
              data.user.userImage));
          this.$store.dispatch("setLanguage", data.user.lang);
          this.$i18n.locale = data.user.lang;
          localStorage.token = data.token;
          if (
            data.hasLatestAgreement &&
            data.hasLatestAgreement == true
          ) {
            localStorage.hasAgreements = true;
            this.$store.dispatch("setHasAgreements", true);
          } else {
            localStorage.hasAgreements = false;
            this.$store.dispatch("setHasAgreements", false);
          }
          if (this.specialRoute && this.specialRoute == 'checkin')
            window.location = '/admin/secure/pickacademy'
          else if (this.to == 'elevate')
            window.location = '/elevate'
          else if (this.to == 'admin')
            window.location = '/admin'
          else if (
            data.user.totalHistory &&
            data.user.totalHistory > 0
          )
            window.location = '/secure/home?login=true'
          else
            window.location = '/secure/history'
        } else {
          console.log('BJJLINK Connect: We did not try to log in. JSON: ', jsonString)
        }
      } catch (err) {
        console.log('ERROR: ', err)
      }
    },
    toggleThemeColor() {
      this.darkTheme = !this.darkTheme;
      this.$vuetify.theme.dark = this.darkTheme;
      //push color update
      let theme = 'light';
      if (this.darkTheme)
        theme = 'dark';
      this.$store.dispatch("setTheme", theme);
      //Make Api Call
      UserService.toggleTheme();
      this.$router.go();
    },
    getFamilyMembers() {
      if (this.managedAcademy) {
        AcademyService.academyFamily(this.managedAcademy)
          .then((data) => {
            if (data && data.members && data.members.length > 0) {
              const familyMembers = [{
                name: this.$t('message.you'),
                userId: null
              }];
              this.familyMembers = familyMembers.concat(data.members);
            }
          });
      }
    },
    async loadIdCard() {
      if (!this.idCardDialog)
        this.getFamilyMembers()
      this.idCardDialog = true;
      this.loadingIDCard = true;
      const me = await ConnectService.getMe(true, this.selectedFamilyMember);
      if (me.data && me.data.idCard) {
        this.idCard = me.data.idCard;
      }
      this.loadingIDCard = false;
      this.trackEvent('user', 'view_id_card', this.$store.state.user._id);
    },
    reload() {
      window.location.reload(true);
    },
    showElevateDialog() {
      this.elevateDialog = !this.elevateDialog;
    },
    goToElevate() {
      this.elevateDialog = false;
      UserService.logActivity({
        activityType: "clicked_elevate_menu"
      });
      window.open("/elevate");
    },
    openAdmin() {
      //const os = this.getOS();
      //const size = this.$vuetify.breakpoint.name;
      /*
        if (os == 'Mac OS' && size != 'lg' && size != 'xl') {//This should be an ipad
          console.log('BLAh')
          //window.open("/admin");
        } else 
      */
      if (this.$store.state.version == 4) {
        if (this.isSmallerDisplay()) {
          this.showAlert(
            "error",
            this.$t("message.not_allowed")
          );
        } else {
          window.open("https://admin.bjjlink.com/secure/home?inApp=false");
        }
      } else {
        if (this.isSmallerDisplay()) {
          window.location = "/admin/secure/home?inApp=true";
        } else {
          window.open("/admin/secure/home?inApp=false");
        }
      }
    },
    updatePanels(data) {
      if (data) {
        if (data.userPanel) {
          this.userPanel = data.userPanel;
          const vm = this;
          setTimeout(function () {
            vm.userPanel = data.userPanel;
          }, 1000);
        }
        if (data.suggestionsPanel) {
          this.suggestionsPanel = null;
          const vm = this;
          setTimeout(function () {
            vm.suggestionsPanel = data.suggestionsPanel;
          }, 500);
        }
        if (data.newsPanel) {
          this.newsPanel = null;
          const vm = this;
          setTimeout(function () {
            vm.newsPanel = data.newsPanel;
          }, 500);
        }
      }
    },
    reloadToSettings() {
      window.location.replace("/secure/settings");
    },
    toggleDrawer() {
      if (this.isSmallerDisplay() || this.$store.state.version == 4) {
        this.drawer = !this.drawer;
      }
    },
    menuColor(menu) {
      if (this.$route && this.$route.path.indexOf(menu) >= 0) {
        return this.themeColor('primary');
      } else {
        return this.darkTheme ? "white" : "grey";
      }
    },
    closeSearchPanel() {
      this.searchPanel = false;
      this.menuElements = true;
    },
    isSpecialRoute() {
      const path = this.$route ? this.$route.path : null;
      if (!path)
        return false;
      if (
        path == "/landing" ||
        path == "/login" ||
        path == "/qr" ||
        path == "/help" ||
        path == "/register" ||
        path == "/secure/admin/managedacademiesstats" ||
        path == "/registertwo" ||
        path == "/secure/admin/internalaggregates" ||
        path == "/info" ||
        path == "/bjjworld" ||
        path == "/infospa" ||
        path == "/matburn" ||
        path == "/aboutsocialnetwork" ||
        path == "/aboutadminconsole" ||
        path == "/secure/admin-reports" ||
        path.indexOf("/info/") >= 0 ||
        path.indexOf("pub") >= 0 ||
        path.indexOf("update-email") >= 0 ||
        path.indexOf("applogin") >= 0 ||
        path.indexOf("forgot-password") >= 0 ||
        path.indexOf("reset-password") >= 0 ||
        path == "/"
      ) {
        return true;
      } else {
        return false;
      }
    },
    logout() {
      AuthenticationService.logout();
      this.$store.dispatch("setToken", null);
      this.$store.dispatch("setUser", null);
      this.$store.dispatch("setInApp", null);
      localStorage.removeItem("token");
      window.location.replace("/login");
    },
  }
};
</script>

<style>
.clickable {
  cursor: pointer;
}

.hero-button {
  margin-top: -7px
}

.hero-icon {
  margin-top: -7px
}

.info-box {
  max-height: 380px;
  overflow-y: auto;
}

.border-light {
  border: 1px solid #E0E0E0
}

.border-dark {
  border: 1px solid #424242
}

.router-col {
  overflow-y: auto;
  display: flex;
  flex-flow: column;
  position: relative;
}

.rotate-90 {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

.rotate {
  transform: rotate(90deg);
}

.page-description {
  overflow-y: auto;
  max-height: 250px;
  overflow-x: auto;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}


.user-list-container {
  overflow-y: auto;
  max-height: 250px;
}

.center-panel {
  margin: 0 auto;
}

.rounded-card {
  border-radius: 25px !important;
}

.header-column {
  z-index: 10;
}
</style>
