/* eslint-disable indent */
import Api from "@/services/Api2";

export default {
  async managedAcademiesStats() {
    const response = await Api().get("bjjlinkadmins/managedacademies/stats");
    return response.data;
  },
  async managedAcademies() {
    const response = await Api().get("bjjlinkadmins/managedacademies");
    return response.data;
  },
  async inactiveManagedAcademies() {
    const response = await Api().get("bjjlinkadmins/managedacademies/inactive");
    return response.data;
  },
  async checkTransaction(id) {
    const response = await Api().get(
      "bjjlinkadmins/managedacademies/transactions/" + id
    );
    return response.data;
  },
  async checkPaymentIntent(managedAcademy, id) {
    const response = await Api().get(
      "bjjlinkadmins/managedacademies/" + managedAcademy + "/intents/" + id
    );
    return response.data;
  },
  async toogleManagedAcademyActive(id) {
    const response = await Api().patch(
      "bjjlinkadmins/managedacademy/" + id + "/toggle/active"
    );
    return response.data;
  },
  async subscriptionsCheck(id) {
    const response = await Api().post(
      "bjjlinkadmins/managedacademy/" + id + "/trigger/subscriptions/check"
    );
    return response.data;
  },
  async toggleIsMinor(data) {
    const response = await Api().patch(
      "bjjlinkadmins/users/toggle/minor",
      data
    );
    return response.data;
  },
  async lineageTrees(root) {
    const response = await Api().get(
      "bjjlinkadmins/lineagetrees?" + (root ? "root=" + root : "")
    );
    return response.data;
  },
  async orphanNodes() {
    const response = await Api().get("bjjlinkadmins/orphannodes?");
    return response.data;
  },
  async addParentToNode(nodeId, data) {
    const response = await Api().patch(
      "bjjlinkadmins/add/parent/to/node/" + nodeId,
      data
    );
    return response.data;
  },
  async addChildToNode(nodeId, data) {
    const response = await Api().patch(
      "bjjlinkadmins/add/child/to/node/" + nodeId,
      data
    );
    return response.data;
  },
  async editNode(nodeId, data) {
    const response = await Api().patch(
      "bjjlinkadmins/edit/node/" + nodeId,
      data
    );
    return response.data;
  },
  async addRelease(data) {
    const response = await Api().post("bjjlinkadmins/add/release/notes", data);
    return response.data;
  },
  async updateRelease(data) {
    const response = await Api().patch("bjjlinkadmins/add/release/notes", data);
    return response.data;
  },
  async addBanner(data) {
    const response = await Api().post("bjjlinkadmins/add/banners", data);
    return response.data;
  },
  async updateBanner(data) {
    const response = await Api().patch("bjjlinkadmins/update/banners", data);
    return response.data;
  },
  async getBanners(platform) {
    const response = await Api().get("bjjlinkadmins/get/banners/" + platform);
    return response.data;
  },
  async latestVersion(project) {
    const response = await Api().get(
      "bjjlinkadmins/releases/" + project + "/latest/version"
    );
    return response.data;
  },
  async verifyBelt(id, belt, data) {
    const response = await Api().patch(
      "bjjlinkadmins/users/verify/" + id + "/belt/" + belt,
      data
    );
    return response.data;
  },
  async pendingVerifications() {
    const response = await Api().get("bjjlinkadmins/pendingverifications");
    return response.data;
  },
  async completeVerificationRequest(id) {
    const response = await Api().patch(
      "bjjlinkadmins/pendingverifications/complete/" + id
    );
    return response.data;
  },
  async rejectVerificationRequest(id, message) {
    const response = await Api().patch(
      "bjjlinkadmins/pendingverifications/reject/" + id,
      { message: message }
    );
    return response.data;
  },
  async searchUsers(data) {
    const response = await Api().post("bjjlinkadmins/users/", data);
    return response.data;
  },
  async getReleases(project) {
    const response = await Api().get(
      "bjjlinkadmins/releases/" + project + "/all"
    );
    return response.data;
  },
  async getUserByEmailOrId(data) {
    const response = await Api().post("bjjlinkadmins/users/identifier", data);
    return response.data;
  },
  async addManagedAcademyMember(data) {
    const response = await Api().post(
      "bjjlinkadmins/managedacademy/add/member",
      data
    );
    return response.data;
  },
  async submitNewMembers(managedAcademyId, data) {
    const response = await Api().post(
      "bjjlinkadmins/managedacademy/" + managedAcademyId + "/confirm/members",
      data
    );
    return response.data;
  },
  async uploadMembersCsv(managedAcademyId, formData, progress) {
    const response = await Api().post(
      "bjjlinkadmins/managedacademy/" + managedAcademyId + "/upload/members",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (uploadEvent) => {
          progress(uploadEvent);
        },
      }
    );
    return response.data;
  },
  async uploadAlumniCsv(managedAcademyId, formData, progress) {
    const response = await Api().post(
      "bjjlinkadmins/managedacademy/" +
        managedAcademyId +
        "/upload/alumni/confirm",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (uploadEvent) => {
          progress(uploadEvent);
        },
      }
    );
    return response.data;
  },
  async academiesCSV() {
    const response = await Api().get("bjjlinkadmins/academies/csv");
    return response.data;
  },
  async addManagedAcademy(data) {
    const response = await Api().post("bjjlinkadmins/managedacademy/new", data);
    return response.data;
  },
  async claimAcademy(data) {
    const response = await Api().post(
      "bjjlinkadmins/academies/claimmanagement",
      data
    );
    return response.data;
  },
  async updateManagedAcademyConfiguration(id, configuration) {
    const response = await Api().patch(
      "bjjlinkadmins/managedacademy/" + id + "/configuration",
      configuration
    );
    return response.data;
  },
  async createElevateEvent(data) {
    const response = await Api().post("bjjlinkadmins/elevate/event", data);
    return response.data;
  },
  async getElevateUser(id) {
    const response = await Api().get("bjjlinkadmins/elevate/user/" + id);
    return response.data;
  },

  async uopdateAcademyLineage(managedAcademyId, lineageId) {
    const response = await Api().patch(
      "bjjlinkadmins/managedacademy/" +
        managedAcademyId +
        "/lineage/" +
        lineageId
    );
    return response.data;
  },
  async swapAcademies(from, to) {
    const response = await Api().patch("bjjlinkadmins/academies/swap/true", {
      academySwap: from,
      academyKeep: to,
    });
    return response.data;
  },
  async removeAcademy(academyId) {
    const response = await Api().delete(
      "bjjlinkadmins/academies/remove/" + academyId
    );
    return response.data;
  },
  async addAcademy(data) {
    const response = await Api().post("bjjlinkadmins/academies/add", data);
    return response.data;
  },
  async refreshData() {
    const response = await Api().post("products/admin/reports/process-data", {
      current: true,
    });
    return response.data;
  },
  async getHomeData(data) {
    const response = await Api().post("products/admin/reports/data/home", data);
    return response.data;
  },
  async getRegionData(data) {
    const response = await Api().post(
      "products/admin/reports/data/region",
      data
    );
    return response.data;
  },
  async getAvailableRegions() {
    const response = await Api().get("products/admin/reports/list/regions");
    return response.data;
  },
  async compareMonths(data) {
    const response = await Api().post(
      "products/admin/reports/compare-data",
      data
    );
    return response.data;
  },
  async getReferralData(data) {
    const response = await Api().post(
      "products/admin/reports/referral-data",
      data
    );
    return response.data;
  },

  async getReferralAggregates(data) {
    const response = await Api().post(
      "products/admin/reports/referral-aggregates",
      data
    );
    return response.data;
  },
};
