<template>
  <svg version="1.0" xmlns="http://www.w3.org/2000/svg" :width="iconSize" viewBox="0 0 4218.000000 1500.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,1500.000000) scale(0.100000,-0.100000)" :fill="iconColor" stroke="none">
      <path d="M25352 14292 c-18 -2 -36 -10 -39 -18 -3 -8 -18 -14 -33 -14 -16 0
-32 -4 -35 -10 -3 -5 -17 -10 -31 -10 -13 0 -32 -4 -42 -9 -9 -6 -33 -13 -53
-16 -21 -3 -42 -12 -49 -20 -7 -8 -28 -17 -49 -21 -42 -7 -97 -30 -106 -44 -3
-6 -19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4
-26 -10 -3 -5 -21 -10 -40 -10 -19 0 -37 -4 -40 -10 -3 -5 -18 -10 -33 -10
-15 0 -40 -7 -56 -15 -15 -8 -43 -15 -60 -15 -21 0 -40 -8 -55 -24 -16 -17
-36 -25 -74 -28 -29 -2 -68 -12 -86 -21 -19 -9 -45 -17 -58 -17 -12 0 -44 -11
-70 -24 -101 -51 -136 -66 -154 -66 -11 0 -42 -11 -71 -25 -81 -39 -95 -35
-195 58 -34 31 -68 57 -76 57 -9 0 -17 4 -19 8 -1 5 -28 27 -58 49 -30 22 -68
55 -84 72 -17 17 -34 31 -39 31 -5 0 -38 27 -74 60 -41 39 -72 60 -88 60 -13
0 -27 5 -30 10 -4 6 -57 10 -126 10 -73 0 -119 -4 -119 -10 0 -5 -17 -10 -39
-10 -22 0 -44 -6 -51 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5
-16 -10 -29 -10 -25 0 -82 -25 -91 -41 -4 -5 -28 -9 -55 -9 -27 0 -52 -4 -55
-10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -18 -10 -39 -10 -22 0
-42 -4 -45 -9 -3 -5 -20 -11 -38 -14 -64 -11 -147 -32 -154 -40 -4 -4 -17 -7
-29 -7 -13 0 -28 -7 -35 -15 -7 -8 -28 -15 -46 -15 -19 0 -42 -4 -52 -9 -28
-14 -87 -31 -109 -31 -12 0 -41 -11 -64 -25 -24 -14 -56 -25 -71 -25 -15 0
-30 -4 -33 -10 -3 -5 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -17 -10 -29
-10 -13 0 -29 -7 -36 -15 -7 -8 -23 -15 -36 -15 -14 0 -32 -4 -42 -9 -25 -13
-86 -31 -104 -31 -18 0 -75 -28 -83 -41 -4 -5 -25 -9 -49 -9 -41 0 -99 -21
-111 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -4 -45 -10 -3 -5 -19 -10 -36 -10 -16
0 -29 -4 -29 -10 0 -5 -17 -10 -37 -10 -21 0 -51 -7 -67 -15 -15 -8 -38 -15
-50 -15 -11 -1 -32 -7 -45 -14 -14 -8 -30 -12 -36 -9 -7 2 -33 -7 -59 -20 -27
-14 -60 -23 -76 -21 -16 2 -49 -7 -76 -21 -27 -14 -63 -25 -81 -25 -18 0 -52
-11 -77 -25 -25 -14 -56 -25 -71 -25 -14 0 -34 -4 -45 -10 -11 -5 -29 -14 -40
-20 -11 -5 -33 -10 -49 -10 -17 0 -32 -4 -35 -9 -4 -5 -32 -17 -64 -27 -31 -9
-61 -21 -67 -26 -5 -4 -26 -8 -47 -8 -21 0 -38 -4 -38 -10 0 -5 -8 -10 -19
-10 -10 0 -24 -7 -31 -15 -8 -9 -29 -15 -56 -15 -25 0 -59 -9 -79 -20 -20 -11
-48 -20 -63 -20 -15 0 -37 -6 -48 -14 -11 -8 -46 -19 -79 -25 -32 -6 -63 -15
-68 -20 -5 -5 -16 -7 -23 -4 -8 3 -16 -1 -19 -9 -8 -19 -70 -16 -120 6 -22 10
-54 19 -70 20 -17 1 -49 12 -73 24 -23 12 -54 22 -67 22 -14 0 -39 9 -57 20
-18 11 -46 20 -61 20 -16 0 -43 7 -60 16 -61 32 -67 34 -97 34 -16 0 -45 9
-65 20 -20 11 -45 20 -56 20 -20 0 -41 8 -137 51 -23 10 -60 19 -82 19 -21 0
-41 4 -45 9 -10 18 -68 41 -101 41 -18 0 -36 5 -39 10 -3 6 -13 10 -21 10 -9
0 -22 7 -30 15 -9 8 -33 15 -55 15 -21 0 -39 5 -39 10 0 6 -9 10 -19 10 -11 0
-23 5 -26 11 -4 5 -19 7 -34 4 -19 -4 -32 -1 -41 10 -7 9 -21 13 -31 10 -11
-4 -26 0 -33 8 -9 8 -26 12 -39 10 -14 -3 -28 0 -31 6 -4 6 -20 11 -37 11 -16
0 -29 4 -29 8 0 5 -26 15 -57 22 -32 7 -77 24 -101 37 -23 13 -55 23 -71 23
-29 0 -86 24 -96 41 -4 5 -24 9 -45 9 -21 0 -42 5 -45 10 -3 6 -17 10 -30 10
-13 0 -45 11 -71 25 -26 14 -55 25 -65 25 -10 0 -21 5 -24 10 -3 6 -19 10 -35
10 -16 0 -32 5 -35 10 -3 6 -18 10 -32 10 -30 0 -103 30 -103 42 0 4 -20 8
-45 8 -25 0 -45 5 -45 10 0 6 -8 10 -18 10 -9 0 -42 9 -72 21 -30 11 -62 21
-70 21 -8 1 -21 5 -28 11 -7 6 -27 12 -45 14 -18 2 -42 5 -55 7 -12 1 -28 8
-34 14 -7 7 -24 12 -39 12 -15 0 -31 5 -34 10 -3 6 -17 10 -30 10 -14 0 -40 9
-60 20 -20 11 -54 20 -76 20 -28 0 -51 8 -73 25 -24 18 -45 25 -79 25 -26 0
-47 5 -47 10 0 6 -8 10 -19 10 -10 0 -24 7 -31 15 -7 8 -27 15 -46 15 -18 0
-36 5 -39 10 -4 6 -57 10 -125 10 -68 0 -121 -4 -125 -10 -3 -5 -14 -10 -23
-10 -10 0 -40 -22 -67 -50 -27 -27 -54 -50 -60 -50 -6 0 -31 -20 -55 -45 -24
-25 -50 -45 -57 -45 -7 0 -35 -20 -63 -45 -28 -25 -55 -45 -61 -45 -5 0 -56
-42 -112 -93 -56 -52 -107 -96 -114 -98 -6 -2 -36 -28 -66 -56 -31 -29 -62
-53 -69 -53 -8 0 -25 -12 -39 -26 -13 -14 -44 -40 -69 -57 -25 -17 -56 -41
-70 -53 -108 -99 -138 -124 -149 -124 -6 0 -26 -14 -44 -31 -17 -18 -48 -44
-67 -58 -36 -26 -89 -69 -181 -148 -27 -24 -53 -43 -58 -43 -4 0 -32 -22 -61
-50 -29 -27 -58 -50 -63 -50 -5 0 -34 -21 -64 -47 -66 -58 -154 -133 -225
-191 -29 -24 -77 -63 -107 -88 -30 -24 -58 -44 -63 -44 -4 0 -35 -25 -68 -55
-33 -30 -63 -55 -67 -55 -5 0 -44 -34 -88 -75 -43 -41 -84 -75 -89 -75 -5 0
-35 -25 -67 -55 -32 -30 -64 -55 -71 -55 -8 0 -27 -14 -44 -31 -16 -17 -47
-41 -69 -54 -22 -13 -55 -42 -73 -65 -19 -22 -39 -40 -45 -40 -6 0 -30 -20
-54 -45 -23 -25 -48 -45 -54 -45 -6 0 -32 -18 -57 -40 -107 -92 -181 -150
-190 -150 -5 0 -28 -20 -51 -45 -23 -25 -46 -45 -53 -45 -10 0 -37 -21 -147
-117 -26 -24 -53 -43 -60 -43 -6 0 -16 -11 -21 -25 -5 -14 -14 -25 -21 -25 -6
0 -30 -16 -53 -35 -24 -19 -46 -35 -50 -35 -8 0 -75 -55 -160 -132 -29 -27
-57 -48 -62 -48 -6 0 -30 -20 -54 -45 -24 -25 -50 -45 -58 -45 -7 0 -28 -15
-45 -33 -18 -18 -50 -46 -71 -62 -22 -17 -56 -45 -75 -62 -20 -18 -41 -33 -46
-33 -6 0 -31 -20 -55 -45 -24 -25 -49 -45 -55 -45 -5 0 -37 -25 -70 -55 -33
-30 -63 -55 -67 -55 -4 0 -46 -37 -94 -82 -47 -46 -96 -87 -107 -91 -12 -4
-29 -17 -39 -27 -10 -11 -37 -24 -61 -30 -23 -7 -61 -20 -85 -31 -23 -10 -53
-19 -66 -19 -26 0 -86 -30 -86 -42 0 -5 -20 -8 -45 -8 -25 0 -45 -4 -45 -10 0
-5 -13 -10 -29 -10 -16 0 -34 -7 -41 -15 -7 -8 -23 -15 -36 -15 -13 0 -36 -5
-51 -11 -60 -25 -130 -48 -161 -54 -10 -1 -35 -13 -55 -24 -22 -12 -55 -21
-81 -21 -32 0 -48 -6 -66 -25 -17 -18 -34 -25 -61 -25 -21 0 -41 -4 -44 -10
-3 -5 -19 -10 -36 -10 -16 0 -29 -4 -29 -9 0 -5 -22 -11 -50 -13 -27 -3 -57
-11 -66 -19 -9 -8 -27 -12 -40 -11 -15 2 -26 -3 -30 -13 -5 -12 -14 -14 -34
-10 -18 4 -43 -2 -77 -20 -28 -14 -61 -25 -72 -25 -12 0 -39 -9 -61 -20 -22
-11 -50 -20 -63 -20 -26 0 -83 -25 -92 -41 -4 -5 -22 -9 -40 -9 -19 0 -50 -9
-70 -20 -20 -11 -51 -20 -69 -20 -18 0 -54 -11 -80 -25 -26 -14 -56 -25 -66
-25 -10 0 -22 -4 -25 -10 -3 -5 -21 -10 -40 -10 -19 0 -36 -4 -40 -9 -10 -18
-68 -41 -101 -41 -18 0 -36 -4 -39 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7
-41 -15 -7 -8 -25 -15 -40 -15 -26 0 -102 -27 -142 -50 -10 -5 -24 -7 -33 -4
-8 4 -23 0 -32 -7 -9 -8 -28 -15 -42 -16 -42 -2 -103 -19 -150 -41 -24 -11
-50 -18 -57 -15 -7 2 -30 -7 -53 -21 -23 -15 -55 -26 -75 -26 -19 0 -38 -4
-41 -10 -3 -5 -16 -10 -28 -10 -12 0 -31 -7 -41 -15 -11 -8 -32 -15 -47 -15
-15 0 -31 -5 -34 -10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -17
-10 -30 -10 -13 0 -44 -11 -69 -25 -25 -14 -56 -25 -70 -25 -14 0 -28 -4 -31
-10 -3 -5 -16 -10 -27 -10 -26 0 -98 -31 -98 -42 0 -4 -20 -8 -45 -8 -25 0
-45 -4 -45 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -24 -10 -46
-10 -24 0 -54 -9 -77 -23 -20 -13 -43 -22 -50 -19 -7 2 -22 -4 -32 -13 -15
-14 -23 -15 -33 -6 -11 8 -18 6 -32 -9 -10 -12 -24 -19 -31 -16 -19 7 -164
-33 -164 -46 0 -4 -18 -8 -39 -8 -22 0 -42 -4 -46 -9 -10 -18 -68 -41 -101
-41 -18 0 -36 -4 -39 -10 -3 -5 -19 -10 -35 -10 -16 0 -32 -5 -35 -10 -3 -5
-17 -10 -31 -10 -14 0 -47 -11 -74 -25 -27 -14 -61 -25 -75 -25 -14 0 -25 -4
-25 -10 0 -5 -8 -10 -19 -10 -10 0 -24 -7 -31 -15 -7 -9 -29 -15 -51 -15 -21
0 -41 -4 -44 -10 -3 -5 -19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -8 -10 -19
-10 -10 0 -24 -7 -31 -15 -7 -8 -28 -15 -46 -15 -18 0 -49 -9 -69 -20 -20 -11
-46 -20 -60 -20 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -35 -10 -15 0 -30 -6 -33
-13 -3 -9 -16 -12 -35 -9 -16 2 -40 -2 -53 -9 -13 -7 -28 -12 -33 -11 -4 2
-14 -2 -22 -9 -7 -6 -18 -8 -23 -4 -6 3 -22 -2 -36 -11 -14 -9 -33 -14 -43
-12 -10 3 -27 -1 -37 -8 -10 -8 -29 -14 -42 -14 -13 0 -23 -4 -23 -10 0 -5
-10 -10 -22 -10 -26 0 -98 -31 -98 -42 0 -4 -20 -8 -45 -8 -25 0 -54 -4 -65
-10 -11 -5 -29 -14 -40 -20 -11 -5 -31 -10 -45 -10 -14 0 -43 -11 -65 -25 -22
-14 -51 -25 -65 -25 -24 0 -53 -12 -85 -36 -8 -7 -31 -14 -50 -17 -65 -10 -95
-19 -135 -38 -22 -10 -56 -20 -76 -22 -20 -2 -50 -14 -67 -25 -16 -12 -44 -22
-61 -22 -17 0 -39 -4 -49 -9 -40 -20 -69 -30 -107 -35 -62 -9 -80 -15 -142
-41 -18 -8 -39 -12 -46 -9 -8 3 -20 -2 -27 -11 -7 -8 -21 -15 -31 -15 -11 0
-19 -4 -19 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -13 -10 -29
-10 -16 0 -34 -7 -41 -15 -7 -8 -28 -15 -46 -15 -18 0 -48 -9 -66 -20 -18 -11
-42 -20 -54 -20 -23 0 -80 -26 -89 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -5 -45
-10 -3 -6 -17 -10 -31 -10 -24 0 -31 -2 -91 -34 -17 -9 -40 -16 -52 -16 -11 0
-36 -9 -56 -20 -20 -11 -51 -20 -69 -20 -18 0 -39 -7 -46 -15 -7 -8 -21 -15
-31 -15 -11 0 -19 -4 -19 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5
-9 -10 -19 -10 -11 0 -23 -5 -27 -11 -3 -6 -17 -8 -30 -5 -13 4 -37 0 -52 -7
-15 -8 -33 -12 -41 -9 -7 2 -16 0 -20 -4 -9 -13 -73 -34 -104 -34 -15 0 -27
-4 -27 -8 0 -12 -73 -42 -103 -42 -26 0 -35 -3 -84 -28 -13 -7 -36 -12 -53
-12 -17 0 -48 -11 -70 -25 -22 -14 -53 -25 -69 -25 -16 0 -33 -5 -36 -10 -3
-6 -14 -10 -24 -10 -10 0 -24 -7 -31 -15 -7 -9 -29 -15 -51 -15 -22 0 -39 -4
-39 -10 0 -5 -13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3 -6 -17 -10 -30 -10 -13
0 -26 -4 -29 -9 -4 -5 -32 -17 -64 -27 -31 -9 -61 -21 -67 -26 -5 -4 -19 -8
-31 -8 -13 0 -26 -5 -29 -10 -3 -6 -19 -10 -34 -10 -16 0 -34 -7 -41 -15 -8
-9 -21 -13 -36 -9 -13 3 -37 -2 -56 -11 -67 -36 -70 -37 -80 -27 -6 6 -26 1
-54 -14 -25 -13 -60 -24 -79 -24 -19 0 -37 -5 -40 -10 -3 -6 -17 -10 -31 -10
-24 0 -30 -2 -92 -33 -19 -9 -49 -17 -67 -17 -24 0 -38 -6 -45 -20 -8 -16 -21
-20 -60 -20 -27 0 -51 -4 -55 -9 -11 -18 -68 -41 -102 -41 -18 0 -33 -4 -33
-10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -6 -19 -10 -34 -10 -15 0
-36 -7 -47 -15 -10 -8 -31 -15 -46 -15 -15 0 -30 -5 -33 -10 -3 -6 -17 -10
-30 -10 -13 0 -27 -4 -30 -10 -3 -5 -14 -10 -24 -10 -10 0 -24 -7 -31 -15 -7
-8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -6 -22 -10 -40 -10 -19 0 -50 -9
-70 -20 -20 -11 -48 -20 -63 -20 -44 0 -149 -77 -168 -124 -8 -22 -24 -46 -35
-54 -19 -13 -19 -63 -19 -2943 0 -2618 2 -2929 15 -2929 9 0 20 -12 25 -26 14
-35 115 -134 137 -134 11 0 28 -7 39 -15 27 -20 193 -21 220 0 10 8 29 15 41
15 12 0 38 9 58 20 20 11 51 20 69 20 18 0 39 7 46 15 7 8 21 15 31 15 11 0
19 5 19 10 0 6 16 10 35 10 19 0 49 9 67 20 18 11 45 20 59 20 15 0 32 7 39
15 7 8 21 15 31 15 11 0 19 5 19 10 0 6 20 10 45 10 25 0 45 5 45 10 0 6 9 10
19 10 11 0 23 5 27 11 3 6 15 9 25 6 10 -3 26 1 35 8 10 7 25 10 35 8 9 -3 20
0 23 6 4 6 18 11 31 11 13 0 27 5 30 10 3 6 23 10 43 10 26 0 46 8 64 25 20
18 38 25 68 25 22 0 40 5 40 10 0 6 8 10 19 10 10 0 24 7 31 15 7 8 29 15 50
15 21 0 43 7 50 15 7 8 28 17 49 20 20 3 44 10 53 16 10 5 25 9 35 9 9 0 24 6
32 13 18 16 88 37 120 37 11 0 21 5 21 10 0 6 8 10 19 10 10 0 24 7 31 15 7 8
28 15 48 15 19 0 43 7 52 15 10 9 35 19 56 22 22 3 46 11 54 17 33 24 58 34
108 41 29 4 59 14 67 22 8 8 20 11 26 7 6 -3 21 -2 32 3 35 16 92 33 110 33 9
0 17 5 17 10 0 6 13 10 28 10 26 0 64 14 114 41 10 5 31 9 47 9 15 0 40 9 54
20 14 11 36 20 49 20 14 0 28 3 32 7 4 5 11 8 14 8 4 1 21 9 39 18 18 9 41 17
51 17 22 0 79 27 87 41 4 5 19 9 35 9 16 0 32 5 35 10 3 6 21 10 39 10 33 0
91 23 101 41 4 5 22 9 40 9 19 0 49 9 67 20 18 11 43 20 56 20 12 0 40 11 62
25 22 14 46 22 55 19 9 -4 24 1 34 10 10 9 25 14 35 10 9 -3 23 0 32 7 15 12
32 17 84 23 33 4 76 22 85 35 3 6 22 11 41 11 19 0 42 4 52 9 51 27 94 41 126
41 20 0 36 5 36 10 0 6 9 10 19 10 11 0 23 4 26 10 3 5 13 10 21 10 8 0 37 11
66 25 28 14 60 25 69 25 10 0 21 5 24 10 3 6 16 10 29 10 25 0 82 25 91 41 4
5 22 9 41 9 19 0 42 4 52 9 38 19 69 30 104 36 21 3 38 9 38 13 0 10 60 32 88
32 12 0 22 5 22 10 0 6 9 10 19 10 11 0 23 4 26 10 3 5 19 10 35 10 16 0 39 4
52 9 50 20 64 25 80 26 10 1 24 8 32 14 8 7 19 10 24 7 9 -5 46 9 94 35 10 5
31 9 48 9 16 0 30 5 30 10 0 6 9 10 19 10 11 0 23 4 26 10 3 5 18 10 33 10 15
0 49 11 76 25 27 14 64 25 82 25 18 0 35 4 39 9 9 16 66 41 92 41 12 0 23 5
23 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 28
15 46 15 18 0 49 9 69 20 20 11 47 20 61 20 14 0 45 11 70 25 25 14 56 25 69
25 14 0 25 5 25 10 0 6 9 10 19 10 11 0 22 4 25 9 3 5 21 9 38 8 18 0 53 9 77
21 24 12 48 19 52 16 5 -3 19 1 31 8 13 7 28 11 35 10 6 -1 32 9 57 23 24 14
56 25 70 25 14 0 41 9 61 20 20 11 51 20 70 20 18 0 36 4 40 9 9 16 66 41 91
41 13 0 36 5 51 11 15 7 44 18 63 26 19 8 43 18 52 23 10 6 27 10 38 10 10 0
35 9 55 20 20 11 47 20 61 20 14 0 45 11 70 25 25 14 58 25 75 25 16 0 47 11
69 25 22 14 51 25 64 25 14 0 28 4 31 10 3 5 15 10 26 10 10 0 19 4 19 9 0 5
17 8 38 8 20 0 55 8 77 18 22 10 47 19 55 21 84 17 103 24 120 39 10 9 37 19
59 22 23 3 44 10 47 14 3 5 14 9 25 9 10 0 19 5 19 10 0 6 15 10 34 10 18 0
54 11 80 25 26 14 63 25 82 25 18 0 34 4 34 8 0 10 60 32 87 32 12 0 23 7 27
15 3 9 18 15 35 15 16 0 33 4 36 10 3 5 15 10 26 10 10 0 19 5 19 10 0 6 17
10 39 10 22 0 44 6 51 15 7 8 21 15 31 15 10 0 21 5 24 10 3 6 19 10 35 10 16
0 49 11 72 24 24 13 48 21 55 19 6 -2 14 1 18 6 3 6 19 11 35 11 15 0 30 5 32
12 2 6 23 13 45 14 22 1 56 9 74 17 19 8 54 20 79 27 25 6 53 16 62 21 10 5
25 9 33 9 8 0 23 4 33 9 9 6 33 12 53 16 20 3 44 14 54 25 11 12 31 20 50 20
32 0 90 23 100 41 4 5 21 9 40 9 18 0 50 9 72 19 75 38 111 51 137 51 14 0 26
5 26 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 21 10 39 10 33 0 91 23 101 41 4
5 23 9 43 9 21 0 48 9 62 20 14 11 38 20 53 20 16 0 41 8 55 19 15 10 42 18
61 18 20 0 39 6 46 16 8 11 15 13 22 6 8 -8 18 -7 34 3 13 7 29 13 34 13 6 1
19 6 30 12 11 6 36 13 56 17 21 4 42 13 49 21 7 9 29 15 51 15 21 0 40 4 44 9
10 17 67 41 96 41 16 0 44 9 64 20 20 11 47 20 61 20 14 0 45 11 69 25 40 22
46 23 72 10 16 -8 42 -28 58 -45 16 -16 34 -30 40 -30 6 0 42 -31 80 -70 38
-38 77 -70 85 -70 9 0 36 -20 60 -45 24 -25 49 -45 54 -45 5 0 44 -33 86 -73
42 -39 94 -87 116 -105 21 -18 51 -44 66 -58 15 -13 30 -24 35 -24 13 -1 63
-48 63 -60 0 -5 -7 -10 -15 -10 -8 0 -30 -9 -50 -20 -20 -11 -51 -20 -70 -20
-19 0 -35 -4 -35 -8 0 -13 -47 -32 -79 -32 -16 0 -34 -7 -41 -15 -7 -9 -20
-13 -28 -10 -8 3 -21 -3 -28 -12 -12 -15 -15 -16 -23 -3 -8 12 -12 12 -29 -3
-10 -9 -26 -17 -35 -17 -9 0 -19 -4 -22 -10 -3 -5 -16 -10 -27 -10 -38 0 -152
-42 -163 -60 -3 -6 -22 -10 -40 -10 -19 0 -50 -9 -70 -20 -20 -11 -46 -20 -59
-20 -13 0 -29 -7 -36 -15 -7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5
-17 -10 -31 -10 -13 0 -33 -4 -44 -10 -11 -5 -29 -14 -40 -20 -11 -5 -29 -10
-39 -10 -11 0 -22 -4 -26 -9 -9 -16 -66 -41 -91 -41 -13 0 -26 -4 -29 -10 -3
-5 -24 -10 -45 -10 -21 0 -41 -4 -45 -9 -10 -16 -67 -41 -94 -41 -15 0 -44 -9
-66 -20 -22 -11 -51 -20 -65 -20 -14 0 -31 -5 -38 -12 -14 -14 -37 -20 -72
-20 -14 0 -48 -11 -76 -24 -28 -13 -58 -24 -67 -24 -9 0 -19 -4 -22 -10 -3 -5
-19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -9 -10 -19 -10 -11 0 -21 -4 -23
-10 -1 -5 -20 -10 -41 -12 -21 -1 -40 -5 -43 -9 -9 -15 -68 -39 -95 -39 -15 0
-49 -11 -75 -25 -26 -14 -53 -25 -60 -25 -7 0 -29 -9 -49 -20 -20 -11 -46 -20
-59 -20 -13 0 -29 -7 -36 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -5 -34 -10 -3
-6 -19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -13 -10 -29 -10 -17 0 -32 -4
-36 -9 -10 -17 -67 -41 -97 -41 -15 0 -38 -5 -51 -12 -51 -26 -59 -29 -77 -23
-10 3 -21 1 -25 -5 -3 -5 -14 -10 -24 -10 -10 0 -24 -6 -30 -14 -6 -7 -22 -11
-35 -9 -13 3 -27 0 -30 -6 -4 -6 -17 -11 -29 -11 -12 0 -31 -7 -41 -15 -10 -8
-23 -12 -27 -9 -5 3 -19 -2 -31 -10 -12 -9 -30 -16 -40 -16 -10 0 -33 -9 -53
-20 -20 -11 -48 -20 -64 -20 -16 0 -34 -7 -41 -15 -7 -8 -21 -15 -31 -15 -11
0 -19 -4 -19 -10 0 -5 -18 -10 -39 -10 -22 0 -43 -5 -46 -10 -3 -6 -14 -10
-24 -10 -10 0 -24 -7 -31 -15 -7 -8 -25 -15 -39 -15 -15 0 -46 -9 -69 -20 -22
-11 -48 -20 -56 -20 -8 0 -18 -4 -21 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -7
-36 -15 -7 -8 -23 -15 -36 -15 -12 0 -26 -5 -29 -10 -3 -5 -16 -10 -29 -10
-25 0 -82 -25 -91 -41 -4 -5 -22 -9 -41 -9 -33 0 -41 -3 -91 -28 -12 -7 -29
-9 -37 -6 -8 3 -33 -4 -57 -15 -23 -12 -53 -21 -65 -21 -13 0 -26 -5 -29 -10
-3 -6 -17 -10 -31 -10 -13 0 -32 -4 -42 -9 -37 -20 -91 -41 -104 -41 -7 0 -28
-9 -46 -20 -18 -11 -45 -20 -61 -20 -16 0 -34 -7 -41 -15 -7 -8 -21 -15 -31
-15 -11 0 -19 -4 -19 -10 0 -5 -20 -10 -45 -10 -25 0 -45 -4 -45 -10 0 -5 -9
-10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -6 -35 -14
-6 -8 -24 -16 -39 -19 -69 -14 -89 -20 -119 -33 -18 -8 -33 -19 -33 -24 0 -6
-16 -10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 -11 -10 -25 -10 -13 0 -40 -9 -60
-20 -20 -11 -50 -20 -68 -20 -18 0 -38 -5 -45 -12 -18 -18 -80 -36 -90 -26 -5
5 -28 -2 -56 -17 -26 -14 -58 -25 -72 -25 -13 0 -34 -5 -47 -12 -48 -24 -57
-28 -76 -28 -11 0 -23 -4 -26 -10 -3 -5 -16 -10 -29 -10 -12 0 -31 -7 -42 -15
-10 -8 -27 -15 -36 -15 -10 0 -18 -4 -18 -10 0 -5 -16 -10 -35 -10 -19 0 -35
-4 -35 -10 0 -5 -8 -10 -19 -10 -10 0 -24 -7 -31 -15 -7 -9 -29 -15 -51 -15
-22 0 -39 -4 -39 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -5 -26 -10 -3 -6 -24 -10
-45 -10 -21 0 -41 -4 -45 -9 -9 -16 -66 -41 -91 -41 -13 0 -26 -4 -29 -10 -3
-5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -10 -10 -23 -10 -13 0 -32 -7
-43 -15 -10 -8 -29 -15 -41 -15 -12 0 -25 -5 -28 -10 -3 -6 -15 -10 -26 -10
-10 0 -19 -4 -19 -10 0 -5 -17 -10 -39 -10 -21 0 -44 -6 -50 -14 -7 -8 -20
-15 -29 -16 -63 -3 -81 -6 -88 -13 -4 -4 -14 -7 -21 -7 -8 0 -21 -7 -29 -15
-9 -9 -25 -13 -38 -9 -14 3 -27 -1 -35 -10 -8 -9 -24 -16 -37 -16 -12 0 -26
-5 -29 -10 -3 -6 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -14 -10 -25 -10
-10 0 -38 -11 -61 -25 -24 -14 -56 -25 -70 -25 -14 0 -34 -9 -44 -20 -10 -11
-23 -20 -28 -20 -16 0 -117 -104 -117 -120 0 -9 -4 -20 -9 -25 -9 -10 -21 -78
-22 -125 -2 -48 14 -150 22 -150 5 0 9 -8 9 -18 0 -10 11 -29 25 -42 14 -13
25 -29 25 -35 0 -15 83 -75 104 -75 9 0 21 -9 28 -20 11 -17 24 -20 99 -20 68
0 88 3 93 15 3 8 16 15 30 15 14 0 28 5 31 10 3 6 20 10 36 10 17 0 50 11 75
25 25 14 54 25 64 25 20 0 29 4 77 28 13 7 39 12 58 12 22 0 43 8 56 21 12 12
39 24 60 26 21 3 39 10 39 14 0 5 9 9 19 9 11 0 23 5 26 10 3 6 18 10 33 10
15 0 49 11 76 25 27 14 60 25 72 25 13 0 26 4 29 10 3 5 17 10 30 10 13 0 26
4 30 9 3 5 36 19 74 31 37 11 77 27 87 35 11 8 33 15 50 15 16 0 39 7 50 15
10 8 29 15 41 15 12 0 25 5 28 10 3 6 19 10 35 10 16 0 32 4 35 10 3 5 15 10
26 10 10 0 19 5 19 11 0 6 6 9 14 6 13 -5 37 0 101 23 58 21 94 30 117 30 14
0 37 11 52 25 17 16 39 25 59 25 18 0 52 11 76 25 23 14 58 25 75 25 20 0 40
8 51 20 12 13 31 20 56 20 21 0 41 5 44 10 3 6 14 10 23 10 9 0 39 11 66 25
27 14 60 25 72 25 12 0 24 3 26 8 5 11 68 42 86 42 20 0 89 23 98 33 4 4 20 7
35 7 15 0 30 4 34 9 10 18 68 41 101 41 18 0 36 5 39 11 4 6 13 8 21 5 7 -3
33 4 56 14 24 11 53 20 65 20 13 0 37 9 55 20 18 11 48 20 66 20 17 0 32 5 32
10 0 6 8 10 18 10 9 0 30 7 46 15 15 8 40 15 55 15 14 0 44 9 66 19 103 50
154 71 172 71 22 0 80 26 88 41 4 5 19 9 35 9 16 0 32 5 35 10 3 6 21 10 39
10 33 0 91 23 101 41 4 5 19 9 34 9 16 0 34 7 41 15 7 8 28 17 49 20 20 3 44
10 53 16 10 5 26 9 37 9 10 0 24 7 31 15 7 9 21 13 34 9 14 -3 28 1 35 10 8 9
21 16 30 16 9 0 22 5 28 11 8 8 13 8 17 0 5 -7 15 -6 32 5 13 9 33 14 44 11
10 -3 24 1 31 9 6 8 20 14 30 14 11 0 19 5 19 10 0 6 17 10 38 10 38 0 96 22
107 41 4 5 19 9 35 9 16 0 32 4 35 10 3 5 17 10 30 10 13 0 27 4 30 10 3 5 16
10 28 10 13 0 31 6 42 13 11 7 40 19 65 27 25 7 49 17 55 22 5 4 19 8 30 8 24
0 81 26 90 41 4 5 19 9 36 9 16 0 29 5 29 10 0 6 13 10 29 10 17 0 33 5 36 10
3 6 19 10 36 10 16 0 50 11 76 24 108 55 136 66 168 66 18 0 38 7 45 15 10 12
16 13 27 4 10 -9 18 -8 33 6 11 10 25 15 33 12 7 -2 21 1 31 9 11 7 23 11 28
8 10 -7 74 21 83 36 3 6 22 10 40 10 19 0 51 10 72 22 21 12 49 21 62 20 14
-1 35 5 47 13 13 8 30 15 39 15 8 0 15 5 15 10 0 6 11 10 26 10 14 0 42 11 62
25 20 14 49 25 64 25 15 0 30 5 33 10 3 6 16 10 29 10 25 0 82 25 91 41 4 5
22 9 40 9 19 0 50 9 70 20 20 11 49 20 65 20 16 0 41 11 58 25 20 16 42 25 65
25 20 0 39 5 42 10 3 6 15 10 26 10 10 0 19 5 19 11 0 6 12 9 28 8 26 -3 57 6
129 37 17 8 40 14 51 14 12 0 24 5 27 10 3 6 16 10 28 10 12 0 31 7 41 15 11
8 34 15 52 15 18 0 36 5 39 10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 13 10 29
10 16 0 34 7 41 15 7 8 25 15 41 15 15 0 31 5 34 10 3 6 17 10 30 10 13 0 27
4 30 10 3 5 14 10 24 10 10 0 39 11 65 25 26 14 56 25 66 25 11 0 35 9 55 20
20 11 51 20 70 20 18 0 36 4 40 9 10 17 67 41 95 41 15 0 48 11 74 25 68 36
104 33 153 -13 109 -102 166 -152 174 -152 5 0 35 -25 67 -55 31 -30 63 -55
70 -55 7 0 17 -11 22 -25 5 -14 15 -25 21 -25 13 0 58 -36 152 -121 34 -31 72
-60 84 -63 11 -4 26 -13 33 -20 9 -11 4 -17 -21 -30 -18 -9 -42 -16 -55 -16
-12 0 -42 -11 -65 -25 -24 -14 -54 -25 -66 -25 -12 0 -42 -11 -67 -25 -25 -14
-58 -25 -75 -25 -16 0 -33 -4 -36 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19
-10 0 -5 -15 -10 -34 -10 -20 0 -63 -15 -102 -35 -37 -19 -75 -35 -84 -35 -20
0 -77 -27 -85 -41 -4 -5 -21 -9 -39 -9 -18 0 -48 -8 -67 -17 -19 -10 -53 -18
-76 -18 -25 0 -49 -7 -61 -18 -10 -9 -35 -17 -55 -17 -20 0 -39 -4 -42 -10 -3
-5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -13 -10 -29 -10 -16 0 -34 -7
-41 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -5 -34 -10 -3 -6 -17 -10 -30 -10
-13 0 -27 -4 -30 -10 -3 -5 -16 -10 -28 -10 -12 0 -40 -11 -62 -25 -22 -13
-52 -25 -67 -25 -15 0 -30 -4 -33 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19
-10 0 -5 -17 -10 -39 -10 -22 0 -44 -6 -51 -15 -7 -8 -21 -15 -31 -15 -11 0
-19 -4 -19 -10 0 -5 -15 -10 -34 -10 -19 0 -38 -4 -41 -10 -3 -5 -17 -10 -29
-10 -13 0 -28 -6 -34 -13 -6 -7 -34 -19 -62 -27 -28 -8 -53 -18 -56 -22 -3 -4
-16 -8 -29 -8 -13 0 -27 -4 -30 -10 -3 -5 -15 -10 -26 -10 -11 0 -41 -12 -67
-26 -33 -19 -51 -23 -62 -16 -11 6 -30 2 -65 -14 -28 -13 -60 -23 -73 -24 -12
0 -31 -5 -42 -10 -11 -6 -29 -15 -40 -20 -11 -6 -29 -10 -39 -10 -11 0 -23 -4
-26 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7 -41 -15 -7 -8 -21 -15 -31 -15
-10 0 -21 -4 -24 -10 -3 -5 -19 -10 -35 -10 -15 0 -48 -11 -71 -25 -24 -14
-56 -25 -71 -25 -16 0 -28 -4 -28 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20
-10 0 -5 -15 -10 -34 -10 -18 0 -54 -11 -80 -25 -26 -14 -63 -25 -82 -25 -18
0 -34 -4 -34 -10 0 -5 -7 -10 -15 -10 -8 0 -23 -4 -33 -9 -9 -4 -33 -15 -52
-23 -19 -8 -55 -25 -78 -37 -24 -11 -59 -21 -77 -21 -18 0 -38 -6 -44 -14 -6
-7 -21 -11 -34 -9 -14 3 -29 -2 -36 -11 -10 -12 -17 -13 -27 -5 -10 8 -21 5
-45 -12 -19 -14 -46 -22 -65 -22 -18 1 -38 -5 -45 -13 -6 -8 -20 -14 -30 -14
-11 0 -19 -4 -19 -10 0 -5 -16 -10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 -13 -10
-29 -10 -16 0 -34 -7 -41 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -5 -34 -10 -3
-6 -14 -10 -24 -10 -61 0 -201 -135 -201 -193 0 -14 -5 -29 -10 -32 -13 -8
-13 -182 0 -190 5 -3 10 -21 10 -40 0 -26 11 -45 52 -90 51 -57 112 -96 136
-88 7 3 12 -4 12 -16 0 -20 5 -21 94 -21 74 0 95 3 100 15 3 8 14 15 25 15 11
0 27 7 35 16 9 8 24 13 34 10 10 -4 27 1 38 9 10 8 27 15 37 15 9 0 17 5 17
11 0 7 10 9 25 5 17 -4 40 2 72 19 27 14 61 25 77 25 16 0 44 11 62 25 18 14
46 25 63 25 17 0 46 9 66 20 20 11 46 20 59 20 13 0 29 7 36 15 7 8 25 15 41
15 16 0 29 5 29 10 0 6 9 10 20 10 10 0 32 5 47 11 70 29 93 40 98 49 3 6 22
10 40 10 19 0 50 9 70 20 20 11 51 20 70 20 18 0 36 4 40 9 9 15 66 41 89 41
21 0 130 35 151 48 6 4 19 5 31 4 11 -2 26 4 33 12 7 8 19 12 26 10 8 -3 24 1
35 9 12 8 29 13 39 10 10 -2 35 7 56 21 21 14 48 26 60 26 12 0 44 11 71 25
27 14 59 25 71 25 12 0 38 9 58 20 20 11 46 20 60 20 13 0 27 4 30 10 3 5 16
10 28 10 13 0 31 6 42 13 11 8 45 21 75 30 30 10 69 26 85 37 17 11 42 20 57
20 15 0 43 9 63 20 20 11 51 20 70 20 18 0 36 4 40 9 9 16 66 41 91 41 13 0
45 7 71 15 26 8 64 20 83 26 36 11 73 25 102 40 10 5 28 9 40 9 24 0 83 19
105 33 7 4 35 15 63 23 27 8 50 19 50 24 0 6 15 10 33 10 18 0 42 7 53 15 10
8 27 15 37 15 9 0 17 5 17 10 0 6 12 10 28 10 33 0 98 22 114 38 7 7 20 12 30
12 10 0 26 4 36 9 9 6 29 12 44 15 15 4 34 13 42 22 9 8 33 18 53 21 21 4 53
15 71 25 18 10 46 18 63 18 16 0 47 11 68 24 23 15 47 23 59 19 11 -2 41 4 66
16 26 11 52 18 60 15 7 -3 19 2 26 11 7 8 25 15 41 15 16 0 29 5 29 10 0 6 13
10 29 10 17 0 33 5 36 10 3 6 17 10 31 10 13 0 42 11 64 25 22 14 51 25 65 25
14 0 43 9 65 20 22 11 51 20 65 20 28 0 79 -34 132 -87 17 -17 44 -38 60 -46
15 -9 46 -34 68 -56 21 -23 45 -41 53 -41 8 0 46 -32 85 -70 38 -39 75 -70 82
-70 6 0 18 -12 25 -26 12 -22 12 -27 0 -34 -8 -6 -23 -10 -32 -10 -9 0 -20 -4
-23 -10 -3 -5 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -23 -10 -44 -10
-27 0 -44 -7 -61 -25 -16 -17 -34 -25 -56 -25 -18 0 -36 -4 -39 -10 -3 -5 -15
-10 -25 -10 -11 0 -39 -11 -64 -25 -25 -14 -54 -25 -65 -25 -11 0 -36 -9 -56
-20 -20 -11 -51 -20 -69 -20 -18 0 -39 -7 -46 -15 -7 -8 -20 -15 -29 -15 -9 0
-22 -5 -28 -11 -7 -7 -13 -8 -17 0 -4 7 -19 3 -43 -10 -20 -11 -51 -21 -69
-22 -19 -2 -39 -8 -46 -15 -7 -7 -24 -12 -39 -12 -15 0 -31 -4 -34 -10 -3 -5
-19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -11 -10 -25 -10 -14 0 -43 -11 -65
-25 -22 -14 -47 -25 -55 -25 -49 0 -185 -109 -185 -149 0 -6 -4 -11 -10 -11
-5 0 -10 -16 -10 -35 0 -19 -4 -35 -10 -35 -13 0 -13 -197 0 -205 6 -3 10 -19
10 -34 0 -21 16 -43 68 -93 37 -35 79 -68 95 -72 15 -3 27 -13 27 -21 0 -12
18 -15 100 -15 62 0 100 4 100 10 0 6 12 15 28 21 15 5 34 12 42 16 8 3 24 5
36 4 11 -1 27 5 34 13 7 9 21 16 31 16 11 0 19 5 19 11 0 5 8 9 18 7 11 -2 30
2 43 8 35 16 93 34 111 34 8 0 37 11 64 25 27 14 59 25 70 25 12 0 33 9 47 20
14 11 34 20 45 20 11 0 33 7 48 15 16 8 41 15 57 15 15 0 27 5 27 10 0 6 9 10
19 10 11 0 22 4 25 9 3 4 24 11 47 14 22 3 49 13 59 22 9 9 35 19 56 22 22 4
54 15 72 25 18 10 48 18 67 18 18 0 36 4 40 9 10 17 67 41 95 41 15 0 54 11
88 25 34 14 73 25 86 25 14 0 28 5 31 10 3 6 14 10 24 10 10 0 24 6 30 14 6 7
22 11 36 8 14 -2 36 3 50 12 51 33 86 21 179 -61 26 -24 54 -43 62 -43 8 0 14
-4 14 -9 0 -7 172 -165 205 -189 6 -4 34 -28 64 -55 31 -27 63 -47 76 -47 13
0 27 -4 30 -10 4 -6 57 -10 125 -10 68 0 121 4 125 10 3 6 21 10 38 10 18 0
50 11 72 25 22 13 52 25 67 25 15 0 30 5 33 10 3 6 19 10 35 10 16 0 32 5 35
10 3 6 18 10 33 10 15 0 36 7 46 15 11 8 32 15 47 15 15 0 31 5 34 10 3 6 15
10 26 10 10 0 19 5 19 10 0 6 15 10 33 10 17 0 55 11 82 25 28 13 58 25 67 25
9 0 20 5 23 10 3 6 19 10 35 10 16 0 32 5 35 10 3 6 24 10 45 10 21 0 41 4 45
9 11 18 68 41 101 41 19 0 51 6 71 14 79 28 110 36 142 36 19 0 34 6 38 16 3
9 12 13 21 9 8 -3 19 0 23 6 4 6 20 11 35 11 16 0 40 6 54 13 32 16 92 35 112
35 18 0 75 28 83 41 4 5 28 9 55 9 27 0 52 5 55 10 3 6 15 10 26 10 10 0 19 5
19 10 0 6 16 10 35 10 19 0 35 5 35 10 0 6 12 10 28 10 15 0 40 7 56 15 15 8
35 15 42 15 8 0 14 5 14 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 19 10 34 10
16 0 34 7 41 15 7 9 29 15 51 15 22 0 39 5 39 10 0 6 9 10 19 10 11 0 23 5 26
10 3 6 24 10 45 10 21 0 42 5 45 11 9 14 54 32 85 35 42 4 84 16 99 28 8 6 28
11 45 12 46 3 107 18 143 37 18 9 45 17 62 17 16 0 33 5 36 10 3 6 17 10 29
10 13 0 29 7 36 15 7 9 29 15 51 15 22 0 39 5 39 10 0 6 9 10 19 10 11 0 23 5
26 10 3 6 21 10 39 10 18 0 41 7 52 15 10 8 29 15 41 15 12 0 25 5 28 10 3 6
17 10 31 10 13 0 32 4 42 9 9 5 35 17 57 26 22 9 48 20 57 25 10 6 33 10 52
10 19 0 38 5 41 10 3 6 17 10 31 10 14 0 32 7 40 15 20 19 68 19 88 0 8 -8 26
-15 40 -15 14 0 28 -4 31 -10 3 -5 24 -10 45 -10 21 0 41 -4 45 -9 8 -13 65
-41 83 -41 24 0 82 -20 134 -46 27 -13 66 -24 87 -24 22 0 43 -4 46 -10 3 -5
15 -10 26 -10 10 0 19 -4 19 -10 0 -5 17 -10 39 -10 22 0 44 -6 51 -15 7 -8
21 -15 31 -15 11 0 19 -4 19 -10 0 -5 18 -10 39 -10 22 0 43 -4 46 -10 3 -5
17 -10 30 -10 13 0 27 -5 31 -11 3 -6 17 -9 30 -6 14 2 29 -2 36 -10 7 -8 21
-12 34 -9 12 3 27 0 33 -8 10 -12 84 -32 105 -27 12 3 74 -27 81 -39 3 -6 28
-10 55 -10 27 0 52 -4 55 -10 3 -5 15 -10 26 -10 10 0 19 -4 19 -10 0 -5 17
-10 38 -10 20 0 46 -7 56 -15 11 -8 29 -15 41 -15 12 0 25 -4 30 -8 6 -5 30
-15 55 -22 25 -8 56 -20 70 -27 14 -7 33 -13 42 -13 9 0 20 -5 23 -10 3 -5 24
-10 46 -10 21 0 39 -4 39 -8 0 -15 49 -32 90 -32 21 0 41 -4 45 -9 8 -14 65
-41 87 -41 10 0 42 -12 70 -26 37 -19 61 -25 84 -21 22 4 37 1 45 -9 6 -8 14
-12 17 -9 3 3 11 0 19 -6 7 -7 30 -15 51 -20 20 -4 55 -13 77 -19 22 -7 63
-17 90 -24 59 -15 80 -22 117 -42 14 -8 42 -14 62 -14 19 0 51 -9 71 -20 20
-11 47 -20 60 -20 13 0 48 -10 77 -23 29 -13 73 -29 98 -36 25 -7 53 -17 62
-22 10 -5 26 -9 37 -9 10 0 24 -7 31 -15 7 -9 29 -15 51 -15 21 0 41 -4 44
-10 3 -5 15 -10 26 -10 10 0 19 -4 19 -10 0 -5 12 -10 26 -10 14 0 40 -6 57
-14 77 -32 91 -36 152 -36 35 0 67 -4 70 -10 8 -12 199 -13 223 -1 9 5 34 17
55 27 21 11 56 38 79 61 23 24 47 43 53 43 5 0 52 40 103 89 50 48 102 93 115
100 12 7 47 34 77 62 30 27 70 56 88 65 18 8 36 22 39 30 3 8 11 14 19 14 8 0
41 27 73 60 33 33 63 60 68 60 5 0 29 20 53 45 24 25 49 45 55 45 6 0 33 23
60 50 27 28 54 50 61 50 6 0 38 25 70 55 33 30 64 55 69 55 6 1 30 21 55 45
25 25 50 45 55 45 6 0 32 23 60 50 27 28 55 50 61 50 7 0 16 9 19 20 3 11 12
20 19 20 7 0 27 16 46 35 19 19 41 35 50 35 8 0 42 27 75 60 33 33 64 60 69
60 10 0 65 46 165 137 37 35 73 63 79 63 6 0 27 16 46 35 19 19 48 43 65 53
29 18 74 55 175 144 29 27 57 48 62 48 6 0 30 21 55 46 24 25 60 56 79 69 19
13 72 57 118 99 45 42 91 76 102 76 11 0 20 4 20 9 0 5 26 29 58 55 159 129
189 154 222 188 19 21 39 38 44 38 5 0 35 25 68 55 32 30 65 55 71 55 7 0 32
20 56 45 24 25 50 45 56 45 7 0 33 20 59 45 27 25 51 45 55 45 4 0 33 27 66
60 33 33 64 60 69 60 5 0 33 23 62 50 29 28 58 50 63 50 6 0 26 14 44 31 17
18 44 39 59 48 28 18 82 64 192 163 34 32 68 58 75 58 7 0 37 25 66 55 30 30
61 55 68 55 7 0 28 16 47 35 19 19 38 35 43 35 7 0 60 45 130 113 16 15 35 27
43 27 8 0 14 5 14 10 0 6 24 25 53 44 28 19 68 50 86 70 19 20 43 36 54 36 10
0 32 9 48 21 16 11 45 23 64 26 20 3 38 10 41 14 3 5 14 9 25 9 10 0 19 5 19
10 0 6 20 10 45 10 25 0 45 5 45 10 0 6 15 10 34 10 19 0 38 5 42 11 3 6 15 8
27 5 11 -3 38 4 60 15 23 10 52 19 66 19 14 0 35 7 45 15 11 8 35 15 53 15 18
0 48 9 68 20 20 11 51 20 70 20 18 0 37 5 40 10 3 6 16 10 28 10 12 0 31 7 41
15 11 8 30 15 43 15 12 0 38 9 58 20 20 11 51 20 69 20 18 0 39 7 46 15 7 8
25 15 41 15 16 0 29 5 29 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 21 10 40 10
20 0 50 10 71 25 20 14 49 25 65 25 15 0 31 5 34 10 3 6 19 10 35 10 16 0 32
4 35 10 3 5 21 10 40 10 18 0 40 7 49 15 8 8 21 15 30 15 8 0 18 4 21 10 3 5
16 8 28 6 12 -2 33 1 47 6 14 6 34 11 45 13 11 2 38 9 60 15 22 7 57 14 78 17
20 3 37 9 37 14 0 5 9 9 19 9 11 0 23 4 26 10 3 5 21 10 39 10 18 0 41 7 52
15 10 8 29 15 41 15 12 0 25 5 28 10 3 6 19 10 34 10 29 0 86 24 96 41 4 5 26
9 50 9 26 0 59 8 80 20 20 11 51 20 70 20 18 0 37 5 40 10 3 6 15 10 27 10 11
0 34 7 51 16 60 32 67 34 90 34 26 0 83 25 92 41 4 5 24 9 45 9 21 0 42 5 45
10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 20 10 45 10 26 0 61 8 82 20 21 11 52
20 70 20 30 0 56 8 110 32 11 6 26 8 31 4 5 -3 15 1 22 9 7 9 29 15 51 15 21
0 41 5 44 10 3 6 17 10 30 10 13 0 27 4 30 10 3 6 19 10 34 10 15 0 32 5 37
10 6 6 21 13 34 15 13 2 28 9 34 15 5 5 27 10 48 10 21 0 38 5 38 10 0 6 9 10
19 10 11 0 23 4 26 10 3 5 28 10 55 10 27 0 51 4 55 9 10 17 67 41 96 41 15 0
31 5 34 10 3 6 17 10 29 10 13 0 29 7 36 15 7 8 23 15 36 15 12 0 26 5 29 10
3 6 19 10 35 10 16 0 32 5 35 10 3 6 17 10 31 10 14 0 32 7 40 15 9 8 29 15
45 15 16 0 37 4 47 9 26 13 86 31 104 31 15 0 44 13 78 35 8 5 30 8 48 6 23
-2 38 3 47 15 10 14 16 16 27 6 11 -9 19 -8 35 3 12 8 29 12 38 9 9 -4 24 1
35 11 11 10 23 15 26 12 4 -3 16 0 28 8 11 8 33 15 48 15 15 0 30 4 33 10 3 6
19 10 35 10 16 0 32 5 35 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 28 15 46 15
18 0 49 9 69 20 20 11 51 20 69 20 19 0 54 11 77 25 24 14 56 25 71 25 15 0
30 5 33 10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 17 10 39 10 22 0 44 6 51 15
7 8 23 15 36 15 14 0 32 4 42 9 26 13 86 31 104 31 20 0 78 32 78 43 0 4 22 7
50 7 32 0 63 7 87 21 23 13 47 19 63 15 16 -3 36 1 49 10 11 8 26 12 31 9 5
-3 19 1 31 9 12 8 27 12 34 10 7 -3 16 -1 20 5 3 6 19 11 34 11 16 0 34 7 41
15 7 8 27 15 46 15 18 0 36 4 39 10 3 6 19 10 35 10 16 0 32 5 35 10 3 6 16
10 29 10 12 0 27 5 33 11 5 5 26 11 44 11 19 1 51 12 71 25 21 12 51 23 67 23
16 0 33 5 36 10 3 6 17 10 30 10 13 0 30 6 37 14 8 7 31 16 51 20 20 3 53 9
72 12 19 3 38 10 41 15 3 5 16 9 29 9 13 0 26 4 30 9 3 6 32 18 65 28 33 9 60
21 60 25 0 4 18 8 41 8 23 0 47 7 55 15 9 8 26 15 39 15 13 0 27 4 30 10 3 6
22 10 41 10 19 0 43 5 54 10 11 6 29 14 40 20 10 5 26 7 35 4 9 -4 26 -2 38 3
37 17 92 33 115 33 11 0 24 5 27 10 3 6 17 10 29 10 13 0 29 7 36 15 7 9 21
13 34 9 14 -3 28 1 35 10 8 9 28 16 47 16 18 0 36 4 39 10 3 5 17 10 30 10 13
0 27 4 30 10 3 5 21 10 39 10 19 0 39 7 46 15 7 8 23 15 36 15 12 0 26 5 29
10 3 6 24 10 45 10 21 0 42 5 45 10 3 6 14 10 24 10 10 0 24 7 31 15 7 8 27
15 46 15 18 0 36 5 39 10 3 5 17 10 30 10 13 0 27 5 30 10 3 6 17 10 31 10 13
0 24 5 24 10 0 6 13 10 29 10 16 0 34 7 41 15 7 8 25 15 41 15 15 0 31 4 34
10 3 6 24 10 45 10 21 0 42 5 45 10 3 6 16 10 29 10 12 0 27 5 34 12 7 7 31
14 53 15 22 2 50 10 62 18 12 8 35 15 52 15 17 0 40 6 51 14 12 9 23 11 30 5
7 -6 16 -4 24 6 7 8 28 15 46 15 19 0 34 5 34 10 0 6 11 10 24 10 14 0 28 5
31 10 3 6 21 10 39 10 19 0 39 7 46 15 7 8 25 15 41 15 16 0 29 5 29 10 0 6
13 10 29 10 17 0 33 5 36 10 3 6 21 10 39 10 33 0 91 23 101 41 4 5 24 9 46 9
21 0 48 5 59 10 11 6 29 15 40 20 11 6 29 10 40 10 12 0 41 11 66 25 25 14 58
25 74 25 16 0 32 5 35 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 25 15 41 15 15
0 31 5 34 10 3 6 20 10 36 10 17 0 49 9 71 20 23 11 56 20 74 20 17 0 37 7 44
15 7 9 20 13 28 10 8 -3 20 2 27 12 11 14 16 15 28 4 13 -10 21 -9 46 3 16 9
36 13 44 10 7 -2 20 2 27 11 7 8 23 15 36 15 12 0 26 5 29 10 3 6 19 10 36 10
16 0 29 5 29 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 24 10 45 10 21 0 41 4
45 9 10 17 67 41 97 41 15 0 28 5 28 10 0 6 6 10 14 10 18 0 108 106 122 144
44 122 42 -38 43 3012 2 2556 0 2874 -14 2900 -8 16 -15 39 -15 52 0 36 -86
152 -113 152 -7 0 -22 9 -32 20 -12 13 -31 20 -55 20 -21 0 -43 7 -50 15 -8
10 -31 15 -70 15 -39 0 -62 -5 -70 -15 -8 -9 -29 -15 -56 -15 -25 0 -59 -9
-79 -20 -20 -11 -47 -20 -60 -20 -13 0 -44 -6 -67 -14 -24 -7 -63 -19 -88 -26
-25 -6 -53 -16 -62 -21 -10 -5 -26 -9 -37 -9 -10 0 -24 -7 -31 -15 -7 -9 -29
-15 -51 -15 -21 0 -41 -4 -44 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10
0 -5 -14 -10 -30 -10 -17 0 -53 -11 -82 -25 -28 -14 -58 -25 -65 -25 -7 0 -22
-4 -33 -10 -11 -5 -29 -14 -40 -20 -11 -5 -28 -10 -39 -10 -10 0 -24 -7 -31
-15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5 -19 -10 -36 -10 -16 0
-29 -4 -29 -10 0 -5 -17 -10 -39 -10 -22 0 -44 -6 -51 -15 -7 -8 -21 -15 -31
-15 -10 0 -21 -4 -24 -10 -3 -5 -19 -10 -36 -10 -16 0 -29 -4 -29 -10 0 -5 -8
-10 -19 -10 -10 0 -24 -7 -31 -15 -7 -8 -28 -15 -46 -15 -18 0 -49 -9 -69 -20
-20 -11 -46 -20 -59 -20 -14 0 -44 -8 -68 -18 -24 -11 -48 -17 -55 -15 -6 2
-14 -1 -18 -6 -3 -6 -19 -11 -35 -11 -16 0 -32 -4 -35 -10 -3 -5 -19 -10 -34
-10 -16 0 -34 -7 -41 -15 -7 -8 -16 -12 -22 -9 -5 3 -20 -1 -33 -10 -13 -9
-35 -16 -49 -16 -14 0 -28 -4 -31 -10 -3 -5 -18 -10 -33 -10 -15 0 -49 -11
-76 -25 -27 -14 -57 -25 -67 -25 -10 0 -34 -9 -54 -20 -20 -11 -46 -20 -59
-20 -13 0 -29 -7 -36 -15 -7 -8 -25 -15 -41 -15 -16 0 -29 -4 -29 -10 0 -5
-13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3 -5 -16 -10 -29 -10 -21 0 -61 -15
-108 -41 -10 -5 -27 -9 -38 -9 -10 0 -35 -9 -55 -20 -20 -11 -51 -20 -69 -20
-18 0 -39 -7 -46 -15 -7 -8 -21 -15 -31 -15 -11 0 -19 -4 -19 -10 0 -5 -13
-10 -29 -10 -30 0 -122 -25 -153 -41 -10 -5 -29 -9 -42 -9 -14 0 -28 -4 -31
-10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -35 -10 -16 0
-53 -11 -82 -24 -29 -13 -64 -27 -78 -29 -40 -9 -142 -46 -183 -67 -22 -11
-49 -20 -62 -20 -13 0 -27 -4 -30 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -7 -36
-15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5 -15 -10 -26 -10 -10 0
-27 -4 -37 -9 -43 -23 -92 -41 -111 -41 -11 0 -36 -9 -56 -20 -20 -11 -51 -20
-70 -20 -18 0 -36 -4 -40 -9 -9 -16 -67 -41 -77 -35 -6 4 -20 2 -31 -4 -23
-11 -74 -26 -94 -27 -7 0 -18 -6 -25 -13 -6 -6 -27 -12 -45 -12 -18 0 -33 -4
-33 -9 0 -5 -12 -11 -27 -14 -82 -16 -124 -27 -140 -36 -22 -12 -46 -21 -98
-37 -68 -21 -115 -40 -127 -52 -7 -7 -24 -12 -39 -12 -15 0 -31 -4 -34 -10 -3
-5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7
-41 -15 -7 -8 -25 -15 -41 -15 -16 0 -29 -4 -29 -10 0 -5 -13 -10 -30 -10 -32
0 -80 -19 -80 -32 0 -4 -17 -8 -39 -8 -22 0 -44 -6 -51 -15 -7 -8 -21 -15 -31
-15 -10 0 -21 -4 -24 -10 -3 -5 -20 -10 -36 -10 -17 0 -49 -9 -71 -20 -23 -11
-52 -20 -64 -20 -13 0 -26 -5 -29 -11 -4 -6 -13 -8 -20 -5 -8 3 -28 -2 -45
-11 -17 -8 -49 -17 -70 -20 -22 -3 -45 -12 -51 -19 -6 -8 -24 -14 -40 -14 -15
0 -31 -4 -34 -10 -3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -16 -10
-29 -10 -12 0 -31 -7 -42 -15 -10 -8 -27 -15 -37 -15 -9 0 -36 -8 -60 -19 -23
-10 -64 -23 -92 -30 -27 -7 -57 -19 -66 -27 -8 -8 -27 -14 -42 -14 -14 0 -29
-4 -32 -10 -3 -5 -17 -10 -31 -10 -24 0 -30 -2 -92 -33 -19 -9 -40 -17 -48
-17 -8 0 -34 -9 -56 -20 -23 -11 -56 -20 -74 -20 -18 0 -35 -4 -39 -9 -10 -18
-68 -41 -101 -41 -18 0 -36 -4 -39 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -5
-19 -11 0 -5 -8 -9 -17 -8 -22 3 -72 -9 -95 -22 -10 -5 -29 -9 -42 -9 -14 0
-28 -4 -31 -10 -3 -5 -19 -10 -34 -10 -16 0 -33 -6 -39 -13 -6 -7 -23 -14 -39
-15 -15 -1 -29 -7 -31 -12 -2 -6 -18 -10 -36 -10 -17 0 -49 -9 -71 -20 -22
-11 -51 -20 -66 -20 -15 0 -29 -3 -31 -7 -5 -12 -69 -39 -123 -52 -27 -7 -57
-19 -66 -27 -8 -8 -27 -14 -42 -14 -14 0 -29 -4 -32 -10 -3 -5 -17 -10 -30
-10 -13 0 -27 -4 -30 -10 -3 -5 -16 -10 -29 -10 -12 0 -31 -7 -42 -15 -10 -8
-31 -15 -46 -15 -17 0 -33 -8 -40 -20 -10 -15 -24 -20 -59 -20 -44 0 -102 -21
-114 -41 -4 -5 -26 -9 -51 -9 -25 0 -56 -7 -70 -17 -14 -9 -36 -18 -47 -20
-12 -1 -41 -5 -64 -9 -24 -3 -43 -10 -43 -15 0 -5 -9 -9 -19 -9 -11 0 -23 -4
-26 -10 -3 -5 -24 -10 -45 -10 -21 0 -41 -4 -45 -9 -10 -17 -67 -41 -96 -41
-16 0 -43 -9 -61 -20 -18 -11 -42 -20 -52 -20 -25 0 -96 -31 -96 -42 0 -4 -20
-8 -45 -8 -25 0 -45 -4 -45 -10 0 -5 -13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3
-5 -14 -10 -23 -10 -9 0 -30 -7 -46 -15 -15 -8 -34 -15 -42 -15 -7 0 -29 -9
-49 -20 -20 -11 -44 -20 -54 -20 -21 0 -84 -30 -89 -42 -2 -5 -24 -8 -48 -8
-24 0 -44 -4 -44 -10 0 -5 -8 -10 -19 -10 -10 0 -24 -7 -31 -15 -7 -9 -22 -12
-39 -9 -18 4 -33 0 -44 -11 -9 -9 -21 -14 -27 -10 -9 6 -125 -27 -162 -46 -10
-5 -25 -9 -33 -9 -8 0 -15 -4 -15 -10 0 -5 -17 -10 -38 -10 -38 0 -96 -22
-108 -41 -3 -5 -15 -7 -25 -4 -12 4 -39 -4 -69 -19 -27 -14 -58 -26 -68 -26
-22 0 -79 -27 -87 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -4 -45 -10 -3 -5 -19
-10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10
0 -5 -17 -12 -37 -15 -21 -4 -54 -15 -74 -26 -20 -10 -48 -19 -62 -19 -14 0
-28 -4 -32 -9 -10 -18 -68 -41 -101 -41 -18 0 -36 -4 -39 -10 -3 -5 -15 -10
-26 -10 -10 0 -19 -4 -19 -10 0 -18 -46 -11 -65 10 -10 11 -23 20 -29 20 -7 0
-32 20 -56 45 -24 25 -49 45 -55 45 -5 0 -37 27 -70 60 -33 32 -65 59 -71 60
-7 0 -19 11 -27 24 -9 12 -30 30 -49 39 -18 9 -58 39 -90 66 -31 28 -60 51
-65 51 -5 0 -31 23 -58 50 -27 28 -54 50 -60 50 -15 0 -76 76 -68 84 4 3 15 6
25 6 10 0 18 5 18 10 0 6 11 10 24 10 14 0 46 11 72 25 26 14 62 25 80 25 19
0 34 5 34 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 19 10 35 10 15 0 48 11 71
25 24 14 53 25 65 25 23 0 80 26 89 41 4 5 15 9 26 9 10 0 19 5 19 10 0 6 16
10 35 10 19 0 50 9 70 20 20 11 50 20 68 20 18 1 50 9 72 20 22 10 43 16 47
14 10 -7 79 16 99 33 9 7 27 12 40 11 13 -1 44 8 68 20 25 12 53 22 62 22 10
0 21 5 24 10 3 6 15 10 26 10 11 0 37 11 59 25 23 14 54 25 75 25 19 0 35 5
35 10 0 6 8 10 19 10 10 0 24 7 31 15 7 8 28 15 46 15 18 0 49 9 69 20 20 11
51 20 70 20 18 0 37 4 40 10 3 5 23 17 43 26 20 8 45 20 54 25 10 5 27 9 37 9
11 0 23 5 26 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 23 15 36 15 12 0 26 5
29 10 3 6 15 10 26 10 10 0 19 5 19 11 0 8 12 10 34 6 23 -4 43 1 65 14 17 10
45 19 61 19 17 0 39 5 50 10 11 6 29 15 40 20 11 6 30 10 43 10 13 0 45 11 71
25 26 14 56 25 66 25 10 0 22 5 25 10 3 6 16 10 29 10 13 0 44 11 70 25 26 14
62 25 80 25 20 0 40 8 51 20 12 13 31 20 56 20 21 0 40 4 44 9 10 17 67 41 95
41 28 0 85 24 95 41 4 5 17 9 30 9 14 0 40 9 60 20 20 11 46 20 59 20 13 0 29
7 36 15 7 8 23 15 36 15 12 0 26 5 29 10 3 6 15 10 26 10 10 0 19 5 19 11 0 6
11 8 28 4 16 -3 42 0 58 6 16 7 34 11 41 10 6 -1 14 3 18 9 3 5 19 10 36 10
16 0 29 5 29 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 22 10 41 10 19 0 34 5 34
10 0 6 8 10 18 10 10 0 27 7 38 15 10 8 31 15 46 15 15 0 30 5 33 10 3 6 15
10 25 10 11 0 39 11 64 25 25 14 54 25 65 25 11 0 36 9 56 20 20 11 54 20 79
20 32 0 48 6 66 25 16 17 34 25 56 25 18 0 53 11 78 25 25 14 54 25 65 25 11
0 23 5 26 10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 11 10 25 10 14 0 33 4 43 9
43 22 79 35 106 37 16 1 32 8 37 15 6 11 11 11 20 2 9 -9 19 -8 41 8 16 11 47
22 69 24 22 1 46 8 52 14 6 6 21 11 33 11 13 0 26 5 29 10 3 6 18 10 33 11 15
0 41 9 57 20 17 10 35 20 40 22 42 16 85 27 103 27 12 0 22 5 22 10 0 6 10 10
23 10 12 0 31 7 41 15 11 8 30 15 43 15 12 0 38 9 58 20 20 11 44 20 54 20 21
0 78 27 86 41 4 5 22 9 40 9 19 0 50 9 70 20 20 11 48 20 64 20 16 0 34 7 41
15 7 8 21 15 31 15 10 0 34 9 54 20 20 11 47 20 60 20 14 0 25 5 25 10 0 6 12
10 28 10 15 0 37 7 49 15 15 10 24 12 28 5 5 -8 15 -6 31 5 14 9 29 13 34 10
6 -3 19 1 31 9 12 8 27 12 34 10 6 -3 18 2 25 11 7 8 21 15 31 15 11 0 19 5
19 10 0 6 15 10 34 10 19 0 38 5 41 10 3 6 19 10 34 10 29 0 86 24 96 41 4 5
19 9 36 9 16 0 29 5 29 10 0 6 14 10 30 10 17 0 30 5 30 10 0 6 11 10 24 10
26 0 86 30 86 43 0 4 15 7 33 7 34 0 91 23 102 41 4 5 24 9 45 9 21 0 42 5 45
10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 16 10 35 10 21 0 52 11 75 25 22 14
48 25 59 25 11 0 23 5 26 10 3 6 15 10 27 10 11 0 48 27 84 62 48 46 64 68 64
89 0 15 5 31 10 34 6 3 10 50 10 105 0 55 -4 102 -10 105 -5 3 -10 19 -10 35
0 19 -10 37 -35 59 -19 17 -35 37 -35 45 0 8 -16 23 -35 32 -19 9 -35 20 -35
25 0 5 -14 9 -32 9 -17 0 -40 7 -50 16 -13 12 -37 17 -87 17 -48 0 -78 -5 -95
-17 -14 -9 -34 -16 -45 -16 -11 0 -36 -9 -56 -20 -20 -11 -46 -20 -60 -20 -13
0 -26 -4 -30 -9 -10 -17 -67 -41 -95 -41 -28 0 -85 -24 -95 -41 -4 -5 -22 -9
-41 -9 -19 0 -43 -4 -54 -10 -47 -24 -58 -28 -107 -40 -29 -8 -53 -18 -53 -23
0 -5 -6 -7 -14 -4 -8 3 -20 0 -26 -8 -7 -9 -23 -12 -44 -8 -22 3 -36 0 -45
-11 -7 -9 -22 -16 -32 -16 -10 0 -21 -4 -24 -10 -3 -5 -20 -10 -36 -10 -16 0
-47 -9 -69 -20 -22 -11 -48 -20 -59 -20 -11 0 -41 -11 -67 -25 -26 -14 -54
-25 -63 -25 -19 0 -88 -24 -97 -33 -4 -4 -17 -7 -29 -7 -13 0 -28 -7 -35 -15
-7 -8 -23 -15 -36 -15 -13 0 -38 -9 -56 -20 -18 -11 -42 -20 -54 -20 -23 0
-80 -26 -89 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -4 -45 -10 -3 -5 -14 -10 -24
-10 -10 0 -24 -7 -31 -15 -7 -9 -29 -15 -51 -15 -22 0 -39 -4 -39 -10 0 -5 -7
-10 -15 -10 -8 0 -23 -4 -33 -9 -15 -9 -59 -22 -87 -26 -10 -1 -24 -6 -73 -26
-13 -5 -36 -9 -53 -9 -18 0 -32 -6 -36 -16 -3 -8 -11 -13 -19 -10 -7 3 -18 -2
-25 -10 -7 -8 -20 -12 -31 -8 -11 3 -39 -5 -68 -20 -27 -14 -63 -26 -80 -26
-16 0 -30 -4 -30 -10 0 -5 -7 -10 -16 -10 -8 0 -22 -7 -30 -15 -9 -8 -31 -15
-49 -15 -19 0 -50 -9 -70 -20 -20 -11 -43 -20 -53 -20 -9 0 -26 -7 -36 -15
-11 -8 -28 -15 -38 -15 -9 0 -33 -9 -53 -20 -20 -11 -44 -20 -54 -20 -21 0
-78 -27 -86 -41 -4 -5 -22 -9 -41 -9 -19 0 -43 -4 -54 -10 -11 -5 -29 -14 -40
-20 -11 -5 -29 -10 -41 -10 -11 0 -35 -7 -52 -16 -60 -32 -67 -34 -93 -34 -14
0 -40 -6 -57 -14 -72 -30 -89 -36 -112 -36 -13 0 -40 -9 -60 -20 -20 -11 -47
-20 -60 -20 -14 0 -48 -11 -77 -25 -28 -14 -60 -25 -69 -25 -22 0 -69 -21 -69
-30 0 -4 -20 -10 -44 -13 -24 -4 -61 -16 -80 -27 -20 -11 -47 -20 -61 -20 -13
0 -27 -4 -30 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -7 -36 -15 -7 -8 -21 -15
-31 -15 -11 0 -19 -4 -19 -10 0 -5 -13 -10 -30 -10 -32 0 -80 -19 -80 -31 0
-5 -17 -11 -37 -14 -36 -6 -67 -17 -105 -36 -10 -5 -33 -9 -52 -9 -19 0 -38
-5 -41 -11 -8 -11 -55 -33 -68 -31 -10 3 -137 -36 -159 -49 -10 -5 -29 -9 -42
-9 -14 0 -28 -4 -31 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -14
-10 -30 -10 -17 0 -53 -11 -82 -25 -28 -14 -61 -25 -72 -25 -12 0 -36 -9 -54
-20 -18 -11 -42 -20 -55 -20 -12 0 -44 -11 -71 -25 -27 -14 -60 -25 -72 -25
-13 0 -26 -4 -29 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -7 -36 -15 -7 -8 -21
-15 -31 -15 -11 0 -19 -4 -19 -10 0 -5 -19 -10 -42 -10 -25 0 -57 -8 -76 -20
-18 -11 -41 -20 -51 -20 -10 0 -24 -7 -31 -15 -7 -8 -21 -15 -31 -15 -10 0
-21 -4 -24 -10 -3 -5 -22 -10 -40 -10 -19 0 -50 -9 -70 -20 -20 -11 -46 -20
-58 -20 -25 0 -110 -26 -148 -46 -14 -7 -29 -11 -32 -8 -3 3 -21 -2 -39 -11
-62 -33 -68 -35 -98 -35 -17 0 -48 -11 -70 -25 -22 -14 -50 -25 -62 -25 -13
-1 -41 -9 -63 -20 -22 -11 -48 -19 -57 -20 -10 0 -18 -4 -18 -10 0 -5 -13 -10
-29 -10 -16 0 -34 -7 -41 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3
-5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7
-41 -15 -7 -8 -25 -15 -41 -15 -16 0 -29 -4 -29 -9 0 -11 -55 -31 -86 -31 -13
0 -24 -4 -24 -8 0 -12 -73 -42 -103 -42 -15 0 -41 -8 -60 -19 -18 -10 -57 -22
-85 -26 -29 -4 -54 -11 -58 -16 -3 -5 -16 -9 -29 -9 -13 0 -27 -4 -30 -10 -3
-5 -19 -10 -34 -10 -16 0 -34 -7 -41 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4
-34 -10 -3 -5 -17 -10 -30 -10 -14 0 -40 -9 -60 -20 -22 -12 -54 -20 -84 -20
-35 0 -53 5 -66 20 -10 11 -22 20 -28 20 -5 0 -21 12 -36 28 -15 15 -51 45
-80 67 -28 22 -75 61 -104 87 -29 26 -56 48 -61 48 -5 0 -40 28 -77 63 -96 89
-166 147 -177 147 -6 0 -31 20 -55 45 -25 25 -51 45 -58 45 -17 0 -69 50 -69
67 0 18 42 33 89 33 25 0 44 7 56 20 12 13 31 20 55 20 20 0 41 5 48 12 6 6
20 13 29 14 32 5 93 29 93 36 0 5 7 8 16 8 23 0 113 31 120 41 3 5 21 9 40 9
19 0 34 5 34 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 13 10 29 10 17 0 32 4 36
9 10 18 68 41 101 41 18 0 36 5 39 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 23
15 36 15 12 0 26 5 29 10 3 6 17 10 31 10 13 0 24 5 24 10 0 6 6 10 14 10 7 0
19 6 25 14 7 8 27 14 47 14 19 0 41 6 49 12 8 7 15 9 15 5 0 -5 8 -1 19 9 14
12 24 14 43 7 18 -7 30 -5 49 10 13 10 44 21 69 25 25 4 60 15 78 25 18 11 41
19 52 19 11 0 39 11 64 25 25 14 58 25 75 25 16 0 33 5 36 10 3 6 16 10 29 10
25 0 82 25 91 41 4 5 17 9 30 9 13 0 27 5 30 10 3 6 17 10 31 10 13 0 24 5 24
10 0 6 14 10 32 10 34 0 108 29 108 42 0 4 16 8 35 8 19 0 43 4 53 9 9 5 31
14 47 21 17 7 42 19 57 26 14 8 34 14 45 14 10 0 33 9 51 20 18 11 46 20 62
20 16 0 32 5 35 10 3 6 13 10 22 10 9 0 21 7 28 16 10 14 15 14 33 3 19 -12
25 -10 53 10 19 14 48 24 73 26 45 2 90 18 101 34 3 6 19 11 35 11 16 0 32 5
35 10 3 6 14 10 24 10 21 0 78 27 86 41 4 5 22 9 40 9 19 0 50 9 70 20 20 11
46 20 59 20 13 0 44 11 67 25 24 14 56 25 71 25 15 0 30 5 33 10 3 6 14 10 24
10 10 0 24 7 31 15 7 8 23 15 36 15 12 0 26 5 29 10 3 6 19 10 35 10 16 0 32
5 35 10 3 6 16 10 28 10 12 0 31 7 41 15 11 8 28 15 38 15 10 0 27 5 38 10 11
6 29 15 40 20 11 6 25 10 31 10 6 0 20 11 31 25 11 14 25 25 32 25 16 0 38 25
65 73 19 33 21 54 21 177 0 77 -4 140 -9 140 -5 0 -16 15 -24 33 -18 38 -70
88 -112 109 -102 51 -109 53 -172 51 -39 -1 -74 -8 -88 -17 -14 -9 -36 -16
-50 -16 -14 0 -25 -4 -25 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5
-15 -10 -26 -10 -11 0 -40 -11 -65 -25 -26 -14 -61 -25 -83 -25 -37 0 -95 -22
-106 -41 -4 -5 -17 -9 -30 -9 -14 0 -40 -9 -60 -20 -20 -11 -43 -20 -52 -20
-19 0 -79 -18 -105 -31 -10 -5 -24 -9 -32 -9 -8 0 -17 -7 -20 -16 -5 -13 -9
-14 -20 -4 -11 9 -20 8 -38 -1 -13 -7 -27 -12 -32 -11 -4 2 -14 -2 -21 -8 -7
-6 -39 -17 -71 -26 -33 -8 -63 -19 -68 -25 -6 -5 -23 -9 -40 -9 -32 0 -106
-29 -106 -42 0 -4 -13 -8 -29 -8 -17 0 -33 -4 -36 -10 -3 -5 -16 -10 -28 -10
-12 0 -42 -11 -66 -25 -23 -14 -58 -25 -76 -25 -18 0 -38 -7 -45 -15 -7 -8
-28 -17 -49 -21 -42 -7 -47 -8 -86 -33 -16 -11 -39 -20 -51 -20 -24 -1 -81
-27 -89 -42 -4 -5 -26 -9 -50 -9 -25 0 -59 -8 -78 -19 -18 -10 -52 -22 -75
-26 -23 -4 -44 -11 -48 -16 -3 -5 -16 -9 -28 -9 -13 0 -29 -7 -36 -15 -7 -9
-21 -13 -35 -9 -13 3 -43 -5 -74 -20 -29 -14 -58 -23 -64 -20 -7 2 -36 -7 -65
-21 -28 -14 -58 -25 -66 -25 -8 0 -18 -4 -21 -10 -3 -5 -19 -10 -34 -10 -16 0
-34 -7 -41 -15 -7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5 -16 -10 -29
-10 -12 0 -45 -11 -72 -25 -27 -14 -59 -25 -71 -25 -13 0 -37 -9 -55 -20 -18
-11 -42 -20 -54 -20 -23 0 -80 -26 -89 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -4
-45 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -13 -10 -29 -10 -17
0 -33 -4 -36 -10 -8 -13 -67 -40 -85 -38 -25 3 -100 -14 -106 -23 -3 -5 -14
-9 -24 -9 -10 0 -20 -6 -22 -12 -3 -8 -19 -12 -42 -10 -25 3 -44 -2 -56 -13
-10 -9 -38 -19 -62 -22 -24 -3 -56 -17 -71 -29 -19 -16 -41 -24 -68 -24 -21 0
-39 -4 -39 -10 0 -5 -11 -10 -24 -10 -14 0 -28 -4 -31 -10 -3 -5 -17 -10 -29
-10 -13 0 -29 -7 -36 -15 -7 -8 -21 -15 -31 -15 -11 0 -19 -4 -19 -10 0 -5
-16 -10 -35 -10 -19 0 -35 -4 -35 -10 0 -5 -14 -10 -31 -10 -16 0 -50 -11 -75
-25 -45 -25 -124 -35 -124 -15 0 6 -6 10 -13 10 -8 0 -32 19 -54 43 -22 23
-52 51 -68 62 -16 11 -44 35 -62 53 -19 17 -39 32 -47 32 -7 0 -41 25 -76 55
-35 30 -68 55 -74 55 -12 0 -66 50 -66 61 0 12 60 39 86 39 13 0 26 5 29 10 3
6 19 10 36 10 16 0 29 5 29 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 15 10 26
10 11 0 22 7 25 15 4 8 14 15 23 15 19 0 88 23 97 33 4 4 20 7 36 7 16 0 49
11 74 25 25 14 57 25 73 25 29 0 86 24 96 41 4 5 19 9 35 9 16 0 31 4 34 8 3
4 28 14 56 22 28 8 56 20 62 27 6 7 21 13 34 13 12 0 26 5 29 10 3 6 16 10 29
10 13 0 37 7 53 15 43 22 138 117 156 157 29 64 45 174 28 191 -6 6 -11 23
-11 38 0 15 -6 32 -14 38 -7 6 -16 25 -19 41 -6 30 -71 100 -93 100 -6 0 -19
9 -29 20 -10 11 -29 20 -43 20 -14 0 -30 6 -36 14 -12 14 -89 24 -144 18z
m-9067 -751 c10 -17 67 -41 98 -41 16 0 51 -11 79 -25 27 -14 63 -25 79 -25
16 0 29 -4 29 -10 0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 23 -10 44 -10 22
0 44 -6 51 -15 7 -8 23 -15 36 -15 12 0 26 -4 29 -10 3 -5 17 -10 31 -10 13 0
24 -4 24 -10 0 -5 15 -10 34 -10 19 0 38 -4 41 -10 3 -5 17 -10 29 -10 13 0
29 -7 37 -16 8 -10 22 -14 41 -10 15 3 30 1 34 -5 3 -6 14 -9 24 -6 10 2 25
-4 35 -15 12 -15 24 -19 46 -14 20 4 40 -1 62 -14 17 -11 44 -20 59 -20 15 0
30 -4 33 -10 3 -5 19 -10 35 -10 16 0 32 -4 35 -10 3 -5 16 -10 28 -10 12 0
31 -7 41 -15 11 -8 33 -15 48 -15 16 0 37 -4 48 -10 11 -5 29 -14 40 -20 11
-5 36 -10 55 -10 19 0 35 -4 35 -9 0 -13 59 -41 86 -41 13 0 39 -9 59 -20 20
-11 51 -20 69 -20 18 0 39 -7 46 -15 7 -8 25 -15 41 -15 16 0 29 -4 29 -10 0
-5 12 -10 28 -10 15 0 50 -12 77 -25 28 -14 60 -25 72 -25 12 0 25 -4 28 -10
3 -5 17 -10 30 -10 14 0 50 -11 81 -25 40 -18 60 -22 69 -15 9 7 16 6 25 -5 7
-9 19 -14 26 -11 7 3 17 -1 23 -8 6 -7 36 -16 67 -19 30 -3 61 -10 67 -15 7
-6 29 -13 50 -17 20 -4 40 -11 43 -16 3 -5 21 -9 40 -9 19 0 34 -4 34 -10 0
-5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 20 -10 36 -10 17 0 50 -11 75 -25 25
-14 55 -25 67 -25 12 0 40 -11 62 -25 25 -15 55 -24 82 -25 23 0 45 -4 48 -10
3 -5 15 -10 26 -10 10 0 19 -4 19 -10 0 -5 17 -10 39 -10 22 0 44 -6 51 -15 7
-8 21 -15 31 -15 10 0 21 -4 24 -10 3 -5 19 -10 35 -10 16 0 32 -4 35 -10 3
-5 15 -10 25 -10 17 0 66 -18 112 -41 10 -5 26 -9 36 -9 10 0 37 -9 60 -20 22
-11 57 -20 77 -20 19 0 43 -4 53 -9 53 -27 72 -33 86 -28 9 4 32 -2 51 -13 20
-11 51 -20 69 -20 18 0 39 -7 46 -15 7 -8 25 -15 41 -15 15 0 31 -4 34 -10 3
-5 17 -10 30 -10 13 0 27 -4 30 -10 3 -5 19 -10 35 -10 16 0 31 -4 35 -9 10
-18 68 -41 101 -41 18 0 36 -4 39 -10 3 -5 13 -10 21 -10 9 0 22 -7 30 -15 9
-8 29 -15 45 -15 16 0 38 -4 49 -10 11 -5 29 -14 40 -20 11 -5 40 -10 64 -10
25 0 47 -4 51 -9 8 -14 65 -41 85 -41 9 0 20 -4 25 -8 6 -5 30 -14 55 -21 25
-7 62 -20 82 -28 20 -9 44 -13 52 -10 8 3 20 -1 26 -9 7 -8 21 -14 31 -14 10
0 21 -4 24 -10 3 -5 21 -10 39 -10 18 0 50 -9 73 -20 28 -13 51 -17 77 -13 24
4 36 2 36 -6 0 -6 9 -11 19 -11 11 0 23 -4 26 -10 3 -5 14 -10 24 -10 10 0 32
-7 47 -15 16 -8 37 -15 46 -15 9 0 20 -4 23 -10 3 -5 19 -10 35 -10 16 0 32
-4 35 -10 3 -5 15 -10 25 -10 10 0 40 -11 66 -25 26 -14 62 -25 80 -25 19 0
34 -4 34 -10 0 -5 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 28 -10 55 -10 27 0 51
-4 55 -9 9 -15 66 -41 89 -41 12 0 43 -11 70 -25 27 -14 56 -25 64 -25 20 0
83 -20 155 -49 15 -6 38 -11 51 -11 13 0 29 -7 36 -15 7 -8 25 -15 41 -15 15
0 31 -5 35 -11 3 -6 19 -9 34 -6 17 4 41 -1 64 -14 20 -10 49 -19 66 -19 16 0
45 -9 65 -20 20 -11 51 -20 70 -20 18 0 36 -4 40 -9 3 -5 19 -12 35 -16 65
-14 60 176 58 -2366 l-3 -2314 -42 -3 c-24 -2 -43 1 -43 7 0 12 -57 41 -82 41
-10 0 -27 5 -38 10 -11 6 -29 15 -40 20 -11 6 -28 10 -39 10 -10 0 -24 7 -31
15 -7 8 -25 15 -41 15 -15 0 -31 5 -34 10 -3 6 -19 10 -36 10 -16 0 -29 5 -29
10 0 6 -13 10 -29 10 -17 0 -32 4 -36 9 -8 14 -65 41 -87 41 -10 0 -40 11 -67
24 -27 14 -57 25 -67 24 -19 0 -75 18 -157 51 -15 6 -38 11 -51 11 -13 0 -29
7 -36 15 -7 8 -21 15 -31 15 -10 0 -21 4 -24 10 -3 5 -19 7 -34 4 -16 -3 -32
-1 -35 5 -4 5 -13 8 -20 5 -7 -3 -19 2 -26 11 -7 8 -19 15 -28 15 -23 0 -82
23 -82 32 0 4 -23 8 -51 8 -38 0 -58 6 -83 25 -18 14 -43 25 -55 25 -21 0 -30
3 -78 28 -13 7 -33 12 -47 12 -13 0 -29 7 -36 15 -7 8 -23 15 -36 15 -12 0
-26 5 -29 10 -3 6 -15 10 -26 10 -10 0 -19 5 -19 10 0 6 -20 10 -45 10 -25 0
-45 3 -45 8 0 12 -60 42 -86 42 -13 0 -39 9 -59 20 -20 11 -51 20 -70 20 -18
0 -36 4 -40 9 -10 18 -68 41 -101 41 -18 0 -36 4 -39 10 -3 6 -16 10 -29 10
-13 0 -43 11 -68 24 -30 16 -49 21 -56 14 -6 -6 -16 -6 -28 0 -11 5 -33 16
-49 25 -17 8 -41 13 -55 10 -18 -3 -32 3 -49 21 -18 19 -35 26 -62 26 -21 0
-40 4 -43 9 -3 4 -27 14 -53 21 -27 7 -55 19 -64 27 -8 7 -28 13 -42 13 -15 0
-27 5 -27 10 0 6 -13 10 -29 10 -17 0 -33 5 -36 10 -3 6 -19 10 -34 10 -16 0
-34 7 -41 15 -7 8 -23 15 -36 15 -12 0 -26 5 -29 10 -3 6 -17 10 -31 10 -13 0
-42 11 -64 25 -22 14 -49 25 -60 25 -12 0 -38 9 -60 20 -22 11 -50 20 -64 20
-14 0 -46 11 -72 25 -26 14 -60 25 -75 25 -15 0 -31 5 -34 10 -3 6 -16 10 -29
10 -12 0 -41 9 -64 20 -26 13 -51 18 -66 14 -13 -3 -27 -1 -30 5 -4 6 -16 11
-27 11 -10 0 -19 5 -19 10 0 6 -18 10 -39 10 -22 0 -43 4 -46 10 -3 6 -14 10
-24 10 -10 0 -24 7 -31 15 -7 8 -21 15 -31 15 -10 0 -34 9 -54 20 -20 11 -46
20 -58 20 -13 0 -32 7 -43 15 -10 8 -29 15 -41 15 -12 0 -25 4 -28 10 -3 6
-19 10 -36 10 -16 0 -29 5 -29 10 0 6 -13 10 -29 10 -17 0 -32 4 -36 9 -11 19
-69 41 -107 41 -21 0 -38 4 -38 8 0 12 -46 32 -71 32 -11 0 -42 11 -69 25 -27
14 -63 25 -80 25 -16 0 -30 5 -30 10 0 6 -8 10 -17 10 -10 0 -27 7 -37 15 -11
8 -35 15 -53 15 -18 0 -33 5 -33 10 0 6 -11 10 -25 10 -14 0 -25 4 -25 9 0 8
-38 17 -100 24 -26 3 -53 12 -82 28 -10 5 -31 9 -46 9 -30 0 -87 24 -97 41 -4
5 -23 9 -43 9 -21 0 -48 9 -62 20 -14 11 -35 20 -47 20 -12 0 -25 5 -28 10 -3
6 -17 10 -29 10 -13 0 -29 7 -36 15 -7 8 -25 15 -41 15 -15 0 -31 5 -34 10 -3
6 -19 10 -36 10 -16 0 -29 5 -29 10 0 6 -8 10 -19 10 -10 0 -24 7 -31 15 -7 9
-29 15 -51 15 -22 0 -39 5 -39 10 0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -3 6
-17 10 -30 10 -12 0 -43 11 -66 25 -24 14 -56 25 -71 25 -15 0 -43 9 -63 20
-20 11 -46 20 -58 20 -12 0 -33 7 -45 16 -12 8 -27 12 -33 9 -5 -4 -18 0 -27
8 -9 8 -35 18 -57 22 -47 8 -103 24 -127 36 -10 5 -33 9 -51 9 -21 0 -41 8
-52 20 -13 15 -31 20 -66 20 -26 0 -51 4 -54 10 -3 5 -23 17 -43 26 -20 9 -58
27 -84 40 -27 13 -61 24 -77 24 -17 0 -33 5 -36 10 -3 6 -16 10 -29 10 -25 0
-82 25 -91 41 -4 5 -22 9 -40 9 -19 0 -50 9 -70 20 -20 11 -45 20 -56 20 -11
0 -41 11 -67 25 -26 14 -57 25 -70 25 -13 0 -42 9 -64 20 -23 11 -52 20 -64
20 -13 0 -26 5 -29 10 -3 6 -15 10 -26 10 -10 0 -27 4 -37 9 -32 16 -85 30
-93 26 -4 -3 -28 6 -53 20 -25 14 -58 25 -75 25 -16 0 -33 5 -36 10 -3 6 -19
10 -35 10 -16 0 -32 5 -35 10 -3 6 -16 10 -28 10 -12 0 -31 7 -41 15 -11 8
-30 15 -43 15 -13 0 -23 5 -23 10 0 6 -11 10 -25 10 -14 0 -33 4 -43 9 -9 4
-41 20 -69 35 -29 14 -66 26 -82 26 -17 0 -33 5 -36 10 -3 6 -15 10 -26 10
-10 0 -19 5 -19 10 0 6 -17 10 -39 10 -22 0 -44 6 -51 15 -7 8 -21 15 -31 15
-11 0 -19 5 -19 10 0 6 -14 10 -32 10 -17 0 -51 12 -76 26 -24 14 -52 24 -62
21 -11 -3 -27 1 -37 9 -10 7 -30 14 -45 14 -16 0 -28 5 -28 10 0 6 -9 10 -20
10 -10 0 -32 5 -47 11 -15 6 -37 15 -48 19 -11 4 -33 13 -48 19 -16 6 -32 9
-37 6 -5 -3 -19 0 -32 6 -50 26 -59 29 -87 29 -16 0 -36 7 -45 15 -8 8 -21 15
-30 15 -8 0 -18 5 -21 10 -3 6 -28 10 -55 10 -27 0 -52 4 -55 10 -7 11 -54 33
-110 51 -22 7 -44 16 -50 20 -5 4 -25 10 -42 14 -18 3 -33 11 -33 16 0 5 -16
9 -35 9 -19 0 -35 5 -35 10 0 6 -9 10 -19 10 -11 0 -23 5 -26 10 -3 6 -24 10
-45 10 -21 0 -41 4 -45 9 -8 14 -65 41 -87 41 -10 0 -36 9 -58 20 -22 11 -53
20 -69 20 -16 0 -33 5 -36 10 -3 6 -14 10 -24 10 -10 0 -24 7 -31 15 -7 8 -24
14 -39 14 -14 -1 -48 8 -74 20 -27 11 -67 24 -90 28 -23 3 -57 15 -75 25 -18
10 -48 18 -67 18 -18 0 -36 4 -40 9 -10 17 -67 41 -97 41 -15 0 -36 4 -46 9
-55 27 -92 42 -135 54 l-48 13 28 28 c15 16 44 39 63 51 19 13 57 46 85 74 27
28 54 51 60 51 6 0 26 16 45 35 19 19 39 35 45 35 6 0 31 20 55 45 24 25 52
45 61 45 10 0 19 4 21 8 2 5 37 36 78 69 41 34 91 77 109 97 19 20 40 36 46
36 6 0 31 20 55 45 24 25 50 45 58 45 7 0 28 16 46 35 19 19 38 35 43 35 6 0
37 25 69 55 33 30 64 54 70 55 5 0 25 16 44 35 19 19 39 35 45 35 7 0 38 27
71 60 32 33 66 60 74 60 9 0 35 20 58 45 23 25 45 45 47 45 10 0 172 129 211
168 7 6 17 12 22 12 4 0 19 11 32 25 13 14 30 25 37 25 7 0 19 11 25 25 6 14
17 25 25 25 7 0 50 36 95 80 46 44 87 80 93 80 5 0 30 20 55 45 25 24 51 44
57 45 13 0 24 8 86 62 18 15 37 28 43 28 6 0 30 20 53 45 23 25 45 45 49 45 4
0 30 23 57 50 27 28 55 50 61 50 7 0 37 25 68 55 30 30 62 55 69 55 7 0 28 16
47 35 19 19 40 35 48 35 8 0 28 16 45 35 16 19 34 35 39 35 10 0 64 47 106 93
14 15 32 27 39 27 8 0 35 20 61 44 26 24 52 45 59 47 6 2 36 28 66 57 31 28
58 52 62 52 4 0 25 16 48 35 22 19 46 35 52 35 6 0 32 23 59 50 27 28 52 50
57 50 4 0 11 9 14 20 3 11 14 20 24 20 10 0 25 7 32 17 8 9 26 24 42 33 15 9
49 38 76 63 27 26 53 47 58 47 5 0 24 16 43 35 19 19 39 35 44 35 6 0 27 15
46 33 19 19 53 45 75 59 22 14 56 42 74 62 19 20 40 36 46 36 6 0 26 16 45 35
34 35 54 43 65 26z m6602 -105 c4 -8 7 -303 5 -655 l-2 -641 -40 0 c-22 0 -54
9 -72 20 -18 11 -42 20 -53 20 -11 0 -33 7 -49 15 -15 8 -37 15 -47 15 -10 0
-21 5 -24 10 -3 6 -19 10 -36 10 -29 0 -35 2 -97 34 -17 10 -37 14 -42 11 -6
-4 -22 1 -36 10 -14 9 -31 14 -37 12 -7 -2 -35 6 -63 19 -33 15 -55 19 -66 13
-11 -6 -20 -4 -28 6 -7 8 -21 15 -31 15 -10 0 -21 5 -24 10 -3 6 -25 10 -49
10 -41 0 -99 21 -111 41 -4 5 -19 9 -36 9 -16 0 -29 3 -29 8 0 8 -71 32 -98
32 -22 0 -79 27 -87 41 -4 5 -24 9 -45 9 -21 0 -42 5 -45 10 -3 6 -17 10 -30
10 -13 0 -44 11 -69 25 -25 14 -57 25 -71 25 -14 0 -45 9 -67 20 -23 11 -52
20 -64 20 -13 0 -44 11 -70 25 -26 14 -62 25 -80 25 -18 0 -36 5 -39 10 -3 6
-17 10 -30 10 -13 0 -27 5 -30 10 -3 6 -15 10 -26 10 -11 0 -30 5 -42 12 -12
7 -26 12 -32 13 -5 0 -34 9 -63 19 -29 10 -65 16 -79 13 -26 -5 -47 3 -90 34
-29 21 -20 29 24 22 28 -5 39 -2 50 12 10 14 16 16 24 8 9 -9 18 -8 35 4 13 8
33 13 44 10 10 -3 24 1 31 9 6 8 20 14 30 14 11 0 19 5 19 10 0 6 20 10 44 10
44 0 110 21 121 40 3 5 19 10 35 10 16 0 32 5 35 10 3 6 22 10 41 10 19 0 51
11 72 23 20 13 54 27 75 30 21 3 52 13 69 21 17 9 44 16 59 16 15 0 49 11 75
25 26 14 64 25 85 25 21 0 54 9 74 20 20 11 46 20 59 20 13 0 29 7 36 15 7 8
23 15 36 15 12 0 26 5 29 10 3 6 19 10 35 10 16 0 32 5 35 10 3 6 19 10 36 10
16 0 29 5 29 10 0 6 16 10 35 10 21 0 37 6 43 16 7 13 12 14 29 3 17 -10 24
-9 42 3 12 9 31 14 42 11 10 -3 19 0 19 6 0 6 15 11 34 11 18 0 54 11 80 25
26 14 60 25 76 25 16 0 32 5 35 10 3 6 19 10 35 10 16 0 32 5 35 10 3 6 16 10
28 10 12 0 31 7 41 15 11 8 31 15 45 16 14 0 40 9 56 19 36 23 101 26 112 6z
m15214 -106 c19 -11 19 -49 19 -2476 0 -2334 -1 -2466 -17 -2483 -27 -28 -58
-41 -97 -41 -19 0 -38 -4 -41 -10 -3 -5 -14 -10 -24 -10 -10 0 -24 -7 -31 -15
-7 -8 -28 -15 -46 -15 -18 0 -49 -9 -69 -20 -20 -11 -51 -20 -70 -20 -19 0
-58 -11 -87 -25 -28 -14 -60 -25 -69 -25 -10 0 -21 -4 -24 -10 -3 -5 -19 -10
-35 -10 -16 0 -32 -4 -35 -10 -3 -6 -14 -10 -23 -10 -9 0 -30 -7 -46 -15 -15
-8 -44 -15 -62 -15 -19 0 -34 -4 -34 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4
-26 -10 -3 -5 -20 -10 -37 -10 -16 0 -44 -7 -61 -16 -60 -32 -67 -34 -92 -34
-13 0 -40 -9 -60 -20 -23 -13 -45 -18 -63 -14 -19 4 -34 0 -45 -11 -9 -9 -21
-13 -27 -10 -10 6 -77 -13 -94 -27 -6 -4 -27 -8 -47 -8 -21 0 -41 -4 -44 -10
-9 -14 -55 -12 -55 3 0 6 0 1114 0 2460 l0 2449 21 17 c12 10 43 23 68 30 25
7 54 17 63 22 10 5 28 9 42 9 13 0 29 7 36 15 7 8 23 15 36 15 12 0 26 5 29
10 3 6 14 10 24 10 10 0 40 11 67 25 27 14 61 25 77 25 15 0 27 5 27 10 0 6 9
10 19 10 11 0 23 5 26 10 3 6 21 10 39 10 33 0 91 23 101 41 4 5 24 9 45 9 21
0 42 5 45 10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 14 10 24 10 10 0 24 7 31
15 7 8 25 15 41 15 16 0 29 5 29 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 19
10 35 10 16 0 31 4 35 9 3 6 27 13 53 16 26 3 55 10 64 16 10 5 25 9 33 9 8 0
15 5 15 10 0 6 17 10 39 10 22 0 44 6 51 15 7 8 21 15 31 15 11 0 19 5 19 10
0 13 46 13 71 0z m-14505 -145 c16 -8 49 -35 74 -60 24 -25 47 -45 51 -45 3 0
34 -27 68 -60 34 -33 65 -60 70 -60 4 0 27 -18 51 -40 24 -22 51 -40 60 -40 9
0 34 -19 55 -41 22 -23 53 -50 70 -60 16 -10 65 -54 110 -97 44 -43 101 -93
127 -111 26 -17 51 -37 55 -44 4 -7 22 -22 39 -32 43 -28 95 -70 168 -137 33
-32 66 -58 72 -58 5 0 25 -16 44 -35 19 -19 40 -35 48 -35 8 0 46 -30 84 -67
38 -38 75 -70 81 -72 7 -2 35 -25 64 -50 101 -91 151 -131 163 -131 7 0 31
-20 54 -44 23 -24 60 -55 81 -68 22 -14 60 -46 85 -72 25 -25 50 -46 55 -46 6
0 31 -20 55 -45 24 -25 51 -45 60 -45 8 0 29 -16 45 -35 17 -19 36 -35 43 -35
8 0 42 -27 76 -60 35 -33 67 -60 72 -60 5 0 32 -22 59 -50 28 -27 55 -50 61
-50 6 0 27 -17 47 -37 19 -21 61 -56 92 -78 31 -22 61 -48 66 -57 5 -10 15
-15 22 -12 7 2 39 -23 72 -56 33 -33 63 -60 68 -60 5 0 53 -40 106 -90 54 -49
102 -90 107 -90 5 0 34 -23 64 -51 30 -28 69 -57 85 -64 17 -8 55 -39 85 -69
30 -31 59 -56 65 -56 7 0 18 -11 25 -25 7 -13 22 -28 34 -31 11 -4 40 -25 64
-48 24 -22 48 -42 53 -44 4 -2 23 -17 41 -34 18 -17 78 -67 133 -110 55 -43
126 -102 157 -131 l58 -52 0 -2537 c0 -1686 -3 -2538 -10 -2538 -5 0 -10 -24
-10 -53 0 -49 -3 -56 -40 -90 -23 -20 -44 -37 -48 -37 -5 0 -35 -27 -68 -60
-32 -32 -65 -59 -73 -60 -8 0 -38 -21 -67 -47 -29 -27 -81 -70 -114 -98 -34
-27 -83 -69 -110 -93 -26 -23 -54 -42 -61 -42 -8 0 -39 -25 -69 -55 -30 -30
-59 -55 -65 -55 -5 0 -30 -20 -55 -45 -25 -24 -50 -44 -55 -45 -6 0 -31 -21
-55 -46 -25 -25 -64 -57 -87 -71 -24 -14 -43 -29 -43 -34 0 -4 -24 -24 -52
-42 -29 -19 -69 -51 -87 -71 -19 -20 -40 -36 -46 -36 -6 0 -33 -22 -60 -50
-27 -27 -54 -50 -60 -50 -5 0 -57 -46 -115 -100 -58 -55 -111 -98 -117 -96 -7
3 -16 -1 -20 -8 -9 -15 -102 -90 -119 -97 -6 -2 -42 -33 -80 -68 -38 -35 -85
-74 -104 -86 -19 -13 -60 -48 -90 -79 -30 -31 -59 -56 -65 -56 -6 0 -27 -16
-46 -36 -18 -20 -47 -43 -64 -51 -29 -15 -72 -50 -177 -145 -29 -27 -58 -48
-63 -48 -6 0 -37 -27 -70 -60 -33 -33 -64 -60 -70 -60 -5 0 -30 -20 -55 -45
-25 -24 -52 -44 -61 -45 -9 0 -35 -20 -58 -45 -23 -25 -47 -45 -54 -45 -7 0
-39 -27 -72 -60 -33 -33 -64 -60 -70 -60 -6 0 -31 -20 -55 -45 -24 -25 -50
-45 -56 -45 -7 0 -33 -20 -58 -45 -26 -25 -49 -45 -54 -45 -4 0 -32 -23 -62
-50 -30 -27 -59 -50 -65 -50 -5 0 -39 -29 -75 -65 -36 -35 -70 -64 -75 -65 -6
0 -28 -16 -48 -35 -20 -19 -42 -35 -47 -35 -6 0 -35 -24 -64 -52 -61 -60 -200
-178 -210 -178 -3 0 -6 2554 -6 5675 0 3121 2 5675 3 5675 2 0 17 -7 33 -15z
m12132 -647 c9 -9 12 -565 12 -2441 l0 -2430 -27 -23 c-16 -13 -33 -24 -39
-24 -6 0 -17 -7 -24 -15 -9 -11 -22 -14 -40 -10 -18 4 -33 1 -43 -9 -9 -9 -25
-16 -35 -16 -11 0 -22 -5 -24 -12 -3 -8 -9 -8 -23 -1 -14 8 -24 7 -39 -2 -12
-8 -39 -15 -61 -17 l-40 -3 -7 2435 c-4 1642 -3 2439 4 2447 17 21 70 43 103
43 18 0 38 6 44 14 7 8 20 12 30 9 9 -2 23 3 30 11 8 9 24 16 37 16 12 0 26 5
29 10 3 6 19 10 36 10 16 0 29 5 29 10 0 14 33 13 48 -2z m-908 -2726 l0
-2438 -28 -27 c-15 -15 -37 -27 -49 -27 -11 0 -34 -7 -49 -15 -16 -8 -37 -15
-47 -15 -9 0 -26 -4 -37 -10 -11 -5 -29 -14 -40 -20 -11 -5 -27 -10 -35 -10
-8 0 -24 -6 -35 -12 -17 -11 -22 -10 -29 0 -4 7 -11 286 -14 620 -8 721 -10
4244 -2 4263 6 15 50 39 72 39 7 0 13 5 13 10 0 6 17 10 39 10 22 0 44 6 51
15 7 8 21 15 31 15 10 0 21 5 24 10 3 6 24 10 45 10 21 0 42 5 45 10 3 6 15
10 26 10 19 0 19 -31 19 -2438z m-880 -302 c0 -2214 -1 -2438 -16 -2444 -9 -3
-29 -6 -45 -6 -15 0 -46 -9 -67 -20 -21 -11 -51 -20 -67 -21 -33 -2 -71 -16
-97 -37 -9 -7 -30 -12 -47 -10 l-31 3 -5 1280 c-3 704 -7 1793 -10 2419 l-5
1140 22 23 c12 13 32 23 45 23 13 0 23 5 23 10 0 6 13 10 29 10 16 0 34 7 41
15 7 8 25 15 41 15 16 0 29 5 29 10 0 6 9 10 19 10 11 0 23 5 26 10 4 6 22 9
40 8 19 -1 37 1 41 4 3 4 12 4 20 1 12 -4 14 -361 14 -2443z m-1800 -568 c0
-1860 -3 -2411 -12 -2420 -7 -7 -24 -12 -39 -12 -15 0 -32 -6 -38 -14 -6 -7
-30 -16 -52 -19 -22 -3 -48 -12 -58 -21 -10 -9 -34 -16 -54 -16 -20 0 -39 -4
-42 -10 -3 -5 -17 -10 -30 -10 -13 0 -30 -7 -39 -15 -8 -8 -21 -15 -30 -15 -8
0 -18 -4 -21 -10 -3 -5 -22 -10 -41 -10 -19 0 -42 -4 -52 -8 -9 -5 -32 -16
-50 -25 -19 -9 -46 -17 -60 -17 -15 -1 -38 -7 -52 -15 -14 -8 -44 -14 -68 -15
-29 0 -47 -6 -58 -19 -10 -11 -24 -16 -35 -14 -11 3 -19 0 -19 -7 0 -8 -8 -10
-22 -6 -22 5 -73 -6 -110 -25 -10 -5 -26 -9 -37 -9 -10 0 -32 -7 -47 -15 -16
-8 -39 -15 -51 -15 -12 0 -38 -9 -58 -20 -20 -11 -53 -20 -74 -20 -21 0 -59
-11 -85 -25 -26 -14 -56 -25 -67 -25 -11 0 -37 -9 -59 -20 -22 -11 -52 -20
-67 -20 -15 0 -50 -11 -77 -25 -27 -14 -60 -25 -72 -25 -13 0 -26 -4 -29 -10
-3 -5 -23 -10 -44 -10 -22 0 -44 -6 -51 -15 -7 -8 -23 -15 -36 -15 -12 0 -25
-4 -28 -9 -3 -5 -21 -12 -39 -15 -18 -3 -50 -15 -71 -27 -22 -11 -50 -19 -63
-16 -12 2 -28 -2 -34 -9 -6 -8 -24 -14 -40 -14 -15 0 -31 -5 -35 -11 -3 -6
-17 -8 -31 -4 -15 3 -34 0 -47 -8 -23 -15 -96 -35 -158 -44 -21 -3 -38 -9 -38
-14 0 -5 -13 -9 -29 -9 -17 0 -33 -4 -36 -10 -3 -6 -16 -10 -29 -10 -12 0 -31
-7 -42 -15 -10 -8 -29 -15 -41 -15 -12 0 -25 -4 -28 -10 -3 -5 -19 -10 -35
-10 -28 0 -44 -5 -117 -36 -17 -8 -43 -14 -57 -14 -14 0 -26 -4 -26 -10 0 -5
-9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -23 -10 -44 -10 -22 0 -44 -6 -51
-15 -7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5 -19 -10 -34 -10 -15 0
-31 -3 -35 -7 -10 -11 -79 -33 -102 -33 -12 0 -27 -7 -34 -15 -7 -8 -25 -15
-41 -15 -15 0 -31 -4 -34 -10 -3 -5 -19 -10 -36 -10 -16 0 -29 -4 -29 -10 0
-5 -17 -10 -39 -10 -22 0 -44 -6 -51 -15 -7 -8 -20 -15 -30 -15 -9 0 -20 -3
-24 -7 -4 -4 -23 -6 -42 -4 -19 1 -42 -3 -50 -10 -8 -6 -17 -9 -21 -6 -3 4
-15 0 -27 -8 -11 -8 -33 -15 -48 -15 -15 0 -30 -4 -33 -10 -3 -5 -22 -10 -41
-10 -19 0 -34 -4 -34 -10 0 -5 -8 -10 -17 -10 -19 0 -73 -16 -118 -35 -73 -30
-87 -35 -109 -35 -32 0 -63 35 -47 53 13 17 11 4611 -2 4638 -6 12 0 20 25 33
18 9 47 25 63 36 17 10 47 19 67 19 20 1 40 6 43 11 3 6 15 10 25 10 10 0 20
4 22 10 2 5 17 11 33 13 26 4 97 31 147 58 10 5 33 9 52 9 19 0 37 4 41 9 9
16 66 41 91 41 13 0 26 5 29 10 3 6 19 10 35 10 16 0 32 5 35 11 4 6 19 7 35
4 16 -3 31 -2 35 4 3 6 15 11 26 11 10 0 19 5 19 10 0 6 18 10 39 10 22 0 43
5 46 10 3 6 14 10 24 10 10 0 24 7 31 15 7 8 23 15 36 15 12 0 26 5 29 10 3 6
19 10 35 10 16 0 32 5 35 10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 17 10 29 10
13 0 29 7 36 15 7 8 23 15 36 15 13 0 24 5 24 10 0 6 17 10 38 10 38 0 96 22
107 41 4 5 22 9 40 9 19 0 50 9 70 20 20 11 44 20 53 20 10 0 42 11 70 25 29
14 58 25 66 25 8 0 18 5 21 10 3 6 16 10 28 10 12 0 31 7 41 15 11 8 30 15 42
15 13 0 25 4 28 8 3 5 20 9 38 10 29 1 51 8 101 34 13 6 23 5 33 -3 11 -9 18
-9 32 2 35 28 63 39 102 39 22 0 40 5 40 10 0 6 13 10 29 10 16 0 34 6 40 14
7 8 20 12 30 9 9 -2 23 3 30 11 8 9 26 16 42 16 15 0 31 5 34 10 3 6 15 10 26
10 10 0 19 5 19 10 0 6 16 10 35 10 19 0 35 4 35 9 0 15 60 41 96 41 18 0 36
5 39 10 3 6 14 10 24 10 10 0 24 7 31 15 7 9 29 15 52 15 21 0 58 9 80 20 23
11 50 20 59 20 10 0 21 5 24 10 3 6 14 10 24 10 10 0 24 7 31 15 7 8 25 15 41
15 15 0 31 5 34 10 3 6 14 10 24 10 9 0 37 10 62 22 24 12 54 21 67 19 13 -1
35 5 49 14 14 9 47 18 74 19 32 2 60 10 79 24 17 13 44 22 64 22 19 0 38 5 41
10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 19 10 34 10 16 0 34 7 41 15 7 8 21
15 32 15 10 0 36 9 58 20 22 11 50 20 63 20 14 0 30 7 37 15 7 8 16 13 20 10
4 -3 13 2 20 10 7 8 29 15 49 16 20 0 50 9 66 19 17 10 49 19 73 19 l42 1 0
-2408z m-20510 923 c7 -8 21 -15 31 -15 10 0 21 -5 25 -11 3 -6 15 -8 27 -5
11 3 41 -6 68 -19 37 -19 54 -23 73 -16 18 8 26 6 30 -5 3 -8 14 -14 25 -14
11 0 23 -4 26 -10 3 -5 23 -10 43 -10 21 0 48 -9 62 -20 14 -11 31 -20 38 -20
13 0 67 -21 104 -41 10 -5 29 -9 42 -9 14 0 28 -4 31 -10 3 -5 17 -10 29 -10
13 0 29 -7 36 -15 7 -8 21 -15 31 -15 10 0 21 -4 24 -10 3 -5 24 -10 46 -10
21 0 39 -4 39 -10 0 -5 6 -10 14 -10 7 0 19 -7 26 -15 7 -8 25 -15 41 -15 16
0 44 -9 64 -20 20 -11 49 -20 64 -20 16 0 44 -8 63 -17 62 -31 68 -33 92 -33
14 0 28 -4 31 -10 3 -5 19 -10 36 -10 16 0 29 -4 29 -10 0 -5 8 -10 18 -10 10
0 23 -5 30 -12 7 -7 29 -13 51 -14 21 -1 50 -10 65 -19 17 -11 36 -15 55 -12
19 4 33 1 41 -8 7 -8 16 -13 20 -10 4 3 13 -2 20 -10 7 -8 25 -15 41 -15 15 0
31 -4 34 -10 3 -5 15 -10 26 -10 10 0 27 -4 37 -9 9 -4 35 -16 57 -26 22 -9
48 -21 57 -26 10 -5 29 -9 42 -9 14 0 28 -4 31 -10 3 -5 17 -10 31 -10 14 0
32 -7 40 -15 9 -8 26 -15 39 -15 13 0 27 -4 30 -10 3 -5 21 -10 38 -10 30 0
53 -9 168 -67 26 -13 58 -23 72 -23 15 0 46 -11 71 -25 25 -14 58 -25 75 -25
16 0 33 -4 36 -10 3 -5 19 -10 35 -10 16 0 32 -4 35 -10 3 -5 15 -10 26 -10
10 0 19 -4 19 -9 0 -4 22 -11 50 -14 27 -3 61 -13 74 -23 16 -10 35 -14 50
-10 14 3 28 2 31 -4 3 -5 14 -10 24 -10 10 0 24 -7 31 -15 7 -8 23 -15 36 -15
12 0 26 -4 29 -10 3 -5 19 -10 36 -10 16 0 29 -3 29 -8 0 -8 70 -32 94 -32 7
0 19 -7 26 -15 7 -8 23 -15 36 -15 13 0 24 -4 24 -10 0 -5 13 -10 28 -10 30 0
87 -24 97 -41 4 -5 22 -9 40 -9 19 0 50 -9 70 -20 20 -11 45 -20 56 -20 11 0
40 -11 65 -25 25 -14 56 -25 69 -25 14 0 33 -4 43 -9 41 -21 69 -32 91 -36 14
-2 29 -9 35 -15 5 -5 20 -10 32 -10 13 0 26 -4 29 -10 3 -5 19 -10 35 -10 16
0 32 -5 35 -11 4 -5 17 -9 28 -8 23 2 49 -5 82 -23 11 -6 37 -10 57 -10 21 1
38 -3 38 -8 0 -6 9 -10 19 -10 11 0 23 -4 26 -10 3 -5 16 -10 29 -10 12 0 31
-7 42 -15 10 -8 27 -15 37 -15 9 0 26 -4 37 -10 11 -5 29 -14 40 -20 11 -5 28
-10 39 -10 10 0 24 -7 31 -15 7 -8 23 -15 36 -15 12 0 26 -4 29 -10 3 -5 19
-10 35 -10 16 0 32 -4 35 -10 3 -5 14 -10 24 -10 10 0 24 -7 31 -15 7 -9 29
-15 51 -15 22 0 39 -4 39 -10 0 -5 13 -10 28 -10 15 0 32 -4 37 -8 6 -5 30
-15 55 -22 25 -8 54 -20 65 -27 11 -7 27 -13 37 -13 10 0 33 -9 53 -20 20 -11
45 -20 56 -20 11 0 31 -7 44 -16 16 -10 34 -14 50 -10 18 5 27 2 31 -9 4 -8
14 -15 24 -15 11 0 22 -5 26 -11 3 -6 19 -9 35 -5 15 3 31 1 34 -4 4 -6 10 -8
14 -5 5 2 17 -2 27 -10 10 -8 33 -15 51 -15 18 0 48 -9 68 -20 20 -11 51 -20
70 -20 18 0 37 -4 40 -10 5 -9 28 -20 98 -49 15 -6 36 -11 47 -11 10 0 20 -3
22 -7 7 -17 71 -43 104 -43 19 0 34 -4 34 -10 0 -5 9 -10 19 -10 11 0 23 -4
26 -10 3 -5 24 -10 45 -10 21 0 41 -4 45 -9 9 -15 66 -41 89 -41 12 0 41 -11
65 -25 23 -14 54 -25 67 -25 13 0 32 -4 42 -9 43 -22 131 -51 154 -51 9 0 21
-7 28 -15 7 -8 25 -15 41 -15 15 0 31 -5 34 -10 4 -6 11 -8 16 -5 5 3 30 -3
55 -14 26 -12 59 -21 75 -21 15 0 31 -4 34 -10 3 -5 19 -10 36 -10 16 0 29 -4
29 -10 0 -5 13 -10 29 -10 17 0 33 -4 36 -10 3 -5 19 -10 35 -10 16 0 32 -5
35 -11 10 -16 -26 -38 -61 -39 -31 0 -89 -24 -99 -41 -4 -5 -22 -9 -40 -9 -19
0 -50 -9 -70 -20 -20 -11 -47 -20 -61 -20 -13 0 -42 -11 -64 -25 -23 -14 -54
-25 -75 -25 -19 0 -35 -4 -35 -10 0 -5 -7 -10 -15 -10 -15 0 -23 -3 -83 -33
-19 -9 -42 -17 -53 -17 -11 0 -28 -5 -39 -10 -11 -5 -29 -14 -40 -20 -11 -6
-29 -10 -41 -10 -12 0 -37 -11 -55 -25 -18 -14 -46 -25 -63 -25 -17 0 -46 -9
-66 -20 -20 -11 -50 -20 -67 -20 -17 0 -40 -7 -49 -16 -11 -10 -37 -17 -63
-18 -24 0 -48 -4 -52 -8 -4 -5 -16 -8 -26 -8 -10 0 -18 -4 -18 -10 0 -5 -16
-12 -35 -16 -19 -3 -38 -10 -41 -15 -3 -5 -18 -9 -34 -9 -16 0 -32 -4 -35 -10
-3 -5 -17 -10 -30 -10 -13 0 -27 -4 -30 -10 -3 -5 -19 -10 -35 -10 -15 0 -51
-11 -78 -25 -28 -14 -59 -25 -71 -25 -11 0 -40 -11 -65 -25 -25 -14 -56 -25
-70 -25 -14 0 -41 -9 -61 -20 -20 -11 -46 -20 -59 -20 -13 0 -29 -7 -36 -15
-7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5 -15 -10 -26 -10 -10 0 -19
-4 -19 -10 0 -5 -15 -10 -32 -10 -18 0 -51 -11 -73 -25 -22 -13 -55 -25 -72
-25 -18 0 -33 -4 -33 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -23
-10 -44 -10 -23 0 -44 -6 -51 -15 -7 -9 -19 -14 -26 -11 -7 3 -16 1 -20 -5 -3
-5 -14 -7 -24 -4 -10 3 -28 1 -39 -5 -12 -5 -30 -14 -41 -20 -11 -5 -28 -10
-38 -10 -10 0 -27 -7 -38 -15 -10 -8 -29 -15 -41 -15 -12 0 -25 -4 -28 -10 -3
-5 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -16 -10 -29 -10 -12 0 -31 -7
-42 -15 -10 -8 -29 -15 -41 -15 -12 0 -25 -4 -28 -10 -3 -5 -22 -10 -41 -10
-19 0 -34 -4 -34 -10 0 -5 -9 -10 -20 -10 -11 0 -20 -4 -20 -10 0 -5 -8 -10
-17 -10 -10 0 -31 -7 -47 -15 -15 -8 -39 -15 -52 -15 -13 0 -24 -4 -24 -10 0
-5 -13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3 -5 -16 -10 -29 -10 -12 0 -31 -7
-42 -15 -10 -8 -29 -15 -41 -15 -12 0 -25 -4 -28 -10 -3 -5 -17 -10 -29 -10
-13 0 -35 -9 -49 -21 -15 -11 -44 -23 -64 -26 -21 -4 -53 -15 -72 -25 -22 -12
-43 -17 -61 -13 -18 4 -33 1 -43 -9 -9 -9 -22 -16 -31 -16 -8 0 -18 -4 -21
-10 -3 -5 -19 -7 -34 -4 -16 4 -32 1 -35 -5 -4 -6 -17 -11 -30 -11 -25 0 -82
-25 -91 -41 -4 -5 -22 -9 -40 -9 -19 0 -50 -9 -70 -20 -20 -11 -45 -20 -56
-20 -10 0 -37 -11 -59 -25 -23 -14 -55 -25 -74 -25 -20 0 -51 -9 -71 -20 -20
-11 -45 -20 -56 -20 -12 0 -35 -7 -52 -16 -60 -32 -67 -34 -92 -34 -14 0 -25
-4 -25 -10 0 -5 -13 -10 -29 -10 -16 0 -34 -7 -41 -15 -7 -8 -23 -15 -36 -15
-12 0 -26 -4 -29 -10 -3 -5 -15 -10 -26 -10 -11 0 -41 -11 -67 -25 -26 -14
-57 -25 -69 -25 -12 0 -25 -4 -28 -10 -3 -5 -15 -10 -26 -10 -10 0 -19 -5 -19
-11 0 -7 -11 -8 -30 -4 -18 4 -40 1 -57 -8 -40 -22 -66 -31 -82 -28 -13 2 -35
-6 -83 -30 -10 -5 -29 -9 -43 -9 -14 0 -25 -4 -25 -10 0 -5 -13 -10 -29 -10
-16 0 -34 -7 -41 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5 -19
-10 -34 -10 -32 0 -96 -24 -106 -40 -3 -5 -15 -10 -25 -10 -11 0 -34 -9 -52
-20 -18 -11 -47 -20 -64 -20 -17 0 -37 -7 -44 -15 -7 -8 -23 -15 -36 -15 -12
0 -26 -4 -29 -10 -3 -5 -19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -5 -19 -10
-36 -10 -16 0 -29 -4 -29 -9 0 -5 -10 -11 -22 -14 -35 -8 -128 -42 -160 -58
-10 -5 -27 -9 -37 -9 -11 0 -23 -4 -26 -10 -3 -5 -14 -10 -24 -10 -10 0 -24
-6 -30 -14 -6 -8 -22 -11 -37 -8 -15 3 -44 -4 -70 -18 -25 -12 -53 -20 -63
-17 -10 2 -40 -6 -67 -19 -27 -13 -57 -24 -66 -24 -9 0 -20 -4 -23 -10 -3 -5
-24 -10 -45 -10 -21 0 -41 -4 -45 -9 -10 -17 -67 -41 -95 -41 -15 0 -48 -11
-74 -24 -27 -14 -54 -22 -61 -20 -7 3 -31 -6 -54 -20 -22 -14 -47 -26 -54 -26
-18 0 -75 -28 -82 -41 -4 -5 -24 -9 -45 -9 -21 0 -42 -4 -45 -10 -3 -5 -16
-10 -29 -10 -25 0 -82 -25 -91 -41 -4 -5 -23 -9 -43 -9 -21 0 -48 -9 -62 -20
-14 -11 -39 -20 -57 -20 -17 0 -35 -4 -38 -10 -3 -5 -13 -10 -22 -10 -9 0 -21
-8 -28 -17 -10 -14 -16 -15 -28 -5 -16 13 -59 3 -72 -18 -3 -5 -11 -8 -16 -4
-5 3 -16 0 -24 -6 -8 -6 -19 -9 -25 -5 -5 3 -19 -1 -31 -9 -12 -8 -27 -12 -34
-10 -6 3 -18 -2 -25 -11 -7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5
-19 -10 -35 -10 -16 0 -32 -4 -35 -10 -3 -6 -17 -10 -29 -10 -13 0 -29 -7 -36
-15 -7 -8 -23 -15 -36 -15 -14 0 -32 -4 -42 -9 -29 -14 -87 -31 -110 -31 -11
0 -24 -5 -27 -10 -9 -14 -73 -40 -98 -40 -11 0 -35 -11 -53 -25 -18 -14 -46
-25 -63 -25 -16 0 -33 -5 -36 -10 -3 -5 -17 -10 -31 -10 -13 0 -33 -4 -44 -10
-11 -6 -29 -14 -40 -20 -11 -5 -29 -10 -40 -10 -12 0 -41 -11 -66 -25 -25 -14
-58 -25 -74 -25 -17 0 -30 -4 -30 -10 0 -5 -11 -10 -25 -10 -14 0 -25 -5 -25
-11 0 -7 -11 -8 -29 -4 -20 4 -41 0 -67 -14 -21 -12 -51 -21 -66 -21 -15 0
-30 -4 -33 -10 -3 -5 -17 -10 -31 -10 -13 0 -24 -4 -24 -10 0 -5 -16 -12 -35
-16 -19 -3 -35 -10 -35 -15 0 -5 -13 -9 -29 -9 -17 0 -33 -4 -36 -10 -3 -5
-14 -10 -24 -10 -10 0 -24 -7 -31 -15 -7 -9 -29 -15 -52 -15 -21 0 -58 -9 -80
-20 -23 -11 -51 -20 -62 -20 -18 0 -56 -16 -148 -61 -10 -5 -27 -9 -37 -9 -11
0 -23 -4 -26 -10 -3 -5 -19 -10 -35 -10 -16 0 -31 -4 -35 -9 -9 -16 -66 -41
-91 -41 -13 0 -26 -4 -29 -10 -3 -5 -18 -10 -33 -10 -15 0 -49 -11 -76 -24
-27 -13 -56 -22 -63 -19 -8 3 -22 -2 -33 -12 -10 -9 -23 -14 -29 -11 -5 4 -14
-2 -20 -11 -8 -14 -14 -16 -28 -7 -14 9 -29 6 -68 -12 -27 -13 -60 -24 -73
-24 -12 0 -31 -4 -42 -10 -11 -5 -29 -14 -40 -20 -11 -5 -28 -10 -39 -10 -10
0 -24 -7 -31 -15 -7 -8 -23 -15 -36 -15 -13 0 -39 -9 -59 -20 -20 -11 -51 -20
-70 -20 -18 0 -36 -4 -40 -9 -10 -17 -67 -41 -97 -41 -15 0 -36 -4 -46 -9 -9
-5 -35 -17 -57 -26 -22 -9 -48 -20 -57 -25 -10 -6 -29 -10 -42 -10 -14 0 -28
-5 -31 -10 -3 -6 -17 -10 -29 -10 -13 0 -29 -7 -36 -15 -7 -8 -23 -15 -36 -15
-13 0 -38 -8 -56 -18 -18 -10 -52 -21 -75 -24 l-43 -6 0 2379 c0 1465 4 2379
9 2379 5 0 13 9 16 20 5 15 12 19 29 14 12 -3 43 4 70 15 26 12 59 21 72 21
13 0 24 5 24 10 0 6 9 10 19 10 11 0 32 6 48 14 38 19 95 36 121 36 11 0 24 5
27 10 3 6 16 10 29 10 12 0 31 7 42 15 10 8 27 15 37 15 17 0 27 4 74 28 13 7
36 12 51 12 32 0 92 26 92 41 0 5 17 9 38 9 38 0 96 22 107 41 4 5 19 9 35 9
16 0 32 5 35 10 3 6 23 10 44 10 20 0 41 4 47 8 5 5 35 17 67 26 31 10 67 26
79 36 16 13 33 16 57 12 25 -4 36 -1 43 12 6 11 16 15 29 11 13 -4 28 0 39 10
13 12 23 14 35 7 12 -6 31 -2 66 14 27 13 60 24 74 24 14 0 25 5 25 10 0 6 15
10 34 10 18 0 39 7 46 15 7 8 21 15 31 15 11 0 19 5 19 10 0 6 20 10 45 10 25
0 45 5 45 10 0 6 9 10 21 10 11 0 33 6 47 14 15 7 45 18 67 24 66 17 115 36
115 44 0 5 13 8 29 8 16 0 34 7 41 15 7 9 29 15 51 15 21 0 41 5 44 10 3 6 15
10 26 10 10 0 19 5 19 10 0 6 15 10 33 10 17 0 41 7 51 15 11 8 30 15 43 15
12 0 37 9 55 20 18 11 46 20 62 20 32 0 106 29 106 41 0 4 16 5 35 2 26 -4 41
-1 55 12 10 9 36 18 57 19 21 1 48 8 60 14 12 7 31 12 42 12 11 0 23 5 26 10
3 6 19 10 35 10 16 0 32 5 35 10 3 6 19 10 36 10 16 0 29 4 29 8 0 13 74 42
108 42 17 0 34 5 37 10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 16 10 29 10 25 0
82 25 91 41 4 5 24 9 45 9 21 0 42 5 45 10 3 6 14 10 24 10 10 0 24 6 30 14
11 13 22 16 114 32 20 3 40 10 43 15 3 5 13 9 23 9 9 0 48 16 87 35 50 25 82
35 117 35 26 0 47 5 47 10 0 6 8 10 19 10 10 0 24 7 31 15 9 11 17 12 32 4 15
-8 27 -6 54 10 19 12 53 21 74 21 21 0 40 4 42 8 3 10 57 39 74 41 6 1 17 -6
24 -14z m-5700 -4212 c0 -2257 -1 -2379 -18 -2401 -10 -13 -29 -24 -42 -26
-27 -3 -74 -19 -102 -36 -10 -6 -29 -10 -43 -10 -14 0 -25 -4 -25 -10 0 -5
-11 -10 -24 -10 -14 0 -28 -4 -31 -10 -3 -5 -16 -10 -27 -10 -12 0 -29 -7 -39
-16 -10 -9 -23 -13 -28 -9 -8 4 -11 266 -11 855 0 555 -3 851 -10 855 -7 4
-10 526 -10 1534 l0 1527 28 27 c16 16 39 27 55 27 15 0 27 5 27 10 0 6 13 10
29 10 17 0 33 5 36 10 3 6 19 10 34 10 16 0 34 7 41 15 7 9 29 15 51 15 21 0
41 5 44 10 3 6 19 10 36 10 l29 0 0 -2377z m-920 -315 c0 -2211 -1 -2393 -17
-2410 -9 -10 -22 -18 -28 -18 -7 0 -18 -7 -25 -15 -7 -8 -25 -15 -41 -15 -15
0 -31 -4 -34 -10 -3 -5 -21 -10 -38 -10 -18 0 -51 -9 -72 -20 -22 -12 -49 -19
-59 -16 -11 3 -26 0 -34 -6 -10 -9 -16 -8 -23 3 -5 8 -9 986 -9 2398 0 2202 1
2386 17 2403 9 10 26 18 39 18 12 0 29 6 37 14 8 8 23 13 34 10 11 -3 25 1 32
10 8 9 26 16 42 16 15 0 31 5 34 10 3 6 21 10 38 10 18 0 39 5 47 10 8 5 25
10 38 10 l22 0 0 -2392z m-897 2101 c9 -9 11 -540 9 -2383 -1 -1304 -5 -2381
-8 -2393 -6 -23 -113 -83 -148 -83 -10 0 -29 -9 -43 -20 -14 -11 -36 -20 -49
-20 -24 0 -55 -14 -86 -38 -11 -9 -18 -10 -23 -3 -3 6 -6 164 -5 351 0 216 -3
347 -10 360 -7 13 -10 676 -10 2059 l0 2040 25 25 c17 18 37 26 59 26 33 0 91
23 101 41 4 5 23 9 43 10 20 0 51 9 67 19 35 22 61 25 78 9z m15522 -268 c10
-17 67 -41 95 -41 28 0 85 -24 95 -41 4 -5 15 -9 26 -9 10 0 28 -4 39 -10 11
-5 29 -14 40 -20 11 -5 31 -10 44 -10 14 0 28 -4 31 -10 3 -5 17 -10 29 -10
13 0 29 -7 36 -15 7 -8 21 -15 31 -15 10 0 21 -4 24 -10 3 -5 21 -10 39 -10
33 0 91 -23 101 -41 4 -5 17 -9 31 -9 14 0 42 -9 64 -20 22 -11 50 -20 64 -20
14 0 45 -11 70 -25 25 -14 58 -25 75 -25 16 0 33 -4 36 -10 3 -5 16 -10 29
-10 12 0 41 -9 64 -20 24 -12 47 -18 52 -15 5 3 11 1 15 -5 3 -5 19 -10 35
-10 16 0 32 -4 35 -10 3 -5 16 -10 29 -10 25 0 82 -25 91 -41 4 -5 24 -9 45
-9 21 0 42 -4 45 -10 3 -5 17 -10 30 -10 13 0 45 -11 71 -25 26 -14 55 -25 64
-25 9 0 20 -3 24 -7 10 -11 79 -33 103 -33 12 0 24 -4 28 -9 10 -17 67 -41 95
-41 27 0 85 -24 94 -39 3 -4 21 -8 41 -9 20 -1 43 -8 51 -16 8 -9 31 -18 52
-21 20 -4 61 -18 91 -31 30 -14 59 -23 65 -21 7 3 20 -1 31 -9 10 -8 29 -14
42 -14 13 0 32 -4 43 -10 11 -5 29 -15 40 -20 12 -6 25 -8 30 -5 5 3 25 -4 45
-15 19 -11 48 -20 64 -20 29 0 86 -24 96 -41 4 -5 22 -9 41 -9 33 0 42 -3 91
-28 13 -7 29 -12 38 -12 14 0 26 -5 127 -49 26 -12 58 -21 71 -21 26 0 83 -25
92 -41 4 -5 22 -9 41 -9 31 0 51 -7 94 -32 8 -5 23 -11 32 -14 9 -3 18 -13 20
-22 2 -8 1 -542 -2 -1186 l-5 -1171 -40 -22 c-22 -12 -52 -22 -67 -22 -16 -1
-28 -5 -28 -11 0 -5 -13 -10 -29 -10 -17 0 -33 -4 -36 -10 -3 -5 -16 -10 -29
-10 -12 0 -31 -7 -42 -15 -10 -8 -29 -15 -40 -15 -12 0 -41 -9 -65 -20 -24
-11 -61 -20 -81 -20 -20 0 -39 -4 -43 -9 -10 -18 -68 -41 -101 -41 -18 0 -36
-4 -39 -10 -3 -5 -18 -10 -33 -10 -15 0 -35 -7 -45 -15 -11 -7 -25 -11 -32 -9
-7 3 -19 -1 -26 -9 -14 -14 -45 -24 -107 -34 -18 -3 -35 -9 -38 -14 -3 -5 -16
-9 -28 -9 -13 0 -29 -7 -36 -15 -7 -8 -21 -15 -31 -15 -11 0 -19 -4 -19 -10 0
-5 -22 -10 -48 -10 -46 0 -105 -20 -117 -41 -4 -5 -13 -5 -23 0 -12 6 -30 3
-56 -10 -22 -10 -56 -19 -77 -19 -20 0 -42 -6 -48 -14 -7 -8 -20 -12 -30 -9
-9 2 -23 -3 -30 -11 -8 -9 -24 -16 -37 -16 -14 0 -32 -4 -42 -9 -9 -5 -37 -15
-62 -21 -25 -7 -56 -17 -69 -24 -13 -6 -32 -10 -41 -8 -9 1 -23 -4 -30 -13 -7
-8 -21 -15 -31 -15 -11 0 -19 -4 -19 -10 0 -5 -22 -10 -48 -10 -46 0 -105 -20
-117 -41 -4 -5 -22 -9 -40 -9 -19 0 -50 -9 -70 -20 -20 -11 -44 -20 -54 -20
-10 0 -32 -7 -47 -15 -16 -8 -37 -15 -46 -15 -10 0 -18 -4 -18 -10 0 -5 -13
-10 -29 -10 -17 0 -33 -4 -36 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -7 -41 -15
-7 -8 -23 -15 -36 -15 -12 0 -26 -4 -29 -10 -3 -5 -22 -10 -41 -10 -19 0 -42
-4 -52 -9 -35 -18 -90 -31 -104 -26 -9 4 -22 -1 -31 -9 -9 -9 -22 -13 -30 -10
-8 3 -17 -2 -20 -10 -5 -11 -14 -14 -37 -10 -19 4 -36 1 -46 -7 -9 -8 -27 -12
-39 -11 -13 2 -26 -2 -30 -7 -3 -6 -15 -11 -25 -11 -10 0 -33 -8 -52 -17 -61
-31 -68 -33 -87 -33 -11 0 -23 -4 -26 -10 -3 -5 -23 -10 -44 -10 -22 0 -44 -6
-51 -15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5 -17 -10 -30 -10
-13 0 -27 -4 -30 -10 -3 -5 -19 -10 -34 -10 -16 0 -34 -6 -40 -14 -6 -7 -30
-16 -52 -19 -22 -3 -48 -12 -58 -21 -10 -9 -35 -16 -56 -16 -39 0 -115 -28
-115 -42 0 -4 -13 -8 -29 -8 -17 0 -33 -5 -36 -10 -3 -5 -22 -10 -41 -10 -19
0 -51 -11 -72 -23 -20 -13 -56 -26 -79 -29 -23 -3 -55 -12 -71 -20 -16 -8 -42
-13 -59 -11 -19 3 -34 -1 -42 -11 -8 -9 -21 -16 -31 -16 -9 0 -20 -3 -23 -6
-4 -4 -21 -8 -39 -10 -54 -4 -98 -16 -98 -25 0 -5 -15 -9 -32 -9 -18 0 -42 -7
-52 -15 -11 -8 -30 -15 -43 -15 -12 0 -38 -9 -58 -20 -20 -11 -49 -20 -65 -20
-17 0 -38 -4 -48 -9 -42 -21 -94 -41 -107 -41 -9 0 -24 -4 -35 -10 -11 -6 -29
-15 -40 -20 -11 -5 -31 -10 -45 -10 -15 0 -46 -11 -71 -25 -25 -14 -60 -25
-78 -25 -36 0 -101 -23 -111 -40 -3 -5 -22 -10 -41 -10 -19 0 -34 -4 -34 -10
0 -5 -11 -10 -25 -10 -13 0 -40 -9 -60 -20 -20 -11 -54 -20 -79 -20 -27 0 -48
-6 -56 -15 -7 -8 -21 -15 -30 -15 -10 0 -20 -5 -22 -11 -3 -9 -9 -9 -25 -1
-17 9 -30 7 -69 -13 -27 -14 -64 -25 -82 -25 -18 0 -42 -5 -55 -12 -48 -25
-58 -28 -81 -28 -14 0 -28 -4 -31 -10 -3 -5 -17 -10 -29 -10 -13 0 -29 -7 -36
-15 -7 -8 -25 -15 -41 -15 -15 0 -31 -4 -34 -10 -3 -5 -21 -10 -38 -10 -18 0
-50 -11 -72 -25 -23 -14 -56 -25 -77 -25 -20 0 -40 -5 -43 -10 -3 -5 -15 -10
-26 -10 -10 0 -19 -4 -19 -10 0 -5 -15 -10 -34 -10 -18 0 -54 -11 -80 -25 -26
-14 -62 -25 -81 -25 -19 0 -37 -4 -40 -10 -3 -5 -14 -10 -24 -10 -10 0 -31 -6
-46 -14 -15 -8 -34 -12 -41 -9 -8 3 -17 -2 -20 -11 -4 -10 -18 -16 -40 -16
-19 0 -34 -4 -34 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3 -5 -28 -10
-54 -10 -31 0 -53 -5 -61 -15 -7 -8 -19 -15 -26 -15 -8 0 -23 -5 -33 -12 -17
-10 -28 -4 -88 47 -37 32 -84 75 -103 94 -49 50 -152 139 -186 161 -16 10 -49
37 -74 60 -25 23 -59 48 -76 56 -18 9 -79 62 -135 120 -57 57 -108 104 -113
104 -5 0 -35 25 -67 55 -32 30 -63 55 -70 55 -7 0 -38 24 -70 53 -118 107
-177 157 -186 157 -5 0 -33 25 -63 55 -30 30 -60 55 -66 55 -7 0 -21 11 -32
25 -11 14 -22 25 -25 25 -3 0 -26 20 -50 45 -25 25 -51 45 -58 45 -7 0 -37 22
-68 50 -31 27 -87 77 -126 110 -38 33 -92 80 -120 104 -27 24 -64 50 -81 59
-17 9 -46 33 -65 54 -19 21 -50 50 -69 63 -48 32 -107 82 -194 163 -40 37 -79
67 -88 67 -8 0 -38 27 -67 60 -29 33 -58 60 -63 60 -6 0 -30 17 -53 38 -59 52
-84 72 -93 72 -4 0 -34 27 -67 60 -33 33 -65 60 -72 60 -7 0 -41 27 -76 60
-135 130 -257 233 -307 260 -26 14 -87 67 -219 190 -70 65 -148 130 -156 130
-4 0 -36 27 -72 60 -35 33 -76 68 -91 77 -15 10 -44 34 -65 53 -21 19 -46 39
-55 44 -51 27 -80 76 -44 74 9 -1 37 8 61 20 25 12 53 22 62 22 10 0 39 11 65
25 26 14 62 25 80 25 19 0 34 5 34 10 0 6 13 10 29 10 17 0 33 5 36 10 3 6 14
10 23 10 9 0 39 11 66 25 27 14 55 25 63 25 7 0 13 5 13 10 0 6 17 10 39 10
22 0 44 6 51 15 7 8 21 15 31 15 11 0 19 5 19 10 0 6 20 10 45 10 25 0 45 5
45 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 20 10 36 10 17 0 51 11 76 26 25 14
52 23 59 20 15 -6 79 18 88 33 3 4 16 6 29 4 13 -3 29 2 36 11 7 8 18 13 26
10 7 -3 31 4 54 15 22 12 51 21 65 21 14 0 45 11 70 25 25 14 56 25 69 25 26
0 83 25 92 41 4 5 22 9 40 9 19 0 50 9 70 20 20 11 49 20 65 20 17 0 38 4 48
8 9 5 37 18 62 29 25 12 60 28 79 37 19 9 44 16 56 16 13 0 28 7 35 15 7 8 25
15 41 15 16 0 29 5 29 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 23 10 44 10 21
0 44 6 50 14 6 7 32 16 58 20 56 7 115 27 125 41 4 5 20 8 37 7 16 -2 32 1 36
7 3 6 15 11 26 11 11 0 37 9 59 20 22 11 53 20 70 20 16 0 30 3 30 8 0 10 75
42 100 42 11 0 35 9 55 20 20 11 51 20 69 20 18 0 39 7 46 15 7 8 21 15 31 15
11 0 19 5 19 10 0 6 16 10 34 10 19 0 56 11 82 25 26 14 55 25 65 25 10 0 21
5 24 10 3 6 17 10 30 10 13 0 44 11 69 25 25 14 54 25 65 25 11 0 23 5 26 10
3 6 19 10 36 10 16 0 29 5 29 10 0 6 15 10 33 10 19 0 49 8 67 17 19 9 52 20
74 25 63 13 63 13 80 27 8 7 20 10 26 6 6 -4 17 -1 26 5 8 7 29 14 46 16 18 2
37 9 42 14 6 6 18 10 28 10 10 0 18 5 18 10 0 6 14 10 30 10 17 0 52 11 78 25
26 14 55 25 65 25 9 0 27 5 40 12 49 25 58 28 91 28 19 0 37 4 41 9 10 17 67
41 96 41 16 0 45 10 66 22 21 12 48 21 61 20 12 -1 25 5 28 13 4 9 19 15 40
15 19 0 34 5 34 10 0 6 9 10 19 10 11 0 23 5 26 10 3 6 17 10 29 10 13 0 29 7
36 15 7 8 21 15 31 15 11 0 19 5 19 10 0 6 20 10 45 10 25 0 45 5 45 10 0 6 9
10 19 10 11 0 23 5 27 11 3 6 16 8 29 5 12 -3 40 3 63 14 23 11 51 20 62 20
12 0 31 5 43 12 12 7 31 11 43 9 11 -1 27 5 34 13 7 9 19 16 28 16 23 0 111
29 154 51 10 5 29 9 42 9 14 0 27 4 31 9 10 17 67 41 95 41 28 0 85 24 95 41
4 5 19 9 35 9 16 0 32 5 35 10 3 6 17 10 30 10 13 0 27 5 30 10 3 6 16 10 28
10 12 0 31 7 41 15 11 8 28 15 38 15 10 0 18 5 18 10 0 6 13 10 29 10 17 0 33
5 36 10 3 6 24 10 45 10 21 0 41 4 45 9 10 17 67 41 97 41 17 0 42 9 57 19 16
12 36 18 48 15 12 -3 24 -1 27 5 4 6 20 11 36 11 16 0 32 5 35 10 3 6 17 10
30 10 13 0 27 5 30 10 3 6 17 10 29 10 28 0 92 25 101 40 3 5 20 10 36 10 16
0 47 9 69 20 22 11 50 20 64 20 14 0 27 4 31 9 10 18 68 41 101 41 18 0 36 5
39 10 3 6 14 10 24 10 10 0 24 7 31 15 7 8 25 15 41 15 16 0 37 4 47 9 9 5 31
14 47 21 17 7 38 16 47 21 10 5 26 9 37 9 10 0 24 7 31 15 7 8 25 15 41 15 16
0 29 5 29 10 0 6 17 10 38 11 20 0 55 11 77 24 43 24 80 31 90 16z m-16767
-119 c10 -7 12 -497 12 -2417 0 -2229 -1 -2410 -17 -2427 -9 -11 -30 -18 -50
-18 -20 0 -43 -8 -55 -20 -12 -11 -34 -20 -49 -20 -15 0 -42 -8 -61 -17 -61
-31 -68 -33 -88 -33 -11 0 -20 -4 -20 -10 0 -5 -8 -10 -19 -10 -10 0 -24 -7
-31 -15 -7 -8 -26 -15 -42 -15 -16 0 -48 -9 -70 -20 -23 -11 -52 -20 -64 -20
-13 0 -26 -4 -29 -10 -3 -5 -18 -10 -33 -10 -15 0 -40 -7 -55 -15 -15 -8 -45
-17 -65 -20 -35 -6 -66 -17 -104 -36 -10 -5 -29 -9 -43 -9 -14 0 -45 -9 -67
-20 -23 -11 -50 -20 -59 -20 -10 0 -21 -5 -24 -10 -3 -5 -16 -10 -28 -10 -12
0 -31 -7 -41 -15 -11 -8 -28 -15 -38 -15 -10 0 -18 -4 -18 -10 0 -5 -16 -10
-35 -10 -19 0 -35 -4 -35 -10 0 -5 -8 -10 -19 -10 -10 0 -24 -7 -31 -15 -7 -8
-29 -15 -49 -15 -20 0 -42 -5 -48 -11 -6 -6 -21 -9 -34 -7 l-24 3 -3 2400 c-2
1876 0 2405 10 2425 18 37 39 60 56 60 7 0 28 6 45 14 18 7 51 19 75 25 23 7
42 16 42 21 0 6 20 10 45 10 25 0 45 4 45 8 0 13 74 42 108 42 18 0 32 5 32
10 0 6 16 10 35 10 19 0 35 5 35 11 0 6 8 8 19 4 10 -3 32 0 47 6 54 22 69 27
114 39 25 6 53 16 62 21 10 5 26 9 36 9 10 0 27 7 38 15 10 8 32 15 47 15 15
0 45 9 67 20 22 11 47 20 56 20 23 0 94 32 94 42 0 4 20 8 45 8 25 0 45 5 45
10 0 6 8 10 19 10 10 0 24 6 30 14 6 7 33 16 59 18 26 3 61 12 77 20 37 19 37
20 53 10z m20022 -4134 c0 -7 0 -307 0 -667 1 -700 1 -697 -46 -673 -11 5 -37
17 -59 27 -22 9 -48 21 -57 26 -10 5 -29 9 -42 9 -14 0 -28 5 -31 10 -3 6 -24
10 -46 10 -21 0 -39 5 -39 10 0 6 -8 10 -19 10 -10 0 -24 7 -31 15 -7 8 -28
15 -46 15 -18 0 -49 9 -69 20 -20 11 -50 20 -68 20 -18 0 -50 12 -72 25 -22
14 -54 25 -72 25 -17 0 -35 5 -38 10 -3 6 -19 10 -34 10 -16 0 -34 7 -41 15
-7 8 -23 15 -36 15 -12 0 -26 5 -29 10 -3 6 -19 10 -35 10 -16 0 -32 5 -35 10
-3 6 -17 10 -31 10 -13 0 -24 5 -24 10 0 6 -12 10 -28 10 -15 0 -37 7 -49 15
-14 10 -24 12 -28 5 -4 -7 -16 -3 -33 9 -20 14 -33 16 -51 10 -18 -7 -29 -5
-42 7 -11 10 -19 13 -19 9 0 -4 -9 0 -20 10 -13 12 -28 16 -49 12 -19 -4 -33
-1 -41 8 -7 8 -19 15 -26 15 -8 0 -22 4 -32 9 -25 13 -86 31 -104 31 -9 0 -21
7 -28 15 -7 8 -23 15 -36 15 -12 0 -26 5 -29 10 -3 6 -22 10 -40 10 -19 0 -54
11 -79 25 -25 14 -61 25 -80 25 -20 0 -50 9 -68 20 -18 11 -45 20 -61 20 -15
0 -45 11 -67 25 -27 17 -54 25 -83 25 -30 0 -49 6 -60 18 -20 22 -12 34 18 26
13 -3 27 1 34 10 8 10 29 16 52 16 21 0 41 5 44 10 3 6 17 10 30 10 13 0 27 5
30 10 3 6 19 10 35 10 29 0 90 29 90 43 0 4 18 7 39 7 22 0 43 5 46 10 3 6 19
10 35 10 16 0 31 4 34 9 3 4 25 11 49 13 23 3 59 13 79 22 21 10 41 15 46 12
6 -3 22 0 37 8 15 8 34 12 42 9 8 -3 19 0 23 7 9 15 81 39 120 40 14 1 47 12
74 25 28 12 55 21 62 18 7 -2 33 7 59 21 25 14 59 26 74 26 32 0 96 24 106 40
3 5 20 10 36 10 17 0 49 9 71 20 23 11 52 20 64 20 12 0 27 7 34 15 7 8 28 15
46 15 18 0 48 9 66 20 18 11 50 20 70 20 20 0 39 4 43 9 10 17 67 41 97 41 15
0 36 4 46 9 50 25 71 33 79 28 11 -7 94 12 108 24 5 5 19 9 31 9 13 0 26 5 29
10 9 15 75 13 75 -2z" />
      <path d="M39598 14230 c-1 -5 -1 -40 2 -77 l5 -68 133 -3 132 -3 0 -335 0
-335 93 3 92 3 3 332 2 332 128 3 127 3 3 78 3 77 -360 0 c-228 0 -361 -4
-363 -10z" />
      <path d="M40442 14232 c-9 -7 -12 -96 -10 -413 l3 -404 88 -3 87 -3 0 220 c0
121 3 226 6 235 7 19 24 21 24 3 0 -7 16 -36 35 -66 19 -29 35 -55 35 -58 0
-2 13 -23 28 -46 16 -23 32 -49 36 -57 4 -8 24 -42 45 -75 36 -58 40 -60 83
-63 l46 -3 38 63 c99 167 179 295 193 310 15 16 16 -3 16 -220 l0 -237 93 -3
92 -3 0 416 0 415 -79 0 -78 0 -27 -40 c-14 -22 -26 -43 -26 -46 0 -4 -10 -21
-23 -38 -12 -17 -33 -52 -46 -79 -14 -26 -30 -52 -38 -58 -7 -6 -13 -18 -13
-25 0 -8 -4 -14 -10 -14 -5 0 -10 -5 -10 -10 0 -6 -16 -36 -35 -66 -19 -30
-35 -58 -35 -62 0 -4 -13 -23 -28 -41 l-28 -34 -43 69 c-23 38 -93 152 -155
254 l-113 185 -69 3 c-38 1 -75 -2 -82 -6z" />
    </g>
  </svg>

</template>
<script>
export default {
  data() {
    return {
      iconColor: "#000000",
      iconSize: "52pt"
    };
  },
  mounted() {
    if (this.color)
      this.iconColor = this.color;
    if (this.size)
      this.iconSize = this.size;
  },
  props: ['color', 'size'],
};
</script>
<style>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -6px;
}
</style>