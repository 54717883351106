<template>
  <v-sheet :rounded="isSmallerDisplay() ? '' : 'lg'" v-if="$store.state.isUserLoggedIn">
    <v-card>
      <v-card-actions>
        Manage System Academy
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="goBack()">Back 2 Menu</v-btn>
      </v-card-actions>
      <v-card-text>
        <v-form>
          <locations-autocomplete v-model="location" :display="address"></locations-autocomplete>
          <v-flex xs12 fill-height>
            <GmapMap style="height: 390px; width: 100%" :center="position" :zoom="13" map-type-id="roadmap">
              <GmapMarker v-if="newAcademyPosition" :position="newAcademyPosition" />
            </GmapMap>
          </v-flex>
          <v-text-field class="mt-3" dense outlined v-model="name" :counter="100" label="Name" required></v-text-field>
          <affiliations-combo v-model="affiliation" :show-icon="true"></affiliations-combo>
          <v-text-field dense outlined v-model="website" label="Website"></v-text-field>
          <v-text-field dense outlined v-model="contact" label="Contact"></v-text-field>
          <v-text-field dense outlined v-model="phone" label="Phone"></v-text-field>
          <v-select
            outlined
            dense
            :items="sports"
            v-model="selectedSport"
            label="Sport"
            item-text="name"
            item-value="id">
          </v-select>
          <v-textarea
            outlined
            rows="10"
            label="Description"
            v-model="description"
          ></v-textarea>
        </v-form>
        <div v-if="displayErrors" class="error">
          Make sure all the information is valid
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn small elevation="0" color="primary" @click.stop="addNewAcademy()">Add Academy</v-btn>
      </v-card-actions>
    </v-card>
    <v-card class="mt-1">
      <v-card-text class="text-h5">
        Remove Academy
      </v-card-text>
      <v-card-text>
        <v-progress-linear v-if="deleting" striped height="15" indeterminate color="primary"></v-progress-linear>
        <div class="text-center">{{ deletingMessage }}
        </div>
      </v-card-text>
      <v-card-text>
        <v-text-field class="mt-3" dense outlined v-model="fromAcademy" label="To Delete" required></v-text-field>
        <v-text-field class="mt-3" dense outlined v-model="toAcademy" label="Transfer Members To"
          required></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn v-if="deleteButton != 'Confirm'" small elevation="0" color="error" @click.stop="removeAcademy()">Remove
          Academy</v-btn>
        <v-btn v-else small elevation="0" color="error" @click.stop="confirmRemove()">Confirm Remove</v-btn>
        <v-btn small elevation="0" v-if="deleteButton =='Confirm'" @click.stop="cancelRemove()">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-sheet>
</template>

<script>
import AcademyService from "@/services/AcademyService";
import UtilService from "@/services/UtilService";
import BjjlinkAdminService from "@/services/BjjlinkAdminService";
import AffiliationsCombo from "@/components/inputs/AffiliationsCombo.vue";
import LocationsAutocomplete from "@/components/inputs/LocationsAutocomplete.vue";

export default {
  data() {
    return {
      previous: null,
      academyId: this.$route.params.academyId,
      newAcademy: false,
      affiliation: null,
      website: null,
      name: null,
      newAcademyError: false,
      geolocated: null,
      address: null,
      zip: null,
      position: { lat: 32.715738, lng: -117.16108380000003 },
      newAcademyPosition: null,
      addressIcon: 'location_on',
      location: null,
      displayErrors: false,
      fromAcademy: null,
      toAcademy: null,
      deleting: false,
      deletingMessage: null,
      deleteButton: "Delete Academy",
      sports: [],
      phone: null,
      contact: null,
      selectedSport: null,
      description: null
    };
  },
  components: {
    AffiliationsCombo,
    LocationsAutocomplete
  },
  async created() {
    if (this.academyId == "new") 
      this.newAcademy = true;
    const response = await UtilService.sports();
    this.sports = response.sports;
    for (const s of response.sports) {
      if (s.name == 'JiuJitsu') {
        this.selectedSport = s.id;
      }
    }
  },
  mounted() {},
  watch: {
    location: {
      handler: function(newValue) {
        if(newValue){
          this.geolocated = newValue;
          this.address = this.getAddressCityStateCountry(newValue);
          this.position = { lat: this.geolocated.latitude, lng: this.geolocated.longitude };
          this.newAcademyPosition = { lat: this.geolocated.latitude, lng: this.geolocated.longitude };
        }
      }
    }
  },
  methods: {
    cancelRemove() {
      this.fromAcademy = null;
      this.toAcademy = null;
      this.deleteButton = "Delete Academy";
      this.deleting = false;
      this.deletingMessage = null;
    },
    async confirmRemove() {
      let step = 1;
      try {
        this.deleting = true;
        this.deletingMessage = '⏳ Swapping members ' + this.deletingMessage;
        await BjjlinkAdminService.swapAcademies(this.fromAcademy, this.toAcademy);
        this.deletingMessage = '✅ Completed SWAP ... ⏳ Removing academy...';
        await BjjlinkAdminService.removeAcademy(this.fromAcademy, this.toAcademy);
        const vm = this;
        setTimeout(function () {
          vm.showAlert(
            "success",
            'You got it! DELETED'
          );
          vm.cancelRemove();
         }, 3000);
      } catch (err) {
        console.log(err)
        if(step == 1)
          this.showAlert(
            "error",
            'Exception swapping'
          );
        else 
          this.showAlert(
            "error",
            'Exception deleting'
          );
      }

    },
    async removeAcademy() {
      this.deleting = true;
      try {
        const from = await AcademyService.academy(this.fromAcademy);
        const to = await AcademyService.academy(this.toAcademy);
        if (!from) {
          this.showAlert(
            "error",
            "Invalid from academy"
          );
          this.deleting = false;
          return;
        }
        if (!to) {
          this.showAlert(
            "error",
            "Invalid from academy"
          );
          this.deleting = false;
          return;
        }
        const fromMembers = from.academy && from.academy.members ? from.academy.members.length : 0;
        this.deletingMessage = from.academy.name + " (" + fromMembers +" members) -> " + to.academy.name;
        this.deleteButton = "Confirm";
        this.deleting = false;
      } catch (err) {
        this.showAlert(
          "error",
          this.$t('message.oops_not_working')
        );
      }
    },
    goBack() {
      this.$router.go(-1);
    },
    getAddressCityStateCountry(item) {
      if(!item)
        return null;
      if(item.route)
        return (
          (item.number ? item.number + ' ' : '') + item.route + 
          (item.subpremise ? ' ' + item.subpremise : '') + ', ' +
          (item.city ? item.city : '')+
          (item.state ? ", " + item.state : "") +
          (item.zip ? ", " + item.zip : "") +
          ", " +
          item.country)
      else if(item.formatted && item.latitude)
        return item.formatted;
    },
    validateNewAcademySubmit() {
      this.newAcademyError = false;
      if (
        this.geolocated == null ||
        this.name == null ||
        this.address == null
      ) {
        this.newAcademyError = true;
        return false;
      }
      return true;
    },
    async addNewAcademy() {
      const proceed = this.validateNewAcademySubmit();
      if (proceed) {
        //Get the country
        const data = {
          name: this.name,
          address: this.address,
          affiliation: this.affiliation,
          website: this.website,
          geolocated: this.geolocated,
          phone: this.phone,
          sport: this.selectedSport,
          contact: this.contact,
          description: this.description,
        };
        try {
          const saved = await BjjlinkAdminService.addAcademy(data);
          if (saved) {
            console.log('THE ID: ', saved.newAcademy._id)
            this.navigateTo({ name: "manageacademy", params: { academyId: saved.newAcademy._id } });
          }else{
            this.showAlert(
              "error",
              this.$t('message.oops_not_working')
            );
          }
        } catch (err) {
          if(err)
            this.showAlert(
              "error",
              this.$t('message.academy_exists')
            );
        }
      }else{
        this.displayErrors = true;
      }
    }
  }
};
</script>

<style scoped>
</style>