<template>
  <div v-if="$store.state.isUserLoggedIn">
    <v-container v-if="loadingHome">
      <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
    </v-container>
    <v-container v-else class="pa-2" style="overflow-y: hidden;overflow-x: hidden;">
      <v-row>
        <v-col class="pb-0">
          {{ $t("message.hi") }}, {{ $store.state.user.fName }}
        </v-col>
      </v-row>
      <v-row class="mb-0 pb-0 mt-0 pt-0" no-gutters>
        <v-col cols="6" sm="7" class="d-flex pb-0">
          <div class="pa-2 flex-grow-1" flat>
            <v-list-item dense @click="openHomeWidget('points')">
              <v-list-item-action class="mr-1 pr-1">
                <v-btn elevation="0" fab>
                  <v-icon>mdi-chevron-triple-up</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content class="pr-0">
                <v-list-item-title>
                  <div class="text-h5">{{ points.total }}</div>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <div class="text-caption">{{ $t("message.points") }}</div>
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
        <v-col cols="6" sm="4" class="d-flex pb-0">
          <div class="pa-2 flex-grow-1" flat>
            <v-list-item dense @click="openHomeWidget('schedule')">
              <v-list-item-action class="mr-1 pr-1">
                <v-btn elevation="0" fab>
                  <v-icon>mdi-calendar-range-outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ $t("message.schedule") }}</v-list-item-title>
                <v-list-item-subtitle>{{ $d(new Date(), 'month_day_year', $i18n.locale) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-col>
      </v-row>
      <div class="horizontal-scrolling-wrapper mb-2 pt-0 mt-0">
        <div class="horizontal-scrolling pt-2 pb-2 pr-2"
          :style="$store.state.userManagedAcademy ? 'width:800px' : 'width:640px'">
          <div class="scrollable-div mr-2 rounded-card" v-if="$store.state.userManagedAcademy">
            <v-card flat outlined @click="openHomeWidget('my-academy')" :height="widgetHeight">
              <v-list-item dense>
                <v-list-item-action class="mr-1">
                  <v-btn x-small elevation="0" fab>
                    <v-icon>mdi-bank</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("message.my_academy") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="myAcademyData.loading">
                <v-skeleton-loader class="mx-auto" type="image" :max-height="loaderHeight"></v-skeleton-loader>
              </div>
              <div v-else>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t("message.academy") }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t("message.alerts") }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn x-small elevation="0" fab :color="myAcademyData.alertsCount > 0 ? 'primary' : ''">
                      {{ myAcademyData.alertsCount ? myAcademyData.alertsCount : 0 }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense style="margin-bottom: 8px">
                  <v-list-item-content v-if="myAcademyData.progress && myAcademyData.progress.value >= 0">
                    <v-list-item-subtitle>
                      {{ $t("message.progress") }} <span class="ml-2">{{ myAcademyData.progress.value }}%</span>
                    </v-list-item-subtitle>
                    <v-list-item-title v-if="myAcademyData.progress && myAcademyData.progress.value >= 0">
                      <template>
                        <v-progress-linear v-if="myAcademyData.progress.jjtemplate" rounded height="10"
                          :color="myAcademyData.progress.color"
                          :value="myAcademyData.progress.value"></v-progress-linear>
                        <v-progress-linear v-else rounded height="10" :color="myAcademyData.progress.level"
                          :value="myAcademyData.progress.value"></v-progress-linear>
                      </template>
                    </v-list-item-title>
                    <v-list-item-title v-else>
                      <template>
                        <v-progress-linear color="light-gray" value="0"></v-progress-linear>
                      </template>
                    </v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-content v-else>
                    <v-list-item-subtitle>
                      {{ $t("message.no_program") }}
                    </v-list-item-subtitle>
                    <v-list-item-title style="height:10px">
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>
                      {{ myAcademyData.academyName }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-avatar size="30" v-if="myAcademyData.academyImage">
                    <img :src="userImg(myAcademyData.academyImage)" />
                  </v-list-item-avatar>
                </v-list-item>
              </div>
            </v-card>
          </div>
          <div class="scrollable-div mr-2">
            <v-card flat outlined @click="openHomeWidget('my-training')" :height="widgetHeight">
              <v-list-item dense>
                <v-list-item-action class="mr-1">
                  <v-btn x-small elevation="0" fab>
                    <v-icon>mdi-finance</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("message.my_training") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="myTrainingData.loading">
                <v-skeleton-loader class="mx-auto" type="image" :max-height="loaderHeight"></v-skeleton-loader>
              </div>
              <div v-else>
                <div class="text-center mt-2 pt-2">
                  <div class="text-center text-caption">
                    {{ $t("message.last_30_days") }}
                  </div>
                  <div style="margin-bottom: 19px !important;">
                    <v-chip medium v-if="myTrainingData.last_30">
                      <span class="mr-2 text-subtitle-1">{{ myTrainingData.last_30.delta }}%</span>
                      <v-avatar right :color="myTrainingData.last_30.delta > 0 ? 'success' : 'error'">
                        <v-icon color="white" v-if="myTrainingData.last_30.delta > 0">mdi-trending-up</v-icon>
                        <v-icon v-else color="white">mdi-trending-down</v-icon>
                      </v-avatar>
                    </v-chip>
                  </div>
                </div>
                <div class="text-center mt-2 pt-2 pb-2 mb-2">
                  <div class="text-center text-caption">
                    {{ $t("message.training_minutes") }}
                  </div>
                  <div class="text-center">
                    <v-chip medium class="mr-2">
                      <span class="mr-2 text-subtitle-1">{{ myTrainingData.minutes }}</span>
                      <v-avatar right color="primary">
                        <v-icon size="20">mdi-timer-outline</v-icon>
                      </v-avatar>
                    </v-chip>
                  </div>
                </div>
                <div style="height:10px"></div>
              </div>
            </v-card>
          </div>
          <div class="scrollable-div mr-2">
            <v-card flat outlined @click="openHomeWidget('match-tracker')" :height="widgetHeight">
              <v-list-item dense>
                <v-list-item-action class="mr-1">
                  <v-btn x-small elevation="0" fab>
                    <v-icon>mdi-access-point</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content class="mb-0">
                  <v-list-item-title>{{ $t("message.match") }}</v-list-item-title>
                  <v-list-item-title>{{ $t("message.tracker") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="matchTrackerData.loading">
                <v-skeleton-loader class="mx-auto" type="image" :max-height="loaderHeight"></v-skeleton-loader>
              </div>
              <div v-else>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t("message.saved") }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t("message.competitors") }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="pl-1 ml-1">
                    <v-btn x-small elevation="0" fab>
                      {{ matchTrackerData.savedCompetitorsCount }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t("message.active") }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t("message.competing") }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action class="pl-1 ml-1">
                    <v-btn x-small elevation="0" fab>
                      {{ matchTrackerData.savedCompetitorsCompetingCount }}
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
                <v-list-item dense>
                  <v-list-item-content>
                    <v-list-item-subtitle>{{ $t("message.active") }}</v-list-item-subtitle>
                    <v-list-item-subtitle>{{ $t("message.events") }}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-btn x-small elevation="0" fab>
                      {{ matchTrackerData.activeEventsCount }}
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-avatar size="30" class="pl-1 ml-1" outlined z>
                    <img :src="assets() + '/external-logos/ibjjf-logo-blue.png'" />
                  </v-list-item-avatar>
                </v-list-item>
              </div>
            </v-card>
          </div>
          <div class="scrollable-div mr-2">
            <v-card flat outlined @click="openHomeWidget('nearby')" :height="widgetHeight">
              <v-list-item dense>
                <v-list-item-action class="mr-1">
                  <v-btn x-small elevation="0" fab>
                    <v-icon>mdi-earth</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("message.academies") }}</v-list-item-title>
                  <v-list-item-title>{{ $t("message.nearby") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="academiesData.loading">
                <v-skeleton-loader class="mx-auto" type="image" :max-height="loaderHeight"></v-skeleton-loader>
              </div>
              <div v-else>
                <div class="text-center" style="margin-bottom:11px">
                  <div class="text-center text-h3">
                    {{ academiesData.nearBy }}
                  </div>
                  <div class="text-caption">{{ $t("message.academies") }} {{ $t("message.nearby") }}</div>
                </div>
                <div class="text-center">
                  <div class="text-center text-h4">
                    <v-chip class="pr-2">
                      <CountryFlag border="white" style="margin-left: -5px" v-for="(i, index) in academiesData.last3"
                        :key="'academy_flag_' + index + '_' + i._id" :avatar="true" :countryCode="i.country">
                      </CountryFlag>
                    </v-chip>
                  </div>
                  <div class="text-caption">{{ $t("message.latest_regions") }}</div>
                </div>
              </div>
            </v-card>
          </div>
          <div class="scrollable-div mr-2">
            <v-card flat outlined @click="openHomeWidget('news')" :height="widgetHeight">
              <v-list-item dense>
                <v-list-item-action class="mr-1">
                  <v-btn x-small elevation="0" fab>
                    <v-icon>mdi-newspaper-variant-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>{{ $t("message.news") }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <div v-if="newsData.loading">
                <v-skeleton-loader class="mx-auto" type="image" :max-height="loaderHeight"></v-skeleton-loader>
              </div>
              <div v-else>
                <div class="text-center">
                  <div class="mb-1">
                    <img :src="newsData.last3[0]" class="news-image" style="transform: rotate(-7deg);" />
                    <img :src="newsData.last3[1]" class="news-image"
                      style="margin-left:-45px; transform: rotate(-1deg);" />
                    <img :src="newsData.last3[2]" class="news-image"
                      style="margin-left:-45px; transform: rotate(7deg);" />
                  </div>
                  <div>
                    <div class="text-caption">{{ $t("message.new_today") }}</div>
                    <div class="text-h6">{{ newsData.newToday }}</div>
                  </div>
                </div>
              </div>
            </v-card>
          </div>
        </div>
      </div>
      <v-row v-if="$store.state.userManagedAcademy">
        <v-col>
          <v-card outlined height="136">
            <v-list-item @click="navigateTo({ name: 'viewposts', params: { feed: 'academy' } })">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <v-icon>mdi-post-outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.bulletin") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.bulletin_subtitle") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small elevation="0" fab v-if="bulletinData.last24">
                  +{{ bulletinData.last24 }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <div class="mt-0 pt-0">
              <div v-if="bulletinData.loading">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else>
                <v-card-actions class="ml-2">
                  <v-chip medium>
                    <v-avatar size="30" class="card-avatar" :key="'post_avatars_' + index + '_' + i._id"
                      v-for="(i, index) in bulletinData.posts">
                      <img :src="userImg(i.image)" />
                    </v-avatar>
                  </v-chip>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="$store.state.userManagedAcademy">
        <v-col>
          <v-card outlined height="136">
            <v-list-item @click="navigateTo({ name: 'videos' })">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <v-icon>mdi-play-circle-outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.videos") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.academy_videos") }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small elevation="0" fab v-if="videosData && videosData.lastWeek">
                  +{{ videosData.lastWeek }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <div class="mt-0 pt-0">
              <div v-if="videosData.loading">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else>
                <v-card-actions class="ml-2" v-show="videosData.videos.length > 0">
                  <v-chip medium>
                    <v-avatar size="30" class="card-avatar" :key="'video_avatars_' + index + '_' + i._id"
                      v-for="(i, index) in videosData.videos">
                      <img :src="userImg(i.image)" />
                    </v-avatar>
                  </v-chip>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!hiddenUser">
        <v-col>
          <v-card outlined height="136">
            <v-list-item @click="navigateTo({ name: 'events' })">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <v-icon>mdi-calendar-star</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.events") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.events_home_explained")
                }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div>
              <div v-if="eventsData.loading">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else-if="eventsData.events && eventsData.events.length">
                <v-card-actions class="ml-2">
                  <v-chip medium>
                    <v-avatar size="30" class="card-avatar" :key="'event_avatars_' + index + '_' + i._id"
                      v-for="(i, index) in eventsData.events">
                      <img :src="userImg(i.image)" />
                    </v-avatar>
                  </v-chip>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!hiddenUser">
        <v-col>
          <v-card outlined height="136">
            <v-list-item @click="openHomeWidget('elevate')">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <img width="20px" :src="assets() + '/logos/logo_elevate.png'" />
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.bjjlink_elevate") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.elevate_videos_subtitle")
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small elevation="0" fab v-if="elevateData && elevateData.lastWeek">
                  +{{ elevateData.lastWeek }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <div>
              <div v-if="elevateData.loading">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else>
                <v-card-actions class="ml-2">
                  <v-chip medium>
                    <v-avatar size="30" class="card-avatar" :key="'elevate_avatars_' + index + '_' + i._id"
                      v-for="(i, index) in elevateData.videos">
                      <img :src="userImg(i.image)" />
                    </v-avatar>
                  </v-chip>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!hiddenUser">
        <v-col>
          <v-card outlined height="136">
            <v-list-item @click="openHomeWidget('posts')">
              <v-list-item-action>
                <v-btn elevation="0" fab>
                  <v-icon>mdi-account-group-outline</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.community") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.community_posts") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div>
              <div v-if="postsData.loading">
                <v-progress-linear indeterminate color="primary"></v-progress-linear>
              </div>
              <div v-else>
                <v-card-actions class="ml-2">
                  <v-chip medium>
                    <v-avatar size="30" class="card-avatar" :key="'communityposts_avatars_' + index + '_' + i._id"
                      v-for="(i, index) in postsData.posts">
                      <img :src="userImg(i.image)" />
                    </v-avatar>
                  </v-chip>
                </v-card-actions>
              </div>
            </div>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-if="!hiddenUser">
        <v-col>
          <v-card outlined height="136">
            <v-list-item>
              <v-list-item-action @click="openHomeWidget('search')">
                <v-btn elevation="0" fab>
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title class="text-h5">{{ $t("message.search") }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption">{{ $t("message.search_explained") }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <div class="pa-2">
              <ObjectsAutocomplete :disabled="$store.state.hidden">
              </ObjectsAutocomplete>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog v-model="pointsDialog">
      <v-card outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="pointsDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
        <v-card-title>{{ $t("message.points") }}</v-card-title>
        <v-list dense>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.total_points") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.total }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.last_30_days") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.last30Days }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.this_month") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.thisMonth }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.academy_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.academyPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.city_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.cityPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.country_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.countryPosition }}
            </v-list-item-action>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item dense>
            <v-list-item-content>
              {{ $t("message.world_position") }}
            </v-list-item-content>
            <v-list-item-action>
              {{ points.positions.worldPosition }}
            </v-list-item-action>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-btn elevation="0" @click="navigateTo({ name: 'points' })" block>{{ $t("message.view_more") }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="scheduleDialog" width="99%">
      <v-card outlined>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn icon small @click="scheduleDialog = false"><v-icon>mdi-close-circle</v-icon></v-btn>
        </v-card-actions>
        <div>
          <div v-if="loadingClasses">
            <v-skeleton-loader class="mx-auto" type="card"></v-skeleton-loader>
          </div>
          <div v-else>
            <v-card-text v-if="checkinCode" outlined class="mb-0 pb-0">
              <div class="text-subtitle-2 text-center">{{ $t("message.your_checkin_code") }}</div>
              <div class="text-center text-h3">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="text-center text-h3" v-bind="attrs" v-on="on">{{ checkinCode }}</div>
                  </template>
                  <span>{{ $t('message.use_app_to_checkin') }}</span>
                </v-tooltip>
              </div>
            </v-card-text>
            <v-card-text>
              <v-expansion-panels flat class="mt-2" v-model="classesPanel">
                <v-expansion-panel :disabled="!upcomingEvents || upcomingEvents.length == 0"
                  :class="$store.state.theme == 'light' ? 'border-light pa-0' : 'border-dark pa-0'">
                  <v-expansion-panel-header>
                    {{ $t("message.upcoming_classes") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="upcomingEvents" class="pa-0 ma-0">
                    <v-card elevation="0" class="pa-0">
                      <v-list two-line dense>
                        <template v-for="(e, index) in upcomingEvents">
                          <v-list-item :key="'upcoming_event_' + index" @click="goToAcademyClass(e)" class="pr-0 pl-0">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ e.title }}
                                {{ e.professorUser ? "(" + $t("message.professor") + ": " +
                                  e.professorUser.displayName + ")" : "" }}
                              </v-list-item-title>
                              <v-list-item-title>
                                <span style="color: gray;">
                                  {{ $d(new Date(e.date), "long", $i18n.locale) }}
                                </span>
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :disabled="!hasAvailableClasses"
                  :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
                  <v-expansion-panel-header>
                    {{ $t("message.available_classes") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="hasAvailableClasses">
                    <v-card elevation="0">
                      <v-list two-line>
                        <template v-for="(e, index2) in availableClasses">
                          <v-list-item :key="'academy_class_' + '_' + index2" @click="goToAcademyClass(e)">
                            <v-list-item-content>
                              <v-list-item-title>
                                {{ e.title }}
                              </v-list-item-title>
                              <v-list-item-title v-if="e.disabled" class="error--text">
                                {{ $t("message.cancelled") }}
                              </v-list-item-title>
                              <v-list-item-subtitle>
                                <span v-if="e.maxCapacity > 0"
                                  :class="'font-weight-bold mr-1 ' + (e.hasCapacity ? 'success--text' : 'error--text')">
                                  {{ e.approved ? e.approved.length : 0 }}\{{ e.maxCapacity }}
                                </span>
                                <span v-if="e.professorUser">{{ e.professorUser.displayName }}</span>
                              </v-list-item-subtitle>
                              <v-list-item-subtitle>
                                {{ $d(e.date, "long", $i18n.locale) }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action v-if="e.status || e.canJoin || e.canWaitlist">
                              <v-btn @click.stop="joinAcademyClass(e)" color="primary" elevation="0" small icon
                                v-if="!e.status && e.canJoin">
                                <v-icon>mdi-login</v-icon>
                              </v-btn>
                              <v-btn @click.stop="joinAcademyClass(e)" color="secondary" elevation="0" small icon
                                v-else-if="!e.status && e.canWaitlist">
                                <v-icon>mdi-login</v-icon>
                              </v-btn>
                              <v-btn icon elevation="0" :color="e.canWaitlist ? 'error' : ''" small
                                v-else-if="e.status == 'pending'">
                                <v-icon>mdi-clock-time-five-outline</v-icon>
                              </v-btn>
                              <v-btn color="success" elevation="0" icon small medium v-else-if="e.status == 'approved'">
                                <v-icon>mdi-checkbox-marked-circle-outline</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </template>
                      </v-list>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel :disabled="!schedule"
                  :class="$store.state.theme == 'light' ? 'border-light' : 'border-dark'">
                  <v-expansion-panel-header>
                    {{ $t("message.full_schedule") }}
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="schedule">
                    <v-card elevation="0">
                      <v-card-text v-if="scheduleNotes" class="text-center">{{ scheduleNotes.notes }}</v-card-text>
                      <v-card-text>
                        <ScheduleDisplay :professors="professors" :schedule="schedule" />
                      </v-card-text>
                    </v-card>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import ConnectService from "@/services/ConnectService";
import CountryFlag from "@/components/displays/CountryFlagDisplay.vue";
import AcademyService from "@/services/AcademyService";
import AcademyCalendarService from "@/services/AcademyCalendarService";
import ScheduleDisplay from "@/components/displays/ScheduleDisplay.vue";
import ObjectsAutocomplete from "@/components/inputs/ObjectsAutoComplete";

//Icons

export default {
  name: 'Home',
  data() {
    return {
      loading: true,
      loadingHome: true,
      lat: this.$route.query.lat,
      lon: this.$route.query.lon,
      points: { total: 0, positions: {} },
      pointsDialog: null,
      widgetHeight: 225,
      loaderHeight: 135,
      myAcademyAlerts: 0,
      //Classes
      activeDateTab: 0,
      managedAcademy: this.$store.state.userManagedAcademy,
      classesPanel: null,
      upcomingEvents: [],
      scheduleDialog: null,
      schedule: null,
      scheduleNotes: null,
      checkinCode: null,
      availableClasses: [],
      professors: null,
      hasAvailableClasses: false,
      isMember: false,
      loadingClasses: false,
      hiddenUser: false,
      //Widgets
      myAcademyData: {
        loading: true,
        alertsCount: 0,
        academyName: null,
        academyImage: null,
        progress: 0
      },
      matchTrackerData: {
        loading: true,
        savedCompetitorsCount: 0,
        activeCompetingCount: 0,
        activeEventsCount: 0,
      },
      myTrainingData: {
        loading: true,
        last30Percent: 0,
        minutes: 0,
      },
      academiesData: {
        loading: true,
      },
      newsData: {
        loading: true,
      },
      bulletinData: {
        loading: true,
      },
      videosData: {
        loading: true,
      },
      postsData: {
        loading: true,
      },
      eventsData: {
        loading: true,
      },
      elevateData: {
        loading: true
      }
    };
  },
  components: {
    CountryFlag,
    ScheduleDisplay,
    ObjectsAutocomplete
  },
  async created() {
    if (this.lat && this.lon) {
      //store this for future use
      this.$store.dispatch("setLat", this.lat);
      this.$store.dispatch("setLon", this.lon);
    } else {
      this.lat = this.$store.state.lat;
      this.lon = this.$store.state.lon;
    }

    const me = await ConnectService.getMe();
    if (me) {
      const darkTheme = me.data.darkMode;
      this.$vuetify.theme.dark = darkTheme;
      this.hiddenUser = me.data.hidden;
      if (me.data.isManagedAcademyAdmin && !this.$store.state.managedAcademyAdmin) {
        this.$store.dispatch("setManagedAcademyAdmin", true);
      } else if (!me.data.isManagedAcademyAdmin) {
        this.$store.dispatch("setManagedAcademyAdmin", false);
      }
      if (me.data.isManagedAcademyMember && (!this.$store.state.userManagedAcademy || this.$store.state.userManagedAcademy != me.data.mainManagedAcademy)) {
        this.$store.dispatch("setUserManagedAcademy", me.data.mainManagedAcademy);
      } else if (!me.data.isManagedAcademyMember) {
        this.$store.dispatch("setUserManagedAcademy", null);
      }
      if (me.data.mainManagedAcademy) {
        this.managedAcademy = me.data.mainManagedAcademy;
        if (this.$store.state.version == 4) {
          this.navigateTo({ name: 'home-member' })
        }
      } else {
        if (this.$store.state.version == 4) {
          this.navigateTo({ name: 'home-regular' })
        }
      }
    }

    //Notifications
    const vm = this;
    vm.getMessagesCount(function (countM) {
      vm.getNotificationsCount(function (countS) {
        vm.$store.dispatch("setMessagesCount", countM);
        vm.$store.dispatch("setNotifications", countS);
        if (countM + countS == 0) {
          NotificationService.clearMobile();
        }
      });
    });

    ConnectService.getHomePoints()
      .then((data) => {
        this.points = data.points;
        this.points.positions = data.positions;
      });
    ConnectService.getHomeMyAcademy()
      .then((data) => {
        if (data.success && data.data.academy) {
          this.myAcademyData.academyName = data.data.academy.name;
          this.myAcademyData.academyImage = data.data.academy.image;
          this.myAcademyData.alertsCount = data.data.alerts;
          this.myAcademyData.topUsers = data.data.topUsers;
          if (data.data.progress) {
            this.myAcademyData.progressPercent = data.data.progress.value;
            this.myAcademyData.progress = data.data.progress;
          }
          this.myAcademyData.loading = false;
        }
      });
    ConnectService.getHomeMatchTracker()
      .then((data) => {
        if (data.success) {
          this.matchTrackerData = data.data;
        }
      });
    ConnectService.getHomeMyTraining()
      .then((data) => {
        if (data.success) {
          this.myTrainingData = data.data;
        }
      });

    ConnectService.getHomeAcademies(this.lat, this.lon)
      .then((data) => {
        if (data.success) {
          this.academiesData = data.data;
        }
      });

    ConnectService.getHomeNews()
      .then((data) => {
        if (data.success) {
          if (data.data.news) {
            this.newsData = data.data;
            this.newsData.last3 = [data.data.news[0].image, data.data.news[1].image, data.data.news[2].image];
          }
        }
      });

    ConnectService.getHomePostsAcademy()
      .then((data) => {
        if (data.success) {
          this.bulletinData = data.data;
        }
      });

    ConnectService.getHomeAcademyVideos()
      .then((data) => {
        if (data.success) {
          this.videosData = data.data;
        }
      });

    ConnectService.getHomePostsWorld()
      .then((data) => {
        if (data.success) {
          this.postsData = data.data;
        }
      });

    ConnectService.getHomeElevateData()
      .then((data) => {
        if (data.success) {
          this.elevateData = data.data;
        }
      });

    ConnectService.getHomeEvents()
      .then((data) => {
        if (data.success) {
          this.eventsData = data.data;
        }
      });

    if (!this.isSmallerDisplay())
      this.updatePanels();
    this.loadingHome = false;
  },
  methods: {
    joinAcademyClass(data) {
      let academyClassId = data._id;
      const split = academyClassId.split("_");
      if (split && split.length == 2) {
        academyClassId = split[0] + "_" + this.$store.state.userManagedAcademy;
      }
      AcademyCalendarService.joinAcademyClassFromHome(academyClassId, data)
        .then((data) => {
          if (data) {
            this.getAcademyClasses();
            this.showAlert("success", this.$t("message.success"));
          }
        })
        .catch((err) => {
          if (err.response && err.response.status === 405)
            this.showAlert("error", this.$t("message.need_membership_for_class"));
          else
            this.showAlert("error", this.$t("message.oops_not_working"));
        });
    },
    goToAcademyClass(e) {
      //Are you a member?
      if (!e._id)
        return;
      if (e.social) {
        this.navigateTo({
          name: "viewevent",
          params: { eventId: e._id },
        });
      } else if (this.isMember || e.allowNonMembers) {
        this.navigateTo({
          name: "viewacademyclass",
          params: { academyClassId: e._id },
        });
      }
    },
    getAcademyClasses() {
      AcademyService.academyClasses(this.managedAcademy)
        .then((data) => {
          try {
            if (data.schedule)
              this.schedule = data.schedule;
            if (data.checkinCode)
              this.checkinCode = data.checkinCode;
            if (data.scheduleNotes)
              this.scheduleNotes = data.scheduleNotes;
            if (data.professors)
              this.professors = data.professors;
            this.availableClasses = [];
            if (data.events && data.events.length > 0) {
              this.hasAvailableClasses = true;
              this.isMember = data.isMember;
              this.loading = false;
              for (let c of data.events) {
                const userId = this.$store.state.user._id;
                const maxCapacity = c.maxCapacity;
                const totalApproved = c.approved ? c.approved.length : 0;
                if (totalApproved < maxCapacity)
                  c.hasCapacity = true;
                if (c.approved && c.approved.indexOf(userId) >= 0) {
                  c["status"] = "approved";
                } else if (c.pending && c.pending.indexOf(userId) >= 0) {
                  c["status"] = "pending";
                  //Check max capacity to see if this is a waiting list person
                  if (c.maxCapacity) {
                    if (totalApproved >= maxCapacity) c["canWaitlist"] = true;
                  }
                } else {
                  if (c.maxCapacity) {
                    if (totalApproved < maxCapacity) {
                      //We have not reached max capacity
                      if (this.isMember || c.allowNonMembers)
                        c["canJoin"] = true;
                    } else if (c.allowWaitlist) {
                      if (this.isMember || c.allowNonMembers)
                        c["canWaitlist"] = true;
                    }
                  }
                }

                const classTime = new Date(c.date);
                c.date = classTime;
                if (!isNaN(c.startTimeHours)) {
                  classTime.setHours(c.startTimeHours);
                  classTime.setMinutes(c.startTimeMinutes);
                  c.date = classTime;
                }
                if (c.classDay)
                  c.date.setDate(c.classDay);
                this.availableClasses.push(c);
              }
            }
          } catch (err) {
            console.log(err);
          }
          this.loadingClasses = false;
        });
    },
    getUpcomingEvents() {
      AcademyCalendarService.upcomingEvents()
        .then((data) => {
          if (data.events && data.events.length > 0) {
            const tmp = data.events;
            if (tmp && tmp.length > 0) {
              let upcomingEvents = [];
              this.classesPanel = 0;
              const date = new Date();
              for (const i of tmp) {
                if (i.date) {
                  upcomingEvents.push(i);
                } else {
                  const d = this.hourInNumberToDate(i.start, date);
                  upcomingEvents.push({
                    date: d,
                    title: i.title
                  })
                }
              }
              //Sort
              upcomingEvents.sort((e1, e2) => e1.date - e2.date)

              this.upcomingEvents = upcomingEvents;
            }
          }
        });
    },
    openAdmin() {
      //const os = this.getOS();
      //const size = this.$vuetify.breakpoint.name;
      /*
        if (os == 'Mac OS' && size != 'lg' && size != 'xl') {//This should be an ipad
          console.log('BLAh')
          //window.open("/admin");
        } else 
      */
      if (this.isSmallerDisplay()) {
        window.location = "/admin/secure/home?inApp=true";
      } else {
        console.log('HERE 2')
      }
    },
    openHomeWidget(route) {
      if (route == 'news') {
        if (this.hiddenUser)
          this.showAlert(
            "info",
            this.$t("message.social_disabled")
          );
        else
          this.navigateTo({ name: 'news' })
      } else if (route == 'my-training') {
        this.navigateTo({ name: 'viewmytrainingtab', params: { tabId: 0 } })
      } else if (route == 'match-tracker') {
        if (this.hiddenUser)
          this.showAlert(
            "info",
            this.$t("message.social_disabled")
          );
        else
          this.navigateTo({ name: 'ibjjftournaments' })
      } else if (route == 'my-academy') {
        this.navigateTo({ name: 'myacademytab', params: { tabId: 0 } })
      } else if (route == 'nearby') {
        this.navigateTo({ name: 'nearby', query: { lat: this.lat, lon: this.lon } })
      } else if (route == 'points') {
        this.pointsDialog = true;
        //this.navigateTo({ name: 'points' })
      } else if (route == 'elevate') {
        this.navigateTo({ name: 'elevatevideos' })
      } else if (route == 'schedule') {
        this.scheduleDialog = true;
        this.loadingClasses = true;
        this.getAcademyClasses();
        if (this.managedAcademy) {
          this.getUpcomingEvents();
        }
      } else if (route == 'posts') {
        if (this.hiddenUser)
          this.showAlert(
            "info",
            this.$t("message.social_disabled")
          );
        else
          this.navigateTo({ name: 'viewposts', params: { feed: 'world' } })
      } else if (route == 'search') {
        if (this.hiddenUser)
          this.showAlert(
            "info",
            this.$t("message.social_disabled")
          );
      }
    },
    async updatePanels() {
      let userPanel = await this.getMe();
      let suggestionsPanel = await this.getSuggestions();
      let newsPanel = await this.getNews();
      let data = {
        userPanel: userPanel,
        suggestionsPanel: suggestionsPanel,
        newsPanel: newsPanel
      };
      this.$emit('update-panels', data);
    },
  }
}
</script>
<style scoped>
.horizontal-scrolling-wrapper {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  height: 250px;
}

.horizontal-scrolling {
  height: 232px;
}

.scrollable-div {
  width: 150px;
  display: inline-block;
}

.news-image {
  border-radius: 10px;
  object-fit: cover;
  width: 60px;
  height: 80px;
  border: 1px solid white;
  ;
}

.card-avatar {
  margin-left: -5px;
  border: 1px solid white;
}

.v-expansion-panel-content__wrap {
  padding: 0px
}

.v-expansion-panel-content div {
  padding: 0px
}
</style>
