<template>
  <div v-if="$store.state.isUserLoggedIn">
    HELLO Regular User
  </div>
</template>

<script>
import NotificationService from "@/services/NotificationService";
import ConnectService from "@/services/ConnectService";

export default {
  name: 'HomeRegular',
  data() {
    return {
    };
  },
  components: {
  },
  created() {
    this.initMe();
  },
  mounted() {
    const vm = this;
    vm.getMessagesCount(function (countM) {
      vm.getNotificationsCount(function (countS) {
        vm.$store.dispatch("setMessagesCount", countM);
        vm.$store.dispatch("setNotifications", countS);
        if (countM + countS == 0) {
          NotificationService.clearMobile();
        }
      });
    });
  },
  methods: {
    async initMe() {
      const me = await ConnectService.getMe();
      if (me) {
        console.log('ME DATA REGULAR: ', me);
        const darkTheme = me.data.darkMode;
        this.$vuetify.theme.dark = darkTheme;
        this.hiddenUser = me.data.hidden;
        if (me.data.isManagedAcademyAdmin && !this.$store.state.managedAcademyAdmin) {
          this.$store.dispatch("setManagedAcademyAdmin", true);
        } else if (!me.data.isManagedAcademyAdmin) {
          this.$store.dispatch("setManagedAcademyAdmin", false);
        }
        if (me.data.isManagedAcademyMember && (!this.$store.state.userManagedAcademy || this.$store.state.userManagedAcademy != me.data.mainManagedAcademy)) {
          this.$store.dispatch("setUserManagedAcademy", me.data.mainManagedAcademy);
        } else if (!me.data.isManagedAcademyMember) {
          this.$store.dispatch("setUserManagedAcademy", null);
        }
        if (me.data.mainManagedAcademy) {
          this.managedAcademy = me.data.mainManagedAcademy;
          if (this.$store.state.version == 4) {
            this.navigateTo({ name: 'home-member' })
          }
        }
      }
    }
  }
}
</script>
<style scoped></style>
