import Api from "@/services/Api2";

export default {
  async getMe(token, familyMember) {
    const response = await Api().get(
      "v1/connect/home/me?load_token=" +
        (token ? "true" : "false") +
        (familyMember ? "&family_member=" + familyMember : "")
    );
    return response.data;
  },

  async loadFeed(feed, page) {
    const response = await Api().get(
      "v1/connect/posts/" + feed + "/data?page=" + page
    );
    return response.data;
  },
  async getHomePoints() {
    const response = await Api().get("v1/connect/home/points/data");
    return response.data;
  },
  async getHomeMyAcademy() {
    const response = await Api().get("v1/connect/home/my-academy/data");
    return response.data;
  },
  async getHomeMatchTracker() {
    const response = await Api().get("v1/connect/home/match-tracker/data");
    return response.data;
  },
  async getHomeMyTraining() {
    const response = await Api().get("v1/connect/home/my-training/data");
    return response.data;
  },
  async getHomeAcademies(lat, lon) {
    const response = await Api().get(
      "v1/connect/home/academies/data?lat=" + lat + "&lon=" + lon
    );
    return response.data;
  },
  async getHomeNews() {
    const response = await Api().get("v1/connect/home/news/data");
    return response.data;
  },
  async getHomePostsAcademy() {
    const response = await Api().get("v1/connect/home/posts/academy/data");
    return response.data;
  },
  async getHomePostsWorld() {
    const response = await Api().get("v1/connect/home/posts/world/data");
    return response.data;
  },

  async getHomeAcademyVideos() {
    const response = await Api().get("v1/connect/home/my-academy/videos");
    return response.data;
  },

  async getHomeEvents() {
    const response = await Api().get("v1/connect/home/events/data");
    return response.data;
  },

  async waivers() {
    const response = await Api().get("v1/connect/users/waivers/data");
    return response.data;
  },

  async getHomeElevateData() {
    const response = await Api().get("v1/connect/home/elevate/data");
    return response.data;
  },

  async findNearby(lat, lon) {
    const response = await Api().post(
      "v1/connect/academies/find/nearby?lat=" + lat + "&lon=" + lon
    );
    return response.data;
  },

  async getMyAcademyAlerts() {
    const response = await Api().get("v1/connect/home/my-academy/alerts");
    return response.data;
  },

  async getPost(postId) {
    const response = await Api().get("v1/connect/posts/post/" + postId);
    return response.data;
  },
};
